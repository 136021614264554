.App-child {
  text-align: center;
  background-color: var(--Background);
  height: 100vh;
  position: absolute;
  z-index: 97;
  padding-bottom: 7em;
  color: darkgrey;
  width: 100%;
}
.categories-container {
  background-color: var(--Background);
}

.logo {
  height: 56vmin;
  width: 56vmin;
  pointer-events: none;
  border-radius: 17%;
  padding: 10px;
  border: 1px solid whitesmoke;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: float 6s ease-in-out infinite;
  }
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-top: 25px;
}
.video-responsive:last-child {
  margin-bottom: 25px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.childSection-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7%;
  margin-bottom: 0;
}
.buttonChild-container button {
  border: none;
  background: none;
}
.buttonChild-container {
  width: 91px;
  position: fixed;
  right: 15px;
  top: 50px;
  z-index: 99;
}

.alertContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
  margin-top: 5%;
}
.alertText {
  font-size: 12px;
  width: 80%;
  color: var(--warningTextColor);
  font-style: italic;
  margin-top: 10px;
}
.noneCat {
  height: 33vh;
}
.slide-in-bottom-child {
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0em;
  padding-top: 7em;
  background: var(--Background);
}
.buttonChildZone {
  border: 4px solid rgba(115, 103, 240, 0.34);
  border-radius: 0.25em;
  background: initial;
  background-color: #7367f0;
  color: #fff;
  font-size: 1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  padding: 8px;
  margin-bottom: 17px;
}
.buttonChildZone-active {
  background-color: #3c31ad !important;
  color: darkgray;
}

.buttonContainer {
  /* margin-top: 50%; */
  width: 99%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 117px;
}
.categories-container {
  width: 100%;
}
.absol {
  position: absolute;
  top: 0;
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.catselected {
  padding-top: 20vh;
}
.hidden {
  display: none;
}

@media screen and (min-width: 1290px) {
  .logo {
    height: 28vmin;
    width: 28vmin;
  }
  .childSection-content {
    min-height: 670px;
  }
  .alertContent {
    margin-top: 3em;
  }
}
@media screen and (max-width: 375px) {
  .noneCat {
    margin-top: 17%;
  }
  .App-child {
    padding-bottom: 11em;
  }
  .categories-container {
    margin-top: 15%;
  }
  .loader-reconstruct {
    margin-top: 17%;
  }
}
.child-button-label {
  width: 98px;
  height: 20px;
  position: absolute;
  top: 2rem;
  right: -54px;
  padding: 4px;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #f39c12 0%,
    #f8664f 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  transition: all 0.8s ease-in-out;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 1px 4px grey;
}

.child-button-label--active {
  width: 98px;
  height: 15px;
  position: absolute;
  top: 0rem;
  right: -13px;
  padding: 4px;
  background: #f8664f;
  background-image: radial-gradient(
    ellipse farthest-corner at top,
    #f39c12 1%,
    #f8664f 99%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  border-radius: 3px;
  box-shadow: 0 2px 6px grey;
  transition: all 0.8s ease-in-out;
}
.child-label-text {
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}
.child-button-img {
  width: 110px;
  height: auto;
  display: none;
}
.child-button-img--active {
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  width: 110px;
  height: auto;
  display: inherit;
  margin-top: 2em;
}
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
#triangle-child-level {
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 42px solid #f77142;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  position: absolute;
  bottom: 62px;
  transform: scale(0.5);
  left: 36px;
  z-index: 99;
  animation: fade-in 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) 500ms both;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.child-button-label-inZone {
  background: #7367f0 !important;
}
