/**************************\
  Basic Modal Styles
\**************************/
.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.modal__container {
  background: var(--Background);

  padding: 30px;
  max-width: 500px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  width: 100%;
  height: 65px;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.25;
  color: #ffffff;
  box-sizing: border-box;
  width: 60%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
}

.modal__close {
  background: transparent;
  border: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.infosContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.modal__header .modal__close:before {
  content: "✕";
  font-size: 36px;
  color: #cd3434;
  position: absolute;
  right: 12px;
  font-weight: bold;
}

.modal__content {
  margin-top: 2rem;
  /* margin-bottom: 2rem; */
  line-height: 1.5;
  color: var(--colorText);
  min-height: 100vh;
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  color: #ffffff;
  font-weight: bold;
}

.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.modal__footer {
  padding-bottom: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.micromodal-slide.is-open {
  display: block;
}

/* .micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
} */

/* .micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
} */

/* .micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
} */

/* .micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
} */

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

/**************************\
  Button default style
\**************************/

.cat-outer {
  align-self: auto;
}

.modalBtn {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  font-weight: inherit;
  padding: 0;
  text-decoration: none;
  padding: 10px 35px;
  border-radius: 5px;
  margin: 20px;
}

.notSmooth {
  background-image: radial-gradient(
    ellipse farthest-corner at top right,
    #f39c12 0%,
    #f8664f 100%
  );
  color: white;
  width: 73%;
  font-size: 11px;
}

.smooth {
  background-color: midnightblue;
  color: white;
}

#modal-2 {
  -webkit-overflow-scrolling: touch;
}
.buttonInfos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.titleInfos,
.paragInfos {
  max-width: 80%;
  margin-bottom: 17px;
}
.subWhyContent {
  width: 89%;
  margin-bottom: 15px;
  border: 1px dotted var(--dotted);
  padding: 7px;
  border-radius: 17px;
  background-color: var(--cardColor);
  text-align: center;
  font-size: 1.1rem;
  color: var(--globalTextColor);
}
.why-content-text {
  color: var(--colorText);
}
