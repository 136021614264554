:root {
  --Background: #e9ebf0;
  --backgroundHeaderMultimedia: #e9ebf0;
  --MenuColor: #eeeeee;
  --colorText: #000;
  --cardColor: whitesmoke;
  --globalTextColor: #848181;
  --dotted: lightgray;
  --shadow: #000;
  --purpleText: #602eba;
  --colorChildWelcome: darkblue;
  --menuShadow: grey;
  --animateTextFill: #645f5a;
  --animateTextStroke: #4d4f53;
  --Modalbackground: white;
  --warningTextColor: #6a0d06;
  --dashedBorderColor: #cdc9c9;
  --shadowColor: #000;
  --shadowEffect: -6px -6px 16px rgba(255, 255, 255, 0.5),
    6px 6px 16px rgba(209, 205, 199, 0.5);
  --borderEffect: 1px solid rgba(255, 255, 255, 0.2);
}

.dark-mode {
  --Background: radial-gradient(#565c5d, #353737);
  --backgroundHeaderMultimedia: radial-gradient(#3a3b3b, #0f1010);
  --MenuColor: #6c6c68ed;
  --colorText: #fff;
  --cardColor: #282826;
  --filter: drop-shadow(0 0 1.5rem whitesmoke);
  --globalTextColor: #848181;
  --dotted: none;
  --shadow: #fff;
  --purpleText: #9e58e6;
  --colorChildWelcome: #aaaaeb;
  --menuShadow: transparent;
  --animateTextFill: #000;
  --animateTextStroke: #fff;
  --Modalbackground: #000;
  --warningTextColor: #f05d52;
  --dashedBorderColor: #565555c2;
  --shadowColor: #837f7f;
  --shadowEffect: -6px -6px 16px rgb(42 40 40 / 50%),
    6px 6px 16px rgb(119 117 114 / 50%);
  --borderEffect: 1px solid rgb(66 70 70);
}

.swal2-popup {
  background: var(--Modalbackground) !important;
}
.darkSwitch {
  position: fixed;
  z-index: 999;
  left: 1rem;
  bottom: 20%;
  opacity: 0.2;
  transition: opacity 0.7s ease-in-out;
}
.darkSwitch:hover {
  opacity: 1;
  transition: opacity 0.7s ease-in-out;
}

.bottomMenuIcons,
button {
  cursor: pointer;
}
.bottomMenuIcons {
  width: 3em;
}

.App {
  text-align: center;
}

blockquote {
  width: 70%;
  color: var(--colorText);
}

.App-logo {
  height: 56vmin;
  width: 56vmin;
  pointer-events: none;
  border-radius: 17%;
  padding: 10px;
  position: relative;
}

@media (prefers-reduced-motion: no-preference) {
  .Logo-Container {
    animation: float 6s ease-in-out infinite;
    border-radius: 17%;
    padding: 0;
    max-height: 62vmin;
    overflow: hidden;
  }
}

.App-header {
  /* background: transparent; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.copyright {
  position: absolute;
  top: 1em;
  color: #a19c9c;
  font-size: 12px;
  margin-left: 2rem;
}
/* .logoNiveau {
  position: absolute;
  top: 1em;
} */

.sepia {
  filter: sepia(0.5);
}

@media screen and (min-width: 1600px) {
  .Logo-Container {
    margin-top: 8em;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {
  .Logo-Container {
    margin-top: 5em;
  }
}

@media screen and (min-width: 1290px) {
  .how-content,
  .why-content {
    display: grid !important;
    padding: 43px;
    grid-template-columns: repeat(4, 1fr);
    width: 88%;
    grid-gap: 8px;
  }
  .how-content .card-container,
  .why-content .card-container {
    height: 91%;
  }
  .how-content div,
  .why-content div {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    width: 92%;
  }
  .card-left,
  .card-right {
    padding: 15px;
  }
  .how-content-text,
  .why-content-text {
    text-align: center;
    max-width: 84%;
  }
  .detailContent {
    bottom: 2em !important;
  }
  .deteiledConseils-container {
    width: 35% !important;
  }
}

@media screen and (min-width: 1000px) {
  .App-logo {
    height: 24vmin;
    width: 24vmin;
  }
  .presets-container {
    width: 100%;
  }
  .presets-content,
  .hr-preset {
    width: 42% !important;
  }
  .title-session-container {
    width: 36% !important;
  }
  blockquote {
    width: 41% !important;
  }
  .buttonStartTimer {
    transform: translate(0em, 2px) !important;
  }
  .statsContainer {
    transform: translate(5px, 4px) !important;
  }
  .history-list span {
    margin-right: inherit !important;
  }
}

@media screen and (min-width: 768px) {
  .bottomMenuIcons {
    width: 4em !important;
  }
  .buttonStartTimer {
    transform: translate(0em, 10px) !important;
  }
  .infoButtonContainer {
    bottom: 9em !important;
  }
}

/* @media only screen and (device-width: 360px) {
  .bottom-content {
    position: fixed !important;
    width: 97% !important;
    bottom: -5px;
    box-shadow: 0 0px 11px grey;
    height: 65px;
    justify-content: center;
    z-index: 99;
    scale: 0.8;
    left: -2px;
  }
} */

@media screen and (max-width: 360px) {
  .ribbon {
    display: none !important;
  }

  .presentation-presets {
    font-size: 12px !important;
    width: 84% !important;
  }
  .preset-img-title {
    display: none;
  }

  .eraseIcon {
    width: 24px !important;
    /* transform: translate(8.4em, 0em) !important; */
  }
}
@media screen and (max-width: 320px) {
  /* .presets-container {
    max-height: 79vh !important;
  } */
  .session-container {
    height: 94vh !important;
    top: -2em !important;
  }
  .counterNotStart {
    margin-bottom: 1em !important;
  }
  .watch-container img {
    width: 60px !important;
  }
  .watch-container {
    margin-top: -2em !important;
  }
  .eraseIcon {
    width: 24px !important;
    /* transform: translate(7.4em, 0em) !important; */
  }
  .App-logo {
    height: 39vmin !important;
    width: 38vmin !important;
  }
  .logoNiveauIcon {
    width: 86px !important;
    height: 86px !important;
  }
}

@media screen and (min-width: 651px) and (max-width: 992px) {
  .App-logo {
    height: 25vmin !important;
    width: 25vmin !important;
  }
  /* .navWrapper {
    width: 88% !important;
  } */
}
.secondColumn > img,
.firstColumn > img {
  transform: translate(0px, 7px);
}

/* @media screen and (min-width: 514px) {
  .presets-container {
    position: fixed;
  }
} */

.blue {
  border: 2px solid #8081d7;
  transition: border 0.4s ease-in-out both;
}
.purple {
  border: 2px solid #c155ff;
  transition: border 0.4s ease-in-out both;
}
.red {
  border: 2px solid #f65153;
  transition: border 0.4s ease-in-out both;
}
.white {
  border: 2px solid white;
  transition: border 0.4s ease-in-out both;
}
.black {
  border: 2px solid black;
  transition: border 0.4s ease-in-out both;
}
.colorBlue {
  background-color: #8081d7;
}
.colorPurple {
  background-color: #c155ff;
}
.colorRed {
  background-color: #f65153;
}
.colorBlack {
  background-color: darkslateblue;
}
.color-green {
  border-bottom: 4px solid #28a745 !important;
  border-right: 4px solid #28a745 !important;
}
.text-blue {
  color: #8081d7;
}
.text-purple {
  color: #c155ff;
}
.text-red {
  color: #f65153;
}
.text-black {
  color: darkslateblue;
}
.text-white {
  color: #ffffff;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.flex h3 {
  margin: 0;
}
.niveau {
  margin: 0;
  text-indent: 5px;
}

.rotate-center {
  animation: rotate-center 0.6s ease-in-out both;
}
@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.from-top-flower {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.logoNiveau {
  position: absolute;
  top: 16px;
}
.logoNiveauIcon {
  width: 110px;
  height: 110px;
  filter: var(--filter);
}

.text-tooltip-level {
  background-color: white;
  width: 11em;
  height: auto;
  position: absolute;
  /* top: 29em;
  left: 32%; */
  border-radius: 9px;
  box-shadow: 0 0 6px grey;
  padding: 8px;
  overflow: hidden;
  display: none;
  transform: translate(0px, -59px);
}
#triangle-code-level {
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 42px solid #fffff7;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  position: absolute;
  bottom: -31px;
  transform: scale(0.5);
  left: 62px;
}

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.corner {
  position: absolute;
  width: 100px;
  height: 180px;
  top: 0%;
  left: 0;
  background-image: radial-gradient(
    ellipse farthest-corner at top right,
    #f39c12 0%,
    #f8664f 100%
  );
  overflow: hidden;
  transform: rotate(41deg) translate(-5.4rem, -27px);
  box-shadow: 0 0 6px black;
  border-radius: 16px;
  cursor: pointer;
}
.avatar {
  top: 14px;
  filter: drop-shadow(0px 1px 1px black) opacity(0.85);
  position: absolute;
  left: 10px;
  cursor: pointer;
  animation: rotate-in-center 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
