.why-container,
.how-container,
.presets-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 7em;
  overflow-y: scroll;
}

.why-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-container {
  display: flex;
  border-radius: 10px !important;
  background: var(--cardColor) !important;
  /* height: 18rem; */
}
.card-left {
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  color: #fff;
}
.card-right {
  width: 66%;
}

.card-whyTitle {
  width: 104px;
  font-size: 14px;
}
.btn--primary,
.btn--primary:not([href]):not([tabindex]),
.btn--primary:hover {
  color: #fff;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
}
.btn {
  display: inline-block;
  padding: 8px 18px 9px;
  padding: 0.5rem 1.125rem 0.5625rem;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2.1875rem;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
  padding: 14px;
}

.text-center {
  text-align: center !important;
}
.mt-4 {
  margin-top: 24px !important;
  margin-top: 1.5rem !important;
}
