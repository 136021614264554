/* .selected {
   color: #40424d !important;
} */
.MuiSvgIcon-root-selected {
  fill: #40424d;
}
.navWrapper > ul li {
  list-style: none !important;
  display: flex;
  flex-direction: column;
  height: 60px;
}

a {
  text-decoration: none;
}
.navWrapper label {
  font-size: 0.85rem;
  color: var(--colorText);
}
.MuiSvgIcon-root {
  width: 1.4em !important;
  height: 1.4em !important;
}
.navWrapper ul li button {
  background: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
}

/* @media screen and (min-width: 381px) and (max-width: 767px) {
  .navWrapper {
    width: 77% !important;
  }
} */
/* @media screen and (device-width: 768px) {
  .navWrapper {
    width: 89% !important;
  }
} */

@media screen and (max-width: 380px) {
  /* .navWrapper {
    width: 100% !important;
    bottom: -5px;
    scale: 0.9;
    transform: translate(4px, 0px);
  } */
  /* .bottom-content {
    position: fixed !important;
    width: 98% !important;
    bottom: -5px;
    box-shadow: 0 0px 11px grey;
    height: 65px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 99;
    scale: 0.9;
  } */
  .App-header {
    width: 100% !important;
  }
  /* .presets-content {
    transform: translate(-25px, -5em);
  } */
  .why-container,
  .how-container,
  .presets-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10em;
    width: 100%;
  }
  .session-container {
    width: 100% !important;
  }
}

.bottomMenuIcons {
  filter: sepia(1) contrast(0.8);
}
.active,
.bottomMenuIcons--active {
  filter: inherit !important;
}

.animateIcon {
  animation: jello-horizontal 0.9s both;
}
.navigationLabels {
  border-bottom: 1px solid #7b16ff;
  animation: slide-in-fwd-center 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.labelsWithoutBorder {
  border-bottom: none;
  transition: all 0.6s ease-in-out both;
}
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
