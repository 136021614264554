.block-settings-wrapper {
  position: fixed;
  left: -136px;
  bottom: 20%;
  width: 134px;
  z-index: 9999;
  transition: all 0.3s;
}

.block-settings-wrapper section {
  display: block;
  clear: both;
  padding: 15px 12px 15px 15px;
  height: 65px;
  background: var(--Background);
  box-shadow: 0 1px 2px rgb(35 35 35 / 10%), transparent 0 0 0,
    transparent 0 0 0;
  position: relative;
  z-index: 0;
}
.block-settings-wrapper section h3 {
  clear: both;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #333;
  margin: 0 0 15px 0 !important;
}
.block-settings-wrapper section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.block-settings-wrapper section ul li {
  list-style: none;
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
}
.block-settings-wrapper section ul li:last-child {
  margin-bottom: 0;
}

.block-settings-wrapper section ul li.active a {
  font-weight: bold;
  color: #1f2528;
}
.block-settings-wrapper section hr {
  display: block;
  border: none;
  height: 1px;
  margin: 15px 0;
  background: #ccc;
}
.block-settings-wrapper section span {
  display: block;
  float: left;
  width: 25px;
  height: 25px;
  margin-right: 3px;
  cursor: pointer;
  border-radius: 2px;
}
.block-settings-wrapper section span.blue {
  background-color: #7c4fe0;
}
.block-settings-wrapper section span.green {
  background-color: #2aa275;
}
.block-settings-wrapper section span.red {
  background-color: #ff3d65;
}
.block-settings-wrapper section span.turquoise {
  background-color: #46cad7;
}
.block-settings-wrapper section span.purple {
  background-color: #d1397c;
}
.block-settings-wrapper section span.orange {
  background-color: #ee8f67;
}
.block-settings-wrapper section span.yellow {
  background-color: #e4d20c;
}
.block-settings-wrapper section span.grey {
  background-color: #6b798f;
}
.block-settings-wrapper #settings_close {
  width: 34px;
  height: 26px;
  display: block;
  position: absolute;
  right: -55px;
  top: 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #1f2528;
  transition: all 0.3s;
  border-radius: 0px 2px 2px 0px;
  text-indent: -9000px;
  cursor: pointer;
  padding: 10px;
  box-shadow: 0 1px 4px black;
  background-image: url(../../../images/counterOptions/stopclock-filled.svg);
  background-size: 78%;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.block-settings-wrapper #settings_close:hover {
  background-color: #434343;
}
.block-settings-wrapper section ul li a {
  color: #808080;
}
.block-settings-wrapper section ul li.active > i {
  margin-left: 5px;
  font-size: 14px;
  line-height: 20px;
}

.block-settings-wrapper #settings_close:hover {
  background-color: #434343;
}

/* Opened */
.opened-settings {
  left: 0px;
}

/* @media (max-width: 767px) {
  .block-settings-wrapper {
    display: none;
  }
} */
.choicesTime {
  width: 40px;
}
.choicesTime:first-child {
  margin-right: 1rem;
}
#block_settings {
  box-shadow: 0 1px 4px black;
}
#block_settings section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}
.buttonChoice {
  width: 54%;
  margin: 0.2rem;
}
.buttonChoice p {
  font-size: 10px;
  color: var(--colorText);
}
.block-settings-wrapper button:not(.buttonSelected) {
  opacity: 0.2;
}

button {
  background: transparent;
  border: none;
}
