.circle {
  height: 1em;
  width: 1em;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  font-size: 100%;
  box-sizing: content-box;
  font-size: 200px;
}
.circle:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 50%;
  box-sizing: border-box;
  border: solid 0.1em #cccccc;
  z-index: -1;
}
.circle > span {
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  font-size: 0.2em;
  position: absolute;
  top: -2px;
  left: 0;
  line-height: 5em;
  /* z-index: 9999; */
  color: #fff;
  white-space: nowrap;
  box-sizing: content-box;
  border-radius: 50%;
}
.circle > .bar {
  height: 100%;
  width: 100%;
  position: absolute;
  box-sizing: content-box;
}
.circle > .bar:before,
.circle > .bar:after {
  content: "";
  height: 80%;
  width: 80%;
  position: absolute;
  border: solid 0.1em #28a745;
  border-radius: 50%;
  box-sizing: content-box;
  clip: rect(0, 0.5em, 1em, 0);
}
.circle.big {
  font-size: 300px;
}
.circle.medium {
  font-size: 200px;
}
.circle.small {
  font-size: 100px;
}
.circle.x-small {
  font-size: 50px;
}
.circle[data-fill="0"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="0"] > .bar:before {
  transform: rotate(0deg);
}
.circle[data-fill="0"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="1"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="1"] > .bar:before {
  transform: rotate(3.6deg);
}
.circle[data-fill="1"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="2"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="2"] > .bar:before {
  transform: rotate(7.2deg);
}
.circle[data-fill="2"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="3"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="3"] > .bar:before {
  transform: rotate(10.8deg);
}
.circle[data-fill="3"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="4"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="4"] > .bar:before {
  transform: rotate(14.4deg);
}
.circle[data-fill="4"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="5"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="5"] > .bar:before {
  transform: rotate(18deg);
}
.circle[data-fill="5"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="6"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="6"] > .bar:before {
  transform: rotate(21.6deg);
}
.circle[data-fill="6"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="7"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="7"] > .bar:before {
  transform: rotate(25.2deg);
}
.circle[data-fill="7"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="8"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="8"] > .bar:before {
  transform: rotate(28.8deg);
}
.circle[data-fill="8"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="9"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="9"] > .bar:before {
  transform: rotate(32.4deg);
}
.circle[data-fill="9"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="10"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="10"] > .bar:before {
  transform: rotate(36deg);
}
.circle[data-fill="10"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="11"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="11"] > .bar:before {
  transform: rotate(39.6deg);
}
.circle[data-fill="11"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="12"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="12"] > .bar:before {
  transform: rotate(43.2deg);
}
.circle[data-fill="12"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="13"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="13"] > .bar:before {
  transform: rotate(46.8deg);
}
.circle[data-fill="13"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="14"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="14"] > .bar:before {
  transform: rotate(50.4deg);
}
.circle[data-fill="14"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="15"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="15"] > .bar:before {
  transform: rotate(54deg);
}
.circle[data-fill="15"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="16"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="16"] > .bar:before {
  transform: rotate(57.6deg);
}
.circle[data-fill="16"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="17"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="17"] > .bar:before {
  transform: rotate(61.2deg);
}
.circle[data-fill="17"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="18"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="18"] > .bar:before {
  transform: rotate(64.8deg);
}
.circle[data-fill="18"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="19"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="19"] > .bar:before {
  transform: rotate(68.4deg);
}
.circle[data-fill="19"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="20"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="20"] > .bar:before {
  transform: rotate(72deg);
}
.circle[data-fill="20"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="21"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="21"] > .bar:before {
  transform: rotate(75.6deg);
}
.circle[data-fill="21"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="22"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="22"] > .bar:before {
  transform: rotate(79.2deg);
}
.circle[data-fill="22"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="23"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="23"] > .bar:before {
  transform: rotate(82.8deg);
}
.circle[data-fill="23"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="24"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="24"] > .bar:before {
  transform: rotate(86.4deg);
}
.circle[data-fill="24"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="25"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="25"] > .bar:before {
  transform: rotate(90deg);
}
.circle[data-fill="25"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="26"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="26"] > .bar:before {
  transform: rotate(93.6deg);
}
.circle[data-fill="26"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="27"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="27"] > .bar:before {
  transform: rotate(97.2deg);
}
.circle[data-fill="27"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="28"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="28"] > .bar:before {
  transform: rotate(100.8deg);
}
.circle[data-fill="28"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="29"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="29"] > .bar:before {
  transform: rotate(104.4deg);
}
.circle[data-fill="29"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="30"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="30"] > .bar:before {
  transform: rotate(108deg);
}
.circle[data-fill="30"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="31"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="31"] > .bar:before {
  transform: rotate(111.6deg);
}
.circle[data-fill="31"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="32"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="32"] > .bar:before {
  transform: rotate(115.2deg);
}
.circle[data-fill="32"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="33"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="33"] > .bar:before {
  transform: rotate(118.8deg);
}
.circle[data-fill="33"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="34"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="34"] > .bar:before {
  transform: rotate(122.4deg);
}
.circle[data-fill="34"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="35"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="35"] > .bar:before {
  transform: rotate(126deg);
}
.circle[data-fill="35"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="36"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="36"] > .bar:before {
  transform: rotate(129.6deg);
}
.circle[data-fill="36"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="37"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="37"] > .bar:before {
  transform: rotate(133.2deg);
}
.circle[data-fill="37"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="38"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="38"] > .bar:before {
  transform: rotate(136.8deg);
}
.circle[data-fill="38"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="39"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="39"] > .bar:before {
  transform: rotate(140.4deg);
}
.circle[data-fill="39"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="40"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="40"] > .bar:before {
  transform: rotate(144deg);
}
.circle[data-fill="40"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="41"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="41"] > .bar:before {
  transform: rotate(147.6deg);
}
.circle[data-fill="41"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="42"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="42"] > .bar:before {
  transform: rotate(151.2deg);
}
.circle[data-fill="42"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="43"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="43"] > .bar:before {
  transform: rotate(154.8deg);
}
.circle[data-fill="43"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="44"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="44"] > .bar:before {
  transform: rotate(158.4deg);
}
.circle[data-fill="44"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="45"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="45"] > .bar:before {
  transform: rotate(162deg);
}
.circle[data-fill="45"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="46"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="46"] > .bar:before {
  transform: rotate(165.6deg);
}
.circle[data-fill="46"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="47"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="47"] > .bar:before {
  transform: rotate(169.2deg);
}
.circle[data-fill="47"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="48"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="48"] > .bar:before {
  transform: rotate(172.8deg);
}
.circle[data-fill="48"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="49"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="49"] > .bar:before {
  transform: rotate(176.4deg);
}
.circle[data-fill="49"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="50"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="50"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="50"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="51"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="51"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="51"] > .bar:after {
  transform: rotate(0deg);
}
.circle[data-fill="52"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="52"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="52"] > .bar:after {
  transform: rotate(187.2deg);
}
.circle[data-fill="53"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="53"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="53"] > .bar:after {
  transform: rotate(190.8deg);
}
.circle[data-fill="54"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="54"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="54"] > .bar:after {
  transform: rotate(194.4deg);
}
.circle[data-fill="55"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="55"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="55"] > .bar:after {
  transform: rotate(198deg);
}
.circle[data-fill="56"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="56"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="56"] > .bar:after {
  transform: rotate(201.6deg);
}
.circle[data-fill="57"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="57"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="57"] > .bar:after {
  transform: rotate(205.2deg);
}
.circle[data-fill="58"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="58"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="58"] > .bar:after {
  transform: rotate(208.8deg);
}
.circle[data-fill="59"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="59"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="59"] > .bar:after {
  transform: rotate(212.4deg);
}
.circle[data-fill="60"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="60"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="60"] > .bar:after {
  transform: rotate(216deg);
}
.circle[data-fill="61"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="61"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="61"] > .bar:after {
  transform: rotate(219.6deg);
}
.circle[data-fill="62"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="62"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="62"] > .bar:after {
  transform: rotate(223.2deg);
}
.circle[data-fill="63"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="63"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="63"] > .bar:after {
  transform: rotate(226.8deg);
}
.circle[data-fill="64"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="64"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="64"] > .bar:after {
  transform: rotate(230.4deg);
}
.circle[data-fill="65"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="65"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="65"] > .bar:after {
  transform: rotate(234deg);
}
.circle[data-fill="66"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="66"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="66"] > .bar:after {
  transform: rotate(237.6deg);
}
.circle[data-fill="67"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="67"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="67"] > .bar:after {
  transform: rotate(241.2deg);
}
.circle[data-fill="68"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="68"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="68"] > .bar:after {
  transform: rotate(244.8deg);
}
.circle[data-fill="69"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="69"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="69"] > .bar:after {
  transform: rotate(248.4deg);
}
.circle[data-fill="70"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="70"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="70"] > .bar:after {
  transform: rotate(252deg);
}
.circle[data-fill="71"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="71"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="71"] > .bar:after {
  transform: rotate(255.6deg);
}
.circle[data-fill="72"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="72"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="72"] > .bar:after {
  transform: rotate(259.2deg);
}
.circle[data-fill="73"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="73"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="73"] > .bar:after {
  transform: rotate(262.8deg);
}
.circle[data-fill="74"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="74"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="74"] > .bar:after {
  transform: rotate(266.4deg);
}
.circle[data-fill="75"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="75"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="75"] > .bar:after {
  transform: rotate(270deg);
}
.circle[data-fill="76"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="76"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="76"] > .bar:after {
  transform: rotate(273.6deg);
}
.circle[data-fill="77"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="77"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="77"] > .bar:after {
  transform: rotate(277.2deg);
}
.circle[data-fill="78"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="78"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="78"] > .bar:after {
  transform: rotate(280.8deg);
}
.circle[data-fill="79"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="79"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="79"] > .bar:after {
  transform: rotate(284.4deg);
}
.circle[data-fill="80"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="80"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="80"] > .bar:after {
  transform: rotate(288deg);
}
.circle[data-fill="81"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="81"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="81"] > .bar:after {
  transform: rotate(291.6deg);
}
.circle[data-fill="82"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="82"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="82"] > .bar:after {
  transform: rotate(295.2deg);
}
.circle[data-fill="83"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="83"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="83"] > .bar:after {
  transform: rotate(298.8deg);
}
.circle[data-fill="84"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="84"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="84"] > .bar:after {
  transform: rotate(302.4deg);
}
.circle[data-fill="85"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="85"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="85"] > .bar:after {
  transform: rotate(306deg);
}
.circle[data-fill="86"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="86"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="86"] > .bar:after {
  transform: rotate(309.6deg);
}
.circle[data-fill="87"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="87"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="87"] > .bar:after {
  transform: rotate(313.2deg);
}
.circle[data-fill="88"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="88"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="88"] > .bar:after {
  transform: rotate(316.8deg);
}
.circle[data-fill="89"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="89"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="89"] > .bar:after {
  transform: rotate(320.4deg);
}
.circle[data-fill="90"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="90"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="90"] > .bar:after {
  transform: rotate(324deg);
}
.circle[data-fill="91"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="91"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="91"] > .bar:after {
  transform: rotate(327.6deg);
}
.circle[data-fill="92"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="92"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="92"] > .bar:after {
  transform: rotate(331.2deg);
}
.circle[data-fill="93"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="93"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="93"] > .bar:after {
  transform: rotate(334.8deg);
}
.circle[data-fill="94"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="94"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="94"] > .bar:after {
  transform: rotate(338.4deg);
}
.circle[data-fill="95"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="95"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="95"] > .bar:after {
  transform: rotate(342deg);
}
.circle[data-fill="96"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="96"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="96"] > .bar:after {
  transform: rotate(345.6deg);
}
.circle[data-fill="97"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="97"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="97"] > .bar:after {
  transform: rotate(349.2deg);
}
.circle[data-fill="98"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="98"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="98"] > .bar:after {
  transform: rotate(352.8deg);
}
.circle[data-fill="99"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="99"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="99"] > .bar:after {
  transform: rotate(356.4deg);
}
.circle[data-fill="100"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="100"] > .bar:before {
  transform: rotate(180deg);
}
.circle[data-fill="100"] > .bar:after {
  transform: rotate(360deg);
}
