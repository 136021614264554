.slide-in-top {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: absolute;
  top: 24%;
  width: 86%;
  display: flex;
  z-index: 100;
}

@media screen and (max-width: 360px) {
  .slide-in-top {
    top: 14% !important;
    z-index: 200 !important;
  }
}
@media screen and (min-width: 361px) and (max-width: 375px) {
  .slide-in-top {
    top: 19% !important;
  }
  /* .eraseIcon {
    transform: translate(8.4em, 0em) !important;
  } */
}
.history-list span sub {
  color: var(--purpleText);
  font-style: 14px;
  font-weight: bold;
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.eraseIcon {
  width: 24px;
  /* transform: translate(9.3em, 0em); */
}
.card-history {
  width: 100%;
  min-height: 45vh;
  background: var(--Background);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.history-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2em;
}
.history-list p {
  font-style: 14px;
  font-weight: bold;
}
.history-list span {
  border: 1px dotted darkgrey;
  padding: 6px;
  margin-right: 2px;
  text-align: center;
  border-radius: 5px;
  min-height: 94px;
  background: var(--cardColor);
}
.modal-content {
  box-shadow: 0 2px 6px grey;
}
.eraseHistoButton {
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  border: none;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  margin-top: 10px;
}
.card-history .history-list:last-child {
  margin-bottom: 5em;
}
