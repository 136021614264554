.allSocialsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 999999;
  background: var(--Modalbackground);
  padding-bottom: 2rem;
  padding-top: 2rem;
  flex-wrap: wrap;
  border-radius: 17px;
  margin-top: 0%;
}
.headerInviteModal {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 20px;
  border-bottom: 1px solid lightgray;
}
.iconsInviteContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.4rem 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.invitecontent {
  margin-top: 1rem;
}
.invitecontent h3 {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.invitehead,
.inviteFooter {
  width: 80%;
}
.inviteFooter {
  text-align: center;
}
.headerInviteModal h1 {
  transform: translate(-28px, -1.2rem);
}
