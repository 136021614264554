.layout-container-App {
  height: 100vh;
  /* padding-bottom: 20em; */
  background-color: var(--Background);
}

/* .cover-App {
  width: 100%;
  height: 100vh;
} */

.wrapper-App {
  /* position: relative;
  width: 100%;
  height: 100vh;
  top: 0 !important; */
  background-color: var(--Background);
}

.bottomAppLayout {
  display: inherit !important;
  position: relative;
  z-index: 999999999999999;
}

.bottomLayout-none {
  display: none !important;
}

h1 {
  font-size: 18px;
  text-align: center;
  max-width: 80%;
  color: var(--colorText);
}
h2 {
  text-align: center;
}
.slide-out-bottom {
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(1000px);
    opacity: 0;
  }
}

.badges-section {
  position: fixed;
  left: 0;
  top: 4px;
  z-index: 49;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px;
  transform: translate(-1px, -6px) scale(0.89);
}

.Badge-container {
  cursor: pointer;
  width: 55px;
  height: 54px;
}
.attendanceBadge-container {
  justify-content: center;
}
.attendanceBadge-container,
.curiousBadge-container {
  display: flex;
  align-items: center;
}
.levels-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translate(-13px, 1px);
}
.levels-content p,
.levels-content strong {
  width: 60%;
}
.jello-horizontal {
  animation: jello-horizontal 0.9s both infinite;
}
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
:root {
  --borderWidth: 4px;
  --height: 12px;
  --width: 7px;
  --borderColor: #ffffff;
  --borderGradient: linear-gradient(to bottom, #000428, #004e92);
  --borderColorRight: #ffffff;
}

.check {
  display: inline-block;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
  position: absolute;
  top: 16px;
  z-index: 999;
}
.beginnerBadge,
.intermedBadge,
.confirmedBadge,
.expertBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

/* Progress bar */

.progress-linear {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 30px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
  position: relative;
}

.bar-linear {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  /* background: cornflowerblue; */
}

.percent-linear {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-family: tahoma, arial, helvetica;
  font-size: 17px;
  font-weight: bold;
}
.dark {
  background: var(--Background);
}
.helpContent p {
  width: 100% !important;
}
.ping {
  animation: ping 2s ease-in-out infinite both;
}
@keyframes ping {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
.modalConnexion {
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsInfo {
  position: absolute;
  width: 90%;
  height: auto;
  top: -31px;
  z-index: 999;
  background: whitesmoke;
  box-shadow: 0 2px 6px black;
  border-radius: 7px;
}
.headerInfos {
  display: flex;
  align-items: center;
  width: 99%;
  position: relative;
  height: 69px;
}
.headerInfos button {
  position: absolute;
  right: 15px;
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  border: none;
  padding: 10px;
  font-size: 17px;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  margin-top: 10px;
}

tr:last-child td {
  border: none !important;
}
.slideModal {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: absolute;
  top: 19%;
  width: 100%;
  display: flex;
  z-index: 100;
}
.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
}
.buttonsOptions {
  background: #7367f0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4px;
  border-radius: 7px;
  box-shadow: 0 1px 6px black;
  gap: 1px;
  margin-bottom: 5px;
  transform: scale(0.7);
  transition: 0.5s;
  filter: sepia(1) opacity(0.5);
  width: 50%;
}
.buttonsOptions:hover,
.buttonsOptions:active {
  transform: scale(0.8);
  transition: 0.5s;
  filter: sepia(0) opacity(1);
}
.buttonsOptions p {
  width: 60%;
}
.modesPictures {
  width: 120px;
}
.buttonsOptions h5 {
  font-size: 18px;
}
