/* html:hover .presentation,
body:hover .presentation {
  display: none;
} */

.infoButtonContainer {
  position: fixed;
  z-index: 100;
  right: 0px;
  width: 50px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 16em;
}

.presentation {
  position: fixed;
  right: 58px;
  bottom: 23px;
  text-shadow: 1px 1px 1px #000;
}

/* menu */
/* nav.float-action-button {
  position: fixed;
  bottom: 4px;
  right: 0;
  z-index: 98;
  margin: 1em;
} */
.amanoLogo {
  width: 40px;
  margin-top: -4px;
  background: #722cb9;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.buttons {
  /* box-shadow: 0 5px 11px -2px rgb(0 0 0 / 18%), 0 4px 12px -7px rgb(0 0 0 / 15%); */
  border-radius: 50%;
  width: 37px;
  height: 37px;
  color: #fff;
  font-size: 2.6em;
  padding: 1px 0 0 0;
  text-align: center;
  display: block;
  margin: 20px auto 0;
  position: relative;
  filter: opacity(0.6);
  transition: all 0.6s ease-out;
}
a.buttons:hover {
  filter: opacity(1);
}
a.buttons:hover > svg,
a.buttons:hover > img {
  box-shadow: 0 0 3px gray;
  border-radius: 50%;
}
.buttons > svg {
  transform: translate(0px, -1px);
}
.main-button > svg {
  transform: translate(0px, -5px);
}

a.buttons:active,
a.buttons:focus,
a.buttons:hover {
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); */
  text-decoration: none;
}

a.buttons:not(:last-child) {
  width: 40px;
  height: 40px;
  margin: 20px auto 0;
  opacity: 0;
  font-size: 1.6em;
  padding-top: 4px;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

a.buttons.main-button > i:first-child {
  position: relative;
  margin-top: 10px;
}

nav.float-action-button:hover a.buttons:not(:last-child) {
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin: 20px auto 0;
  transition: all 0.6s ease-in-out;
}

a.buttons:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
  /* background-color: #d32f2f; */
  /* Button color */
}

a.buttons:nth-last-child(1) i.fa {
  transform: rotate3d(0, 0, 1, 0);
  transition: content 0.4s, transform 0.4s, opacity 0.4s;
}

a.buttons:nth-last-child(1):hover i.fa {
  transform: rotate3d(0, 0, 1, -180deg);
}

a.buttons:nth-last-child(1) i.fa:nth-last-child(1) {
  position: absolute;
  margin: 10px 0 0 -32px;
}

a.buttons:nth-last-child(1) i.fa:nth-last-child(2) {
  opacity: 0;
}

a.buttons:nth-last-child(1):hover i.fa:nth-last-child(1) {
  opacity: 0;
}

a.buttons:nth-last-child(1):hover i.fa:nth-last-child(2) {
  opacity: 1;
}

a.buttons:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 50ms;
  transition-delay: 20ms;
  /* background-color: #3b5998; */
  /* Facebook color */
}

a.buttons:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 75ms;
  transition-delay: 40ms;
  /* background-color: #1da1f2; */
  /* Twitter color */
}

a.buttons:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 100ms;
  transition-delay: 60ms;
  /* background-color: #dd4b39; */
  /* Google plus color */
}

.tooltip.left {
  margin-left: -10px;
}
.graduateIcon {
  width: 40px;
  margin-top: -4px;
  background-image: radial-gradient(
    ellipse farthest-corner at top right,
    #f39c12 0%,
    #f8664f 100%
  );
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-6px, 0px);
}
