@font-face {
  font-family: "Permanent Marker";
  font-style: normal;
  font-weight: 400;
  src: url(./permanentMarker.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.container-text {
  margin: 0 auto;
  width: 100%;
  position: absolute;
  top: 6em;
  align-items: center;
  justify-content: center;
  display: flex;
}
.container-text svg {
  width: 88%;
  height: auto;
  fill: none;
  stroke: black;
  stroke-width: 4;
}
.container-text svg tspan > tspan {
  stroke-dasharray: 1500;
  stroke-dashoffset: -1500;
}
.container-text svg tspan > tspan:nth-of-type(1) {
  animation: draw 600ms 250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(2) {
  animation: draw 600ms 500ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(3) {
  animation: draw 600ms 750ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(4) {
  animation: draw 600ms 1000ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(5) {
  animation: draw 600ms 1250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(6) {
  animation: draw 600ms 1500ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(7) {
  animation: draw 600ms 1750ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(8) {
  animation: draw 600ms 2000ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(9) {
  animation: draw 600ms 2250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(10) {
  animation: draw 600ms 2500ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(11) {
  animation: draw 600ms 2750ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(12) {
  animation: draw 600ms 3000ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(13) {
  animation: draw 600ms 3250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(14) {
  animation: draw 600ms 3500ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(15) {
  animation: draw 600ms 3750ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(16) {
  animation: draw 600ms 4000ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(17) {
  animation: draw 600ms 4250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(18) {
  animation: draw 600ms 4500ms forwards;
}

@keyframes draw {
  100% {
    stroke-dashoffset: 0;
  }
}
