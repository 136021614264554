.minuteSlider {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  background: whitesmoke;
  border-radius: 9px;
  transform: translate3d(0, 0, 0);
  margin: 10px 2%;
  width: 106px;
  height: 102px;
  box-shadow: 0 2px 6px black;
  transition: all 0.7s ease-in-out;
}
.minuteContent {
  list-style: none;
  position: absolute;
  top: 0;
  left: 0 !important;
  overflow-y: scroll;
  width: 100%;
  height: 54%;
  right: auto;
  transform: translate(-38px, 23px);
}
.minuteLists {
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  margin-bottom: 7px;
  margin-top: 7px;
  overflow: hidden;
  width: 100%;
  height: 44px;
}
.arrow {
  border-top: 3px solid #ddd;
  border-left: 3px solid #ddd;
  height: 15px;
  width: 15px;
  display: inline-block;
  position: absolute;
}
.arrow.down {
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  transform: rotate(225deg);
}

.arrow.up {
  -webkit-transform: rotate(-315deg);
  -moz-transform: rotate(-315deg);
  transform: rotate(-315deg);
}
.tap_area.value_down {
  bottom: 0px;
  z-index: 11;
}

.tap_area .value_up .arrow {
  top: 10px;
}
.tap_area .value_down .arrow {
  bottom: 10px;
}
.tap_area.value_up {
  position: fixed;
  left: 42%;
  top: 10px;
  width: 100%;
  height: 20px;
  z-index: 10;
}
.tap_area.value_down {
  bottom: 7px;
  z-index: 11;
  position: fixed;
  width: 100%;
  height: 20px;
  left: 42%;
}
/* .minuteSlider-selected {
  border-radius: 50%;
  transition: all 0.7s ease-in-out;
} */

.number-selected {
  transition: all 0.7s ease-in-out;
  /* box-shadow: 0 2px 6px black, inset 7px 1px 10px grey, inset -5px -5px 13px !important; */
  color: #9e58e6 !important;
}
.arrow-selected {
  border-top: 3px solid #eeebeb;
  border-left: 3px solid #eeebeb;
  height: 15px;
  width: 15px;
  display: inline-block;
  position: absolute;
  transition: all 0.7s ease-in-out;
}
.info-icon {
  position: absolute;
  left: 0;
  top: 0;
}
.info-icon img {
  border-radius: 0 0 10px 0;
}
