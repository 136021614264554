.logoChoice-content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 202;
}
.overlay-logoChoice {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 200;
}

.configIcon {
  position: absolute;
  bottom: 21%;
  left: 2px;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  filter: drop-shadow(0 0 1.4px black) opacity(0.8);
}
.configIcon:hover,
.configIcon:active {
  transition: all 0.8s ease-in-out;
  filter: opacity(1) drop-shadow(0px 0px 2px black);
}

@keyframes rotate-in-center {
  0% {
    transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.logoChoice-container {
  background: var(--Modalbackground);
  width: 93%;
  /* height: 70vh; */
  position: absolute;
  z-index: 999;
  top: 10%;
  box-shadow: 0 2px 6px black;
  border-radius: 6px;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1000px) {
  .logoChoice-container {
    max-width: 24em !important;
  }
  .logoChoice-pictureContainer img {
    width: 33vmin;
    height: 33vmin;
  }
}
.logoChoice-text {
  color: white;
  font-weight: bold;
}

.logoPreview-picture {
  width: 58vmin;
  height: 58vmin;
}
.header-logoChoice {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* margin-bottom: 0.7em; */
  width: 100%;
  height: 40px;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  border-radius: 6px 6px 0 0;
}
.logoChoice-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  color: red;
  font-size: 22px;
  font-weight: bold;
}

.textLogoChoice {
  width: 72%;
  text-align: center;
}
.logoPreview {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.logoChoice-containerList {
  position: absolute;
  width: 95%;
  /* height: 107px; */
  bottom: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}
.logoChoice-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  position: relative;
  border-bottom: 2px solid purple;
  border-top: 2px solid purple;
  width: 81%;
}
@media screen and (max-width: 900px) {
  .logoChoice-list::-webkit-scrollbar {
    display: none !important;
  }
  .logoChoice-list {
    width: 79% !important;
  }
}
@media screen and (min-width: 900px) {
  .logoChoice-list::-webkit-scrollbar {
    height: 6px;
    background: whitesmoke;
  }
  .logoChoice-list::-webkit-scrollbar-thumb {
    background: #5c09e2;
  }
}
.logoChoice-ul {
  list-style: none;
  position: relative;
}
.logoChoice-arrowLeft {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  border: 2px solid purple;
  border-right: none;
  z-index: 999;
}
.logoChoice-arrowLeft svg,
.logoChoice-arrowRight svg {
  width: 32px;
  fill: var(--colorText);
}
.logoChoice-arrowRight {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 96%;
  display: flex;
  align-items: center;
  padding: 1px;
  border: 2px solid purple;
  border-left: none;
}
.logoChoice-arrowLeft,
.logoChoice-arrowRight {
  background: #92919466;
}
/* .logoChoice-ul:last-child .logoChoice-li {
  margin-right: 3rem;
} */
.logoChoice-li--selected {
  background: #7a7be0;
  filter: opacity(0.5);
}
.logoChoice-img {
  padding-right: 26px;
}
.logoChoice-button {
  background: none;
  border: none;
  width: 100%;
}
.logoChoice-button:first-child {
  border-right: 1px dotted black;
}
.logoChoice-middle {
  color: var(--colorText);
  height: 50vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  width: 100%;
}
.logoChoice-lowerMiddle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7em;
}
.defaultLogo {
  position: absolute;
  width: 100%;
  font-size: 15px;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colorText);
}
.logoChoice-li {
  width: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoChoice-close,
.curiousBadge-container,
.logoChoice-li,
.bestLearn-container,
.configIcon,
.eraseIcon {
  cursor: pointer;
}
.footerLogoChoice {
  /* position: absolute; */
  /* bottom: 4px; */
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
}
.buttonContainer-logoChoices {
  width: 79%;
  display: flex;
  background: transparent;
  border-radius: 5px;
  box-shadow: 0 2px 6px var(--shadowColor);
}
.placeholderLogo {
  width: 99px;
  filter: opacity(0.7);
}
