.EditProfileLover__imageContainer {
  position: relative;
  width: 100%;
  height: auto;
}

/* PictureCompress component */
.picture-compress__fabicon {
  /* position: relative !important;
  margin-top: -47px !important;
  margin-left: 142px !important; */
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  color: white !important;
  width: 30% !important;
  margin-bottom: 4vh !important;
}

.picture-compress__input {
  display: none !important;
}

/* ModalFilters Component */
.paper {
  position: absolute;
  min-width: 352px;
  min-height: 520px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 2px 2px 6px black;
  padding: 10px 17px 37px;
  outline: none !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

/* Picture filter component */
.pictureFilter__mainImage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#profile-picture {
  /* width: 100%; */
  border-radius: 50%;
  box-shadow: 0 2px 6px gray, inset 5px 4px 6px black;
}

.MuiFab-sizeMedium {
  width: 35px !important;
  height: 35px !important;
  opacity: 0.6;
}
.MuiAvatar-fallback {
  width: 75% !important;
  height: 75% !important;
}
.picture-compress__fabicon .MuiFab-label .MuiSvgIcon-root {
  width: 1em !important;
  height: 1em !important;
}
.header-profile h1 {
  color: white;
  text-transform: uppercase;
  transform: translate(0, -14px);
}
/* .cropper-crop-box {
  width: 80% !important;
  height: 80% !important;
} */
