.contentInfo-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid gray;
}
.contentInfo-center h5 {
  width: 71%;
  text-align: center;
  padding: 17px;
}
.icons-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  padding: 0;
  grid-column-gap: 10px;
  transform: scale(0.88);
  margin-top: 1rem;
}
.Icon-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contentInfo-center p {
  width: 78%;
  margin-bottom: 2rem;
}
.headerInfos h1 {
  color: white;
}
.contentInfoModal {
  background: var(--Modalbackground);
  color: var(--colorText);
  padding-top: 1rem;
}
.headerInfos {
  display: flex;
  align-items: center;
  width: 99%;
  position: relative;
  height: 69 px;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  border-radius: 6px 6px 0 0;
}
.contentInfo-footer {
  width: 90%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contentInfo-footer button {
  font-size: 21px;
}
.statsButton {
  width: 100px !important;
  height: 39px;
  border-radius: 9px;
  margin-top: 1rem;
}
.rtt {
  font-size: 0.7rem;
  width: 100% !important;
  display: grid;
  text-align: center;
  margin-top: 1rem;
  font-style: italic;
}
