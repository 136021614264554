.startingApp-container {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(#7b16ff 21%, rgb(68 0 204 / 61%) 88%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 0rem;
  justify-content: space-evenly;
  overflow: hidden;
}
/* .pictContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
} */
/* .pictContent img {
  transform: translate(0px, 5rem);
}
.startingApp-container p {
  transform: translate(0px, -7rem);
} */

.center-loadingApp {
  /* margin-bottom: -8rem;
  margin-top: 6rem; */
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.startingApp-container h5 {
  font-size: 80%;
  word-break: break-word;
}
.loader-startApp {
  position: relative;
  width: 200px;
  height: 200px;
  margin-top: -5rem;
  margin-bottom: 7rem;
}

/* The dot */
.loader-startApp > .dot-startApp {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;

  width: 160px;
  height: 100px;

  margin-top: -50px;
  margin-left: -80px;

  border-radius: 5px;

  background-color: #1e3f57;

  transform: preserve-3d;

  animation: dot1 2.8s cubic-bezier(0.55, 0.3, 0.24, 0.99) infinite;
}

.loader-startApp > .dot-startApp:nth-child(2) {
  z-index: 11;

  width: 150px;
  height: 90px;

  margin-top: -45px;
  margin-left: -75px;

  border-radius: 3px;

  background-color: #3c617d;

  animation-name: dot2;
}

.loader-startApp > .dot-startApp:nth-child(3) {
  z-index: 12;

  width: 40px;
  height: 20px;

  margin-top: 50px;
  margin-left: -20px;

  border-radius: 0 0 5px 5px;

  background-color: #6bb2cd;

  animation-name: dot3;
}

@keyframes dot1 {
  3%,
  97% {
    width: 160px;
    height: 100px;

    margin-top: -50px;
    margin-left: -80px;
  }
  30%,
  36% {
    width: 80px;
    height: 120px;

    margin-top: -60px;
    margin-left: -40px;
  }
  63%,
  69% {
    width: 40px;
    height: 80px;

    margin-top: -40px;
    margin-left: -20px;
  }
}

@keyframes dot2 {
  3%,
  97% {
    width: 150px;
    height: 90px;

    margin-top: -45px;
    margin-left: -75px;
  }
  30%,
  36% {
    width: 70px;
    height: 96px;

    margin-top: -48px;
    margin-left: -35px;
  }
  63%,
  69% {
    width: 32px;
    height: 60px;

    margin-top: -30px;
    margin-left: -16px;
  }
}

@keyframes dot3 {
  3%,
  97% {
    width: 40px;
    height: 20px;

    margin-top: 50px;
    margin-left: -20px;
  }
  30%,
  36% {
    width: 8px;
    height: 8px;

    margin-top: 49px;
    margin-left: -5px;

    border-radius: 8px;
  }
  63%,
  69% {
    width: 16px;
    height: 4px;

    margin-top: -37px;
    margin-left: -8px;

    border-radius: 10px;
  }
}

.tracking-in-contract-bck-top {
  animation: tracking-in-contract-bck-top 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.roll-in-left {
  animation: roll-in-left 0.6s 1s ease-out both;
}

@keyframes roll-in-left {
  0% {
    transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

.tracking-in-expand-fwd-bottom {
  animation: tracking-in-expand-fwd-bottom 0.8s 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.slide-in-blurred-right {
  animation: slide-in-blurred-right 0.6s 0.2s cubic-bezier(0.23, 1, 0.32, 1)
    both;
}
@keyframes slide-in-blurred-right {
  0% {
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform-origin: 0% 50%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
.bounce-in-bottom {
  animation: bounce-in-bottom 1.1s both;
}
@keyframes bounce-in-bottom {
  0% {
    transform: translateY(500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(65px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}
.loadingText {
  position: relative;
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.5em;
}
.loadingText:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 7em;
  white-space: nowrap;
  color: #fff;
  animation: loading 4.8s linear;
}
@keyframes loading {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}
.footer-loadingApp.bounce-in-bottom {
  height: 11%;
}
