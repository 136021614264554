.QrCodemodal button {
  background-color: #0d52e9;
  position: relative;
  color: #fff;
  border: none;
  padding: 1.25em 2em;
  font-size: 0.75em;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: background 0.25s cubic-bezier(0.55, 0, 0.1, 1);
}
.QrCodemodal button:hover {
  background: #0c4ddb;
}
.QrCodemodal button:focus {
  outline: none;
}
.headerModal {
  background: rgb(92, 9, 226);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 7px;
}

/**
 * Overlay
 * -- only show for tablet and up
 */
/* @media only screen and (min-width: 40em) { */
.QrCodemodal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1),
    visibility 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.QrCodemodal-overlay.active {
  opacity: 1;
  visibility: visible;
}
/* } */
/**
 * QrCodeModal
 */
.QrCodemodal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  width: 22em;
  max-width: 75rem;
  min-height: 20rem;
  padding: 1rem;
  border-radius: 3px;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  transform: scale(1.2);
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.QrCodemodal .close-QrCodemodal {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 15px;
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1),
    transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.3s;
  color: black;
}
.QrCodemodal .close-QrCodemodal svg {
  width: 1.75em;
  height: 1.75em;
}
.QrCodemodal .QrCodemodal-content {
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.3s;
  width: 100%;
  height: auto;
}
.QrCodemodal.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.QrCodemodal.active .QrCodemodal-content {
  opacity: 1;
}
.QrCodemodal.active .close-QrCodemodal {
  transform: translateY(10px);
  opacity: 1;
}
.qrlink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
}
.qrlink img {
  width: 100%;
}
.qrlink sub {
  font-size: 0.7rem;
  font-weight: bold;
}

/**
 * Mobile styling
 */
@media only screen and (max-width: 39.9375em) {
  .QrCodemodal {
    position: fixed;
    width: 81%;
    height: 53%;
    border-radius: 0;
    transform: scale(1.1);
    padding: 0 !important;
  }

  .close-QrCodemodal {
    right: 10px !important;
  }
}
