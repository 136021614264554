.how-content,
.why-content {
  padding-bottom: 2em;
}

.how-content .card-container {
  display: flex;
  border-radius: 10px !important;
  min-height: 11em !important;
}
.detailContent {
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999999;
  box-shadow: 6px 4px 28px black;
  border-radius: 10px 10px 0 0;
  overflow-y: scroll;
}
.deteiledConseils-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px !important;
}
.cardConseil-header {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  width: 100%;
  position: relative;
  height: 60px;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  color: white;
  border-radius: 8px 8px 0 0;
  top: 0;
}

.how-content-text {
  color: var(--colorText);
}
.closeConseils {
  position: absolute;
  right: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #cd3434;
  font-size: 27px;
}
.detailedConseil-card {
  padding: 26px;
  /* height: 100vh; */
  border: 1px dashed lightgray;
  /* width: 76%; */
  background: var(--Background);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding-top: 9.5em; */
}
.slide-in-bottom {
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.slide-out-bottom {
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(1000px);
    opacity: 0;
  }
}
.detailedConseil-card p {
  margin-bottom: 16px;
  max-width: 86%;
  font-size: 1.1rem;
  color: var(--colorText);
}
.cardConseil-header h1 {
  width: 60%;
  font-size: 16px;
  color: white !important;
}
.subConseils {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0;
  border-radius: 5px;
  margin: 15px;
}
.conseils {
  font-size: 12px;
  font-style: italic;
  color: #fff;
  margin-top: 1em;
  cursor: pointer;
  margin-bottom: 1em;
}
/* .detailedConseil-card p:first-child {
  margin-top: 6em;
} */
.footer-conseils {
  margin-bottom: 10em;
}
.scale-out-center {
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes scale-out-center {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 1;
  }
}
