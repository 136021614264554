#myModal2 {
  background: whitesmoke;
  transform: scale(1.1);
}

.text-center-histo {
  text-align: center !important;
  height: 56px;
  border-top: 1px solid lightgrey;
}
::-webkit-scrollbar {
  width: 2px;
  background: whitesmoke;
}

::-webkit-scrollbar-thumb {
  background: #5c09e2;
}
.modalcontentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
}
.modalcontentHeader p {
  font-size: 1rem;
  color: white;
}
