.buttonPresets-container {
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
  width: 100%;
  margin-top: 1em;
  width: 100%;
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  grid-row-gap: 43px;
}
.buttonPresets {
  border-radius: 50%;
  height: 87px;
  width: 87px;
  border: none;
  font-style: italic;
  text-transform: lowercase;
  box-shadow: 0 1px 6px grey, inset 0 2px 6px white;
  font-size: 0.67rem;
  word-break: break-word;
}
.soundPicture {
  height: 76px;
  width: 76px;
}
.presets-content {
  width: 100%;
  /* height: 22em; */
  /* transform: translate(0px, -2em); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 12px;
}
.buttonPresets-container:last-child {
  margin-top: 1em;
}
.buttonPresets:hover,
.buttonPresets--active,
.buttonPresets--active:hover {
  color: #7b16ff;
  border: 1px solid #7b16ff;
  box-shadow: 0 1px 11px rgb(0 0 0 / 25%);
}
.buttonPresets:hover h2,
.buttonPresets--active h2,
.buttonPresets--active h2:hover {
  font-style: italic;
  font-weight: lighter;
  text-transform: lowercase;
}
.sound-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.soundTitle {
  /* position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  height: 100%; */
  display: none;
}
.hiddenText,
.hiddenText blockquote {
  filter: opacity(0);
  height: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}
.preset-content-hidden {
  /* height: 27em !important; */
  transform: inherit !important;
}
.soundPresets-content {
  display: flex;
  /* align-items: center;
    justify-content: center; */
  position: relative;
}
.timerName {
  position: absolute;
  top: 37%;
  left: 9%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 7px;
  box-shadow: 0 1px 1px grey;
  padding: 2px;
}
.preset1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonPresets-container .preset1:first-child .soundPresets-content h2 {
  left: 12% !important;
}
.Night,
.Tropical,
.Lake,
.Volcan {
  border-radius: 50%;
}
.headerMultimedia {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--backgroundHeaderMultimedia);
  height: 55px;
  position: fixed;
  top: 0;
  box-shadow: 0 0px 7px var(--shadow);
  left: 0;
  z-index: 100;
  overflow: hidden;
}

.pictureMantra img {
  overflow: hidden;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-clip: content-box;
  position: relative;
}
.sound-contentIntermed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.om .namah .shivaya {
  width: 50%;
}
.textMantra {
  position: absolute;
  margin-top: 19px;
  transform: scale(0.78);
  max-width: 90px;
  text-align: center;
  /* word-break: break-word; */
}
/* .soundTitleIntermed {
  word-break: break-word;
  width: 100%;
  font-size: 144%;
} */
.soundPictureConfirmed {
  border-radius: 50%;
}
.sound-contentConfirmed {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textFrequency {
  position: absolute;
  padding: 8px;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 17px;
}
.pictureFrequency {
  margin-top: 4px;
}
.confirmed {
  position: relative;
}
.text-tooltip {
  background-color: white;
  width: 11em;
  height: auto;
  position: absolute;
  top: -52px;
  left: -28px;
  border-radius: 9px;
  box-shadow: 0 0 6px grey;
  padding: 8px;
  overflow: hidden;
  display: none;
}
.text-tooltip--active,
.text-tooltip:hover {
  overflow: visible;
  display: inherit !important;
}

#triangle-code {
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 42px solid #fffff7;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  position: absolute;
  bottom: -31px;
  transform: scale(0.5);
  left: 46px;
}

.buttonPresets-container .confirmed:nth-child(1n) .text-tooltip--active {
  left: -17px !important;
}

.buttonPresets-container
  .confirmed:nth-child(1n)
  .text-tooltip--active
  #triangle-code {
  left: 36px !important;
}
.buttonPresets-container .confirmed:nth-child(3n) .text-tooltip--active {
  left: -32px !important;
}

.buttonPresets-container
  .confirmed:nth-child(3n)
  .text-tooltip--active
  #triangle-code {
  left: 49px !important;
}
.DurationHead {
  border-right: 1px dashed mediumpurple;
}
.DurationHead,
.SoundHeader {
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 4px;
}
