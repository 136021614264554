#skill {
  list-style: none;
  font: 12px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  width: 296px;
  margin: 6px auto 0;
  position: relative;
  line-height: 2em;
  padding: 56px 0;
}

#skill li {
  margin-bottom: 50px;
  background: #e9e5e2;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#e1ddd9),
    to(#e9e5e2)
  );
  background-image: -webkit-linear-gradient(to bottom, #e1ddd9, #e9e5e2);
  background-image: -moz-linear-gradient(to bottom, #e1ddd9, #e9e5e2);
  background-image: -ms-linear-gradient(to bottom, #e1ddd9, #e9e5e2);
  background-image: -o-linear-gradient(to bottom, #e1ddd9, #e9e5e2);
  background-image: linear-gradient(to to bottom, #e1ddd9, #e9e5e2);
  height: 20px;
  border-radius: 10px;
  -moz-box-shadow: 0 1px 0px #bebbb9 inset, 0 1px 0 #fcfcfc;
  -webkit-box-shadow: 0 1px 0px #bebbb9 inset, 0 1px 0 #fcfcfc;
  box-shadow: 0 1px 0px #bebbb9 inset, 0 1px 0 #fcfcfc;
}

#skill li h3 {
  position: relative;
  top: -25px;
}

.bar-loaderApp {
  height: 18px;
  margin: 1px 2px;
  position: absolute;
  border-radius: 10px;
  -moz-box-shadow: 0 1px 0px #fcfcfc inset, 0 1px 0 #bebbb9;
  -webkit-box-shadow: 0 1px 0px #fcfcfc inset, 0 1px 0 #bebbb9;
  box-shadow: 0 1px 0px #fcfcfc inset, 0 1px 0 #bebbb9;
}

.graphic-design {
  width: 100%;
  animation: graphic-design 4.4s ease-out;

  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
}

@keyframes graphic-design {
  0% {
    width: 0px;
  }
  10% {
    width: 5%;
  }
  15% {
    width: 10%;
  }
  20% {
    width: 12%;
  }
  21% {
    width: 13%;
  }
  23% {
    width: 17%;
  }
  25% {
    width: 25%;
  }
  30% {
    width: 26%;
  }
  50% {
    width: 40%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 70%;
  }
  80% {
    width: 72%;
  }
  85% {
    width: 73%;
  }
  90% {
    width: 84%;
  }
  100% {
    width: 97%;
  }
}
