.stage-hello {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: hiddenTime 5s ease-in-out both;
  text-align: center;
}

.stage-hello p {
  font-size: 14px;
  color: darkgrey;
  word-break: break-word;
  position: relative;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  width: 23em;
  animation: type 3s 4s steps(60, end);
}

.stage-hello span {
  animation: blink 1s infinite;
}

@keyframes type {
  from {
    width: 0;
    filter: opacity(0);
  }
  to {
    filter: opacity(1);
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: 100;
    filter: opacity(1);
  }
}

@keyframes blink {
  to {
    opacity: 0;
  }
}

::selection {
  background: black;
}
@keyframes hiddenTime {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
