.profile-container {
  width: 367px;
  height: auto;
  /* background: var(--Modalbackground); */
  border-radius: 18px;
  box-shadow: 0 2px 6px #6b6767;
  padding-bottom: 2rem;
  position: relative;
}
.header-profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 145px;
  background: #7b16ff;
  box-shadow: 0 5px 7px #565557;
  /* background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  ); */
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #f39c12 0%,
    #f8664f 100%
  );
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  padding: 7px;
}
.content-profile {
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 63;
  color: var(--colorText);
}
.profile-innerContent {
  position: absolute;
  top: 91px;
  left: 32%;
  right: auto;
  /* box-shadow: 0 2px 6px black;
  border-radius: 50%;
  height: 112px; */
}
.EditProfileLover__imageContainer .container {
  position: absolute;
  top: 0;
}
.react-responsive-modal-modal {
  margin: 0 !important;
  text-align: inherit !important;
  min-width: 352px;
}
#profile-picture {
  width: 135px;
  height: 135px;
  position: relative;
}
.dropzone button span svg {
  transform: scale(0.9);
}
.bottom-headerProfile p,
.bottom-headerProfile a p {
  color: white;
  font-weight: bold;
}
.bottom-headerProfile p {
  transform: translate(-140px, 40px);
  text-align: center;
}
.bottom-headerProfile a p {
  transform: translate(8.8rem, 11px);
}
.profile-middleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  height: 46%;
  color: white;
}
.profile-middleHead,
.profile-nextmiddleHead,
.profile-lastmiddleHead {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
  position: relative;
}
.session-profileHead {
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #f39c12 0%,
    #f8664f 100%
  );

  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  color: white;
  width: auto;
  padding: 4.5px 9px 4.5px 9px;
  position: relative;
}
.profile-middleHead .text-tooltip {
  background-color: white;
  width: 11em;
  height: auto;
  position: absolute;
  top: -27px;
  left: 8px;
  border-radius: 9px;
  box-shadow: 0 0 6px grey;
  padding: 8px;
  z-index: 9;
}

.session-profileHead .text-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.session-profileHead .text-tooltip sub {
  color: black;
  transform: inherit;
}
.infoSigns {
  width: 16px;
  position: absolute;
  right: 6px;
  top: 3px;
  transition: all 0.7s ease-in-out both;
}
.infosSignsSelect {
  filter: sepia(1);
  transition: all 0.7s ease-in-out both;
}
.profile-lastmiddleHead sub {
  padding: 9px;
}
.session-profileHead h3 {
  transform: translate(7px, -3px);
  font-size: 1.8rem;
  padding: 2px;
  margin-right: 1.1rem;
}
.session-profileHead sub {
  font-size: 0.99rem;
  transform: translate(-9px, 5px);
}
.profile-middleHead h2,
.profile-nextmiddleHead h2,
.profile-lastmiddleHead h2 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.closeProfileButton,
.buttonCloseInvite {
  color: #ee0979;
  border: none;
  padding: 7px;
  position: absolute;
  top: 0;
  right: 11px;
  font-size: 1.9rem;
  background: transparent;
  font-weight: bold;
  text-shadow: 0px 1px 2px black;
}
.modalProfileBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-lastmiddleHead .session-profileHead sub {
  font-size: 11px;
  transform: translate(0px, 0px) !important;
  font-weight: bold;
}
.bottom-headerProfile p {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
  padding: 7px;
  border-radius: 0 14px 0 14px;
  /* border: 1px solid white;
  box-shadow: inset 0 2px 6px white; */
}
.badgesSestionProfile {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-evenly;
}
/* .profile-middleContent .attendanceBadge-container {
  margin-right: 1rem;
  margin-left: 1rem;
}
.profile-middleContent .bestLearn-container {
  margin-right: 1rem;
}
.profile-middleContent .levelsBadges-container {
  margin-right: 0.3rem;
} */
.profile-middleContent .badgesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-middleContent .badgesContainer h2 {
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
}
.deleteIcon {
  position: absolute;
  color: #674f4f;
  transform: scale(0.7) translate(-11px, -11px);
  z-index: 99999;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.image-bg:before {
  border-radius: 14px;
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: linear-gradient(-47deg, #7c4fe0 0%, #4528dc 100%);
}
.contour {
  width: 232px;
  position: absolute;
  transform: translate(-56.2px, -46.5px);
  filter: drop-shadow(2px 4px 6px black);
}

.EditProfileLover__imageContainer span {
  width: 100%;
  height: auto;
}
.logoProfile img {
  transform: scale(0.76);
  margin-right: 0rem;
}
.logoProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 207px;
  height: 0px;
  margin-bottom: 1.03rem;
}
.logoProfile sub {
  color: white;
}
.deleteIcon svg {
  filter: opacity(0.32);
}

.allSocialsContainer {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.levelsBadges-container .beginnerBadge .check {
  top: 9px !important;
}
.profile-lastmiddleHead .text-tooltip {
  background-color: white;
  width: 11em;
  height: auto;
  position: absolute;
  top: -51px;
  left: 11px;
  border-radius: 9px;
  box-shadow: 0 0 6px grey;
  padding: 8px;
  z-index: 8;
}
.profile-lastmiddleHead .text-tooltip sub {
  color: black;
}
.profile-lastmiddleHead .session-profileHead sub {
  padding: 14px 9px 10px 9px;
}
.profile-nextmiddleHead .session-profileHead .text-tooltip {
  top: -61px !important;
}
