@import url(https://fonts.googleapis.com/css?family=Anton&display=swap" rel="stylesheet);
* {
  margin: 0 auto;
  padding: 0 auto;
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.card-history::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.card-history::-webkit-scrollbar-thumb {
  background: #ff0000;
}
div {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

:root {
  --Background: #e9ebf0;
  --backgroundHeaderMultimedia: #e9ebf0;
  --MenuColor: #eeeeee;
  --colorText: #000;
  --cardColor: whitesmoke;
  --globalTextColor: #848181;
  --dotted: lightgray;
  --shadow: #000;
  --purpleText: #602eba;
  --colorChildWelcome: darkblue;
  --menuShadow: grey;
  --animateTextFill: #645f5a;
  --animateTextStroke: #4d4f53;
  --Modalbackground: white;
  --warningTextColor: #6a0d06;
  --dashedBorderColor: #cdc9c9;
  --shadowColor: #000;
  --shadowEffect: -6px -6px 16px rgba(255, 255, 255, 0.5),
    6px 6px 16px rgba(209, 205, 199, 0.5);
  --borderEffect: 1px solid rgba(255, 255, 255, 0.2);
}

.dark-mode {
  --Background: radial-gradient(#565c5d, #353737);
  --backgroundHeaderMultimedia: radial-gradient(#3a3b3b, #0f1010);
  --MenuColor: #6c6c68ed;
  --colorText: #fff;
  --cardColor: #282826;
  --filter: drop-shadow(0 0 1.5rem whitesmoke);
  --globalTextColor: #848181;
  --dotted: none;
  --shadow: #fff;
  --purpleText: #9e58e6;
  --colorChildWelcome: #aaaaeb;
  --menuShadow: transparent;
  --animateTextFill: #000;
  --animateTextStroke: #fff;
  --Modalbackground: #000;
  --warningTextColor: #f05d52;
  --dashedBorderColor: #565555c2;
  --shadowColor: #837f7f;
  --shadowEffect: -6px -6px 16px rgb(42 40 40 / 50%),
    6px 6px 16px rgb(119 117 114 / 50%);
  --borderEffect: 1px solid rgb(66 70 70);
}

.swal2-popup {
  background: white !important;
  background: var(--Modalbackground) !important;
}
.darkSwitch {
  position: fixed;
  z-index: 999;
  left: 1rem;
  bottom: 20%;
  opacity: 0.2;
  transition: opacity 0.7s ease-in-out;
}
.darkSwitch:hover {
  opacity: 1;
  transition: opacity 0.7s ease-in-out;
}

.bottomMenuIcons,
button {
  cursor: pointer;
}
.bottomMenuIcons {
  width: 3em;
}

.App {
  text-align: center;
}

blockquote {
  width: 70%;
  color: #000;
  color: var(--colorText);
}

.App-logo {
  height: 56vmin;
  width: 56vmin;
  pointer-events: none;
  border-radius: 17%;
  padding: 10px;
  position: relative;
}

@media (prefers-reduced-motion: no-preference) {
  .Logo-Container {
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
    border-radius: 17%;
    padding: 0;
    max-height: 62vmin;
    overflow: hidden;
  }
}

.App-header {
  /* background: transparent; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.copyright {
  position: absolute;
  top: 1em;
  color: #a19c9c;
  font-size: 12px;
  margin-left: 2rem;
}
/* .logoNiveau {
  position: absolute;
  top: 1em;
} */

.sepia {
  -webkit-filter: sepia(0.5);
          filter: sepia(0.5);
}

@media screen and (min-width: 1600px) {
  .Logo-Container {
    margin-top: 8em;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {
  .Logo-Container {
    margin-top: 5em;
  }
}

@media screen and (min-width: 1290px) {
  .how-content,
  .why-content {
    display: grid !important;
    padding: 43px;
    grid-template-columns: repeat(4, 1fr);
    width: 88%;
    grid-gap: 8px;
  }
  .how-content .card-container,
  .why-content .card-container {
    height: 91%;
  }
  .how-content div,
  .why-content div {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    width: 92%;
  }
  .card-left,
  .card-right {
    padding: 15px;
  }
  .how-content-text,
  .why-content-text {
    text-align: center;
    max-width: 84%;
  }
  .detailContent {
    bottom: 2em !important;
  }
  .deteiledConseils-container {
    width: 35% !important;
  }
}

@media screen and (min-width: 1000px) {
  .App-logo {
    height: 24vmin;
    width: 24vmin;
  }
  .presets-container {
    width: 100%;
  }
  .presets-content,
  .hr-preset {
    width: 42% !important;
  }
  .title-session-container {
    width: 36% !important;
  }
  blockquote {
    width: 41% !important;
  }
  .buttonStartTimer {
    -webkit-transform: translate(0em, 2px) !important;
            transform: translate(0em, 2px) !important;
  }
  .statsContainer {
    -webkit-transform: translate(5px, 4px) !important;
            transform: translate(5px, 4px) !important;
  }
  .history-list span {
    margin-right: inherit !important;
  }
}

@media screen and (min-width: 768px) {
  .bottomMenuIcons {
    width: 4em !important;
  }
  .buttonStartTimer {
    -webkit-transform: translate(0em, 10px) !important;
            transform: translate(0em, 10px) !important;
  }
  .infoButtonContainer {
    bottom: 9em !important;
  }
}

/* @media only screen and (device-width: 360px) {
  .bottom-content {
    position: fixed !important;
    width: 97% !important;
    bottom: -5px;
    box-shadow: 0 0px 11px grey;
    height: 65px;
    justify-content: center;
    z-index: 99;
    scale: 0.8;
    left: -2px;
  }
} */

@media screen and (max-width: 360px) {
  .ribbon {
    display: none !important;
  }

  .presentation-presets {
    font-size: 12px !important;
    width: 84% !important;
  }
  .preset-img-title {
    display: none;
  }

  .eraseIcon {
    width: 24px !important;
    /* transform: translate(8.4em, 0em) !important; */
  }
}
@media screen and (max-width: 320px) {
  /* .presets-container {
    max-height: 79vh !important;
  } */
  .session-container {
    height: 94vh !important;
    top: -2em !important;
  }
  .counterNotStart {
    margin-bottom: 1em !important;
  }
  .watch-container img {
    width: 60px !important;
  }
  .watch-container {
    margin-top: -2em !important;
  }
  .eraseIcon {
    width: 24px !important;
    /* transform: translate(7.4em, 0em) !important; */
  }
  .App-logo {
    height: 39vmin !important;
    width: 38vmin !important;
  }
  .logoNiveauIcon {
    width: 86px !important;
    height: 86px !important;
  }
}

@media screen and (min-width: 651px) and (max-width: 992px) {
  .App-logo {
    height: 25vmin !important;
    width: 25vmin !important;
  }
  /* .navWrapper {
    width: 88% !important;
  } */
}
.secondColumn > img,
.firstColumn > img {
  -webkit-transform: translate(0px, 7px);
          transform: translate(0px, 7px);
}

/* @media screen and (min-width: 514px) {
  .presets-container {
    position: fixed;
  }
} */

.blue {
  border: 2px solid #8081d7;
  transition: border 0.4s ease-in-out both;
}
.purple {
  border: 2px solid #c155ff;
  transition: border 0.4s ease-in-out both;
}
.red {
  border: 2px solid #f65153;
  transition: border 0.4s ease-in-out both;
}
.white {
  border: 2px solid white;
  transition: border 0.4s ease-in-out both;
}
.black {
  border: 2px solid black;
  transition: border 0.4s ease-in-out both;
}
.colorBlue {
  background-color: #8081d7;
}
.colorPurple {
  background-color: #c155ff;
}
.colorRed {
  background-color: #f65153;
}
.colorBlack {
  background-color: darkslateblue;
}
.color-green {
  border-bottom: 4px solid #28a745 !important;
  border-right: 4px solid #28a745 !important;
}
.text-blue {
  color: #8081d7;
}
.text-purple {
  color: #c155ff;
}
.text-red {
  color: #f65153;
}
.text-black {
  color: darkslateblue;
}
.text-white {
  color: #ffffff;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.flex h3 {
  margin: 0;
}
.niveau {
  margin: 0;
  text-indent: 5px;
}

.rotate-center {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
          animation: rotate-center 0.6s ease-in-out both;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.from-top-flower {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.logoNiveau {
  position: absolute;
  top: 16px;
}
.logoNiveauIcon {
  width: 110px;
  height: 110px;
  -webkit-filter: var(--filter);
          filter: var(--filter);
}

.text-tooltip-level {
  background-color: white;
  width: 11em;
  height: auto;
  position: absolute;
  /* top: 29em;
  left: 32%; */
  border-radius: 9px;
  box-shadow: 0 0 6px grey;
  padding: 8px;
  overflow: hidden;
  display: none;
  -webkit-transform: translate(0px, -59px);
          transform: translate(0px, -59px);
}
#triangle-code-level {
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 42px solid #fffff7;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  position: absolute;
  bottom: -31px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  left: 62px;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.corner {
  position: absolute;
  width: 100px;
  height: 180px;
  top: 0%;
  left: 0;
  background-image: radial-gradient(
    ellipse farthest-corner at top right,
    #f39c12 0%,
    #f8664f 100%
  );
  overflow: hidden;
  -webkit-transform: rotate(41deg) translate(-5.4rem, -27px);
          transform: rotate(41deg) translate(-5.4rem, -27px);
  box-shadow: 0 0 6px black;
  border-radius: 16px;
  cursor: pointer;
}
.avatar {
  top: 14px;
  -webkit-filter: drop-shadow(0px 1px 1px black) opacity(0.85);
          filter: drop-shadow(0px 1px 1px black) opacity(0.85);
  position: absolute;
  left: 10px;
  cursor: pointer;
  -webkit-animation: rotate-in-center 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: rotate-in-center 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@font-face {
  font-family: "Permanent Marker";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/permanentMarker.bd982c23.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.container-text {
  margin: 0 auto;
  width: 100%;
  position: absolute;
  top: 6em;
  align-items: center;
  justify-content: center;
  display: flex;
}
.container-text svg {
  width: 88%;
  height: auto;
  fill: none;
  stroke: black;
  stroke-width: 4;
}
.container-text svg tspan > tspan {
  stroke-dasharray: 1500;
  stroke-dashoffset: -1500;
}
.container-text svg tspan > tspan:nth-of-type(1) {
  -webkit-animation: draw 600ms 250ms forwards;
          animation: draw 600ms 250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(2) {
  -webkit-animation: draw 600ms 500ms forwards;
          animation: draw 600ms 500ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(3) {
  -webkit-animation: draw 600ms 750ms forwards;
          animation: draw 600ms 750ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(4) {
  -webkit-animation: draw 600ms 1000ms forwards;
          animation: draw 600ms 1000ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(5) {
  -webkit-animation: draw 600ms 1250ms forwards;
          animation: draw 600ms 1250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(6) {
  -webkit-animation: draw 600ms 1500ms forwards;
          animation: draw 600ms 1500ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(7) {
  -webkit-animation: draw 600ms 1750ms forwards;
          animation: draw 600ms 1750ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(8) {
  -webkit-animation: draw 600ms 2000ms forwards;
          animation: draw 600ms 2000ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(9) {
  -webkit-animation: draw 600ms 2250ms forwards;
          animation: draw 600ms 2250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(10) {
  -webkit-animation: draw 600ms 2500ms forwards;
          animation: draw 600ms 2500ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(11) {
  -webkit-animation: draw 600ms 2750ms forwards;
          animation: draw 600ms 2750ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(12) {
  -webkit-animation: draw 600ms 3000ms forwards;
          animation: draw 600ms 3000ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(13) {
  -webkit-animation: draw 600ms 3250ms forwards;
          animation: draw 600ms 3250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(14) {
  -webkit-animation: draw 600ms 3500ms forwards;
          animation: draw 600ms 3500ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(15) {
  -webkit-animation: draw 600ms 3750ms forwards;
          animation: draw 600ms 3750ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(16) {
  -webkit-animation: draw 600ms 4000ms forwards;
          animation: draw 600ms 4000ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(17) {
  -webkit-animation: draw 600ms 4250ms forwards;
          animation: draw 600ms 4250ms forwards;
}
.container-text svg tspan > tspan:nth-of-type(18) {
  -webkit-animation: draw 600ms 4500ms forwards;
          animation: draw 600ms 4500ms forwards;
}

@-webkit-keyframes draw {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  100% {
    stroke-dashoffset: 0;
  }
}

.layout-container-App {
  height: 100vh;
  /* padding-bottom: 20em; */
  background-color: var(--Background);
}

/* .cover-App {
  width: 100%;
  height: 100vh;
} */

.wrapper-App {
  /* position: relative;
  width: 100%;
  height: 100vh;
  top: 0 !important; */
  background-color: var(--Background);
}

.bottomAppLayout {
  display: inherit !important;
  position: relative;
  z-index: 999999999999999;
}

.bottomLayout-none {
  display: none !important;
}

h1 {
  font-size: 18px;
  text-align: center;
  max-width: 80%;
  color: var(--colorText);
}
h2 {
  text-align: center;
}
.slide-out-bottom {
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
          animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}

.badges-section {
  position: fixed;
  left: 0;
  top: 4px;
  z-index: 49;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px;
  -webkit-transform: translate(-1px, -6px) scale(0.89);
          transform: translate(-1px, -6px) scale(0.89);
}

.Badge-container {
  cursor: pointer;
  width: 55px;
  height: 54px;
}
.attendanceBadge-container {
  justify-content: center;
}
.attendanceBadge-container,
.curiousBadge-container {
  display: flex;
  align-items: center;
}
.levels-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-transform: translate(-13px, 1px);
          transform: translate(-13px, 1px);
}
.levels-content p,
.levels-content strong {
  width: 60%;
}
.jello-horizontal {
  -webkit-animation: jello-horizontal 0.9s both infinite;
          animation: jello-horizontal 0.9s both infinite;
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
:root {
  --borderWidth: 4px;
  --height: 12px;
  --width: 7px;
  --borderColor: #ffffff;
  --borderGradient: linear-gradient(to bottom, #000428, #004e92);
  --borderColorRight: #ffffff;
}

.check {
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 12px;
  height: var(--height);
  width: 7px;
  width: var(--width);
  border-bottom: 4px solid #ffffff;
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: 4px solid #ffffff;
  border-right: var(--borderWidth) solid var(--borderColor);
  position: absolute;
  top: 16px;
  z-index: 999;
}
.beginnerBadge,
.intermedBadge,
.confirmedBadge,
.expertBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

/* Progress bar */

.progress-linear {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 30px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
  position: relative;
}

.bar-linear {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  /* background: cornflowerblue; */
}

.percent-linear {
  position: absolute;
  top: 15px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0;
  font-family: tahoma, arial, helvetica;
  font-size: 17px;
  font-weight: bold;
}
.dark {
  background: var(--Background);
}
.helpContent p {
  width: 100% !important;
}
.ping {
  -webkit-animation: ping 2s ease-in-out infinite both;
          animation: ping 2s ease-in-out infinite both;
}
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}
.modalConnexion {
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsInfo {
  position: absolute;
  width: 90%;
  height: auto;
  top: -31px;
  z-index: 999;
  background: whitesmoke;
  box-shadow: 0 2px 6px black;
  border-radius: 7px;
}
.headerInfos {
  display: flex;
  align-items: center;
  width: 99%;
  position: relative;
  height: 69px;
}
.headerInfos button {
  position: absolute;
  right: 15px;
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  border: none;
  padding: 10px;
  font-size: 17px;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  margin-top: 10px;
}

tr:last-child td {
  border: none !important;
}
.slideModal {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: absolute;
  top: 19%;
  width: 100%;
  display: flex;
  z-index: 100;
}
.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
}
.buttonsOptions {
  background: #7367f0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4px;
  border-radius: 7px;
  box-shadow: 0 1px 6px black;
  grid-gap: 1px;
  gap: 1px;
  margin-bottom: 5px;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  transition: 0.5s;
  -webkit-filter: sepia(1) opacity(0.5);
          filter: sepia(1) opacity(0.5);
  width: 50%;
}
.buttonsOptions:hover,
.buttonsOptions:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: 0.5s;
  -webkit-filter: sepia(0) opacity(1);
          filter: sepia(0) opacity(1);
}
.buttonsOptions p {
  width: 60%;
}
.modesPictures {
  width: 120px;
}
.buttonsOptions h5 {
  font-size: 18px;
}

/* .selected {
   color: #40424d !important;
} */
.MuiSvgIcon-root-selected {
  fill: #40424d;
}
.navWrapper > ul li {
  list-style: none !important;
  display: flex;
  flex-direction: column;
  height: 60px;
}

a {
  text-decoration: none;
}
.navWrapper label {
  font-size: 0.85rem;
  color: var(--colorText);
}
.MuiSvgIcon-root {
  width: 1.4em !important;
  height: 1.4em !important;
}
.navWrapper ul li button {
  background: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
}

/* @media screen and (min-width: 381px) and (max-width: 767px) {
  .navWrapper {
    width: 77% !important;
  }
} */
/* @media screen and (device-width: 768px) {
  .navWrapper {
    width: 89% !important;
  }
} */

@media screen and (max-width: 380px) {
  /* .navWrapper {
    width: 100% !important;
    bottom: -5px;
    scale: 0.9;
    transform: translate(4px, 0px);
  } */
  /* .bottom-content {
    position: fixed !important;
    width: 98% !important;
    bottom: -5px;
    box-shadow: 0 0px 11px grey;
    height: 65px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 99;
    scale: 0.9;
  } */
  .App-header {
    width: 100% !important;
  }
  /* .presets-content {
    transform: translate(-25px, -5em);
  } */
  .why-container,
  .how-container,
  .presets-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10em;
    width: 100%;
  }
  .session-container {
    width: 100% !important;
  }
}

.bottomMenuIcons {
  -webkit-filter: sepia(1) contrast(0.8);
          filter: sepia(1) contrast(0.8);
}
.active,
.bottomMenuIcons--active {
  -webkit-filter: inherit !important;
          filter: inherit !important;
}

.animateIcon {
  -webkit-animation: jello-horizontal 0.9s both;
          animation: jello-horizontal 0.9s both;
}
.navigationLabels {
  border-bottom: 1px solid #7b16ff;
  -webkit-animation: slide-in-fwd-center 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-fwd-center 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.labelsWithoutBorder {
  border-bottom: none;
  transition: all 0.6s ease-in-out both;
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

.scroll {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 40px;
  right: -80px;
  /* border-radius: 100%; */
  /* background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(90, 92, 106, 1) 0%,
    rgba(32, 45, 58, 1) 81.3%
  ); */
  color: #fff;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  /* box-shadow: 0 0 5px 0px #888; */
  transition: 300ms;
  z-index: 99999;
}

.scroll i {
  margin-top: 10px;
  text-shadow: 0 0 2px #fff;
}

.scroll:hover i {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

@-webkit-keyframes rotate {
  from {
    margin-top: 15px;
  }
  to {
    margin-top: 5px;
  }
}

@keyframes rotate {
  from {
    margin-top: 15px;
  }
  to {
    margin-top: 5px;
  }
}

.visible {
  right: 5px;
  transition: all 400ms;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  bottom: 2.5em;
}

.fa-angle-up:hover {
  transition: margin-top 0.3s ease-in-out;
  margin-top: -5px;
}
#jsScroll img {
  width: 95%;
}

.QrCodemodal button {
  background-color: #0d52e9;
  position: relative;
  color: #fff;
  border: none;
  padding: 1.25em 2em;
  font-size: 0.75em;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: background 0.25s cubic-bezier(0.55, 0, 0.1, 1);
}
.QrCodemodal button:hover {
  background: #0c4ddb;
}
.QrCodemodal button:focus {
  outline: none;
}
.headerModal {
  background: rgb(92, 9, 226);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 7px;
}

/**
 * Overlay
 * -- only show for tablet and up
 */
/* @media only screen and (min-width: 40em) { */
.QrCodemodal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1),
    visibility 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.QrCodemodal-overlay.active {
  opacity: 1;
  visibility: visible;
}
/* } */
/**
 * QrCodeModal
 */
.QrCodemodal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  width: 22em;
  max-width: 75rem;
  min-height: 20rem;
  padding: 1rem;
  border-radius: 3px;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.QrCodemodal .close-QrCodemodal {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 15px;
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1),
    -webkit-transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1),
    transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1),
    transform 0.6s cubic-bezier(0.55, 0, 0.1, 1),
    -webkit-transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.3s;
  color: black;
}
.QrCodemodal .close-QrCodemodal svg {
  width: 1.75em;
  height: 1.75em;
}
.QrCodemodal .QrCodemodal-content {
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.3s;
  width: 100%;
  height: auto;
}
.QrCodemodal.active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.QrCodemodal.active .QrCodemodal-content {
  opacity: 1;
}
.QrCodemodal.active .close-QrCodemodal {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: 1;
}
.qrlink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
}
.qrlink img {
  width: 100%;
}
.qrlink sub {
  font-size: 0.7rem;
  font-weight: bold;
}

/**
 * Mobile styling
 */
@media only screen and (max-width: 39.9375em) {
  .QrCodemodal {
    position: fixed;
    width: 81%;
    height: 53%;
    border-radius: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    padding: 0 !important;
  }

  .close-QrCodemodal {
    right: 10px !important;
  }
}

.circle {
  height: 1em;
  width: 1em;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  font-size: 100%;
  box-sizing: content-box;
  font-size: 200px;
}
.circle:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 50%;
  box-sizing: border-box;
  border: solid 0.1em #cccccc;
  z-index: -1;
}
.circle > span {
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  font-size: 0.2em;
  position: absolute;
  top: -2px;
  left: 0;
  line-height: 5em;
  /* z-index: 9999; */
  color: #fff;
  white-space: nowrap;
  box-sizing: content-box;
  border-radius: 50%;
}
.circle > .bar {
  height: 100%;
  width: 100%;
  position: absolute;
  box-sizing: content-box;
}
.circle > .bar:before,
.circle > .bar:after {
  content: "";
  height: 80%;
  width: 80%;
  position: absolute;
  border: solid 0.1em #28a745;
  border-radius: 50%;
  box-sizing: content-box;
  clip: rect(0, 0.5em, 1em, 0);
}
.circle.big {
  font-size: 300px;
}
.circle.medium {
  font-size: 200px;
}
.circle.small {
  font-size: 100px;
}
.circle.x-small {
  font-size: 50px;
}
.circle[data-fill="0"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="0"] > .bar:before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="0"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="1"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="1"] > .bar:before {
  -webkit-transform: rotate(3.6deg);
          transform: rotate(3.6deg);
}
.circle[data-fill="1"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="2"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="2"] > .bar:before {
  -webkit-transform: rotate(7.2deg);
          transform: rotate(7.2deg);
}
.circle[data-fill="2"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="3"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="3"] > .bar:before {
  -webkit-transform: rotate(10.8deg);
          transform: rotate(10.8deg);
}
.circle[data-fill="3"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="4"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="4"] > .bar:before {
  -webkit-transform: rotate(14.4deg);
          transform: rotate(14.4deg);
}
.circle[data-fill="4"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="5"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="5"] > .bar:before {
  -webkit-transform: rotate(18deg);
          transform: rotate(18deg);
}
.circle[data-fill="5"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="6"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="6"] > .bar:before {
  -webkit-transform: rotate(21.6deg);
          transform: rotate(21.6deg);
}
.circle[data-fill="6"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="7"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="7"] > .bar:before {
  -webkit-transform: rotate(25.2deg);
          transform: rotate(25.2deg);
}
.circle[data-fill="7"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="8"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="8"] > .bar:before {
  -webkit-transform: rotate(28.8deg);
          transform: rotate(28.8deg);
}
.circle[data-fill="8"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="9"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="9"] > .bar:before {
  -webkit-transform: rotate(32.4deg);
          transform: rotate(32.4deg);
}
.circle[data-fill="9"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="10"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="10"] > .bar:before {
  -webkit-transform: rotate(36deg);
          transform: rotate(36deg);
}
.circle[data-fill="10"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="11"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="11"] > .bar:before {
  -webkit-transform: rotate(39.6deg);
          transform: rotate(39.6deg);
}
.circle[data-fill="11"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="12"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="12"] > .bar:before {
  -webkit-transform: rotate(43.2deg);
          transform: rotate(43.2deg);
}
.circle[data-fill="12"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="13"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="13"] > .bar:before {
  -webkit-transform: rotate(46.8deg);
          transform: rotate(46.8deg);
}
.circle[data-fill="13"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="14"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="14"] > .bar:before {
  -webkit-transform: rotate(50.4deg);
          transform: rotate(50.4deg);
}
.circle[data-fill="14"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="15"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="15"] > .bar:before {
  -webkit-transform: rotate(54deg);
          transform: rotate(54deg);
}
.circle[data-fill="15"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="16"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="16"] > .bar:before {
  -webkit-transform: rotate(57.6deg);
          transform: rotate(57.6deg);
}
.circle[data-fill="16"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="17"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="17"] > .bar:before {
  -webkit-transform: rotate(61.2deg);
          transform: rotate(61.2deg);
}
.circle[data-fill="17"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="18"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="18"] > .bar:before {
  -webkit-transform: rotate(64.8deg);
          transform: rotate(64.8deg);
}
.circle[data-fill="18"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="19"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="19"] > .bar:before {
  -webkit-transform: rotate(68.4deg);
          transform: rotate(68.4deg);
}
.circle[data-fill="19"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="20"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="20"] > .bar:before {
  -webkit-transform: rotate(72deg);
          transform: rotate(72deg);
}
.circle[data-fill="20"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="21"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="21"] > .bar:before {
  -webkit-transform: rotate(75.6deg);
          transform: rotate(75.6deg);
}
.circle[data-fill="21"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="22"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="22"] > .bar:before {
  -webkit-transform: rotate(79.2deg);
          transform: rotate(79.2deg);
}
.circle[data-fill="22"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="23"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="23"] > .bar:before {
  -webkit-transform: rotate(82.8deg);
          transform: rotate(82.8deg);
}
.circle[data-fill="23"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="24"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="24"] > .bar:before {
  -webkit-transform: rotate(86.4deg);
          transform: rotate(86.4deg);
}
.circle[data-fill="24"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="25"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="25"] > .bar:before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.circle[data-fill="25"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="26"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="26"] > .bar:before {
  -webkit-transform: rotate(93.6deg);
          transform: rotate(93.6deg);
}
.circle[data-fill="26"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="27"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="27"] > .bar:before {
  -webkit-transform: rotate(97.2deg);
          transform: rotate(97.2deg);
}
.circle[data-fill="27"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="28"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="28"] > .bar:before {
  -webkit-transform: rotate(100.8deg);
          transform: rotate(100.8deg);
}
.circle[data-fill="28"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="29"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="29"] > .bar:before {
  -webkit-transform: rotate(104.4deg);
          transform: rotate(104.4deg);
}
.circle[data-fill="29"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="30"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="30"] > .bar:before {
  -webkit-transform: rotate(108deg);
          transform: rotate(108deg);
}
.circle[data-fill="30"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="31"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="31"] > .bar:before {
  -webkit-transform: rotate(111.6deg);
          transform: rotate(111.6deg);
}
.circle[data-fill="31"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="32"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="32"] > .bar:before {
  -webkit-transform: rotate(115.2deg);
          transform: rotate(115.2deg);
}
.circle[data-fill="32"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="33"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="33"] > .bar:before {
  -webkit-transform: rotate(118.8deg);
          transform: rotate(118.8deg);
}
.circle[data-fill="33"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="34"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="34"] > .bar:before {
  -webkit-transform: rotate(122.4deg);
          transform: rotate(122.4deg);
}
.circle[data-fill="34"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="35"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="35"] > .bar:before {
  -webkit-transform: rotate(126deg);
          transform: rotate(126deg);
}
.circle[data-fill="35"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="36"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="36"] > .bar:before {
  -webkit-transform: rotate(129.6deg);
          transform: rotate(129.6deg);
}
.circle[data-fill="36"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="37"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="37"] > .bar:before {
  -webkit-transform: rotate(133.2deg);
          transform: rotate(133.2deg);
}
.circle[data-fill="37"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="38"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="38"] > .bar:before {
  -webkit-transform: rotate(136.8deg);
          transform: rotate(136.8deg);
}
.circle[data-fill="38"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="39"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="39"] > .bar:before {
  -webkit-transform: rotate(140.4deg);
          transform: rotate(140.4deg);
}
.circle[data-fill="39"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="40"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="40"] > .bar:before {
  -webkit-transform: rotate(144deg);
          transform: rotate(144deg);
}
.circle[data-fill="40"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="41"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="41"] > .bar:before {
  -webkit-transform: rotate(147.6deg);
          transform: rotate(147.6deg);
}
.circle[data-fill="41"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="42"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="42"] > .bar:before {
  -webkit-transform: rotate(151.2deg);
          transform: rotate(151.2deg);
}
.circle[data-fill="42"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="43"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="43"] > .bar:before {
  -webkit-transform: rotate(154.8deg);
          transform: rotate(154.8deg);
}
.circle[data-fill="43"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="44"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="44"] > .bar:before {
  -webkit-transform: rotate(158.4deg);
          transform: rotate(158.4deg);
}
.circle[data-fill="44"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="45"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="45"] > .bar:before {
  -webkit-transform: rotate(162deg);
          transform: rotate(162deg);
}
.circle[data-fill="45"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="46"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="46"] > .bar:before {
  -webkit-transform: rotate(165.6deg);
          transform: rotate(165.6deg);
}
.circle[data-fill="46"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="47"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="47"] > .bar:before {
  -webkit-transform: rotate(169.2deg);
          transform: rotate(169.2deg);
}
.circle[data-fill="47"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="48"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="48"] > .bar:before {
  -webkit-transform: rotate(172.8deg);
          transform: rotate(172.8deg);
}
.circle[data-fill="48"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="49"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="49"] > .bar:before {
  -webkit-transform: rotate(176.4deg);
          transform: rotate(176.4deg);
}
.circle[data-fill="49"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="50"] > .bar {
  clip: rect(0, 1em, 1em, 0.5em);
}
.circle[data-fill="50"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="50"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="51"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="51"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="51"] > .bar:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.circle[data-fill="52"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="52"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="52"] > .bar:after {
  -webkit-transform: rotate(187.2deg);
          transform: rotate(187.2deg);
}
.circle[data-fill="53"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="53"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="53"] > .bar:after {
  -webkit-transform: rotate(190.8deg);
          transform: rotate(190.8deg);
}
.circle[data-fill="54"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="54"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="54"] > .bar:after {
  -webkit-transform: rotate(194.4deg);
          transform: rotate(194.4deg);
}
.circle[data-fill="55"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="55"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="55"] > .bar:after {
  -webkit-transform: rotate(198deg);
          transform: rotate(198deg);
}
.circle[data-fill="56"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="56"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="56"] > .bar:after {
  -webkit-transform: rotate(201.6deg);
          transform: rotate(201.6deg);
}
.circle[data-fill="57"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="57"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="57"] > .bar:after {
  -webkit-transform: rotate(205.2deg);
          transform: rotate(205.2deg);
}
.circle[data-fill="58"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="58"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="58"] > .bar:after {
  -webkit-transform: rotate(208.8deg);
          transform: rotate(208.8deg);
}
.circle[data-fill="59"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="59"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="59"] > .bar:after {
  -webkit-transform: rotate(212.4deg);
          transform: rotate(212.4deg);
}
.circle[data-fill="60"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="60"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="60"] > .bar:after {
  -webkit-transform: rotate(216deg);
          transform: rotate(216deg);
}
.circle[data-fill="61"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="61"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="61"] > .bar:after {
  -webkit-transform: rotate(219.6deg);
          transform: rotate(219.6deg);
}
.circle[data-fill="62"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="62"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="62"] > .bar:after {
  -webkit-transform: rotate(223.2deg);
          transform: rotate(223.2deg);
}
.circle[data-fill="63"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="63"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="63"] > .bar:after {
  -webkit-transform: rotate(226.8deg);
          transform: rotate(226.8deg);
}
.circle[data-fill="64"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="64"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="64"] > .bar:after {
  -webkit-transform: rotate(230.4deg);
          transform: rotate(230.4deg);
}
.circle[data-fill="65"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="65"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="65"] > .bar:after {
  -webkit-transform: rotate(234deg);
          transform: rotate(234deg);
}
.circle[data-fill="66"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="66"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="66"] > .bar:after {
  -webkit-transform: rotate(237.6deg);
          transform: rotate(237.6deg);
}
.circle[data-fill="67"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="67"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="67"] > .bar:after {
  -webkit-transform: rotate(241.2deg);
          transform: rotate(241.2deg);
}
.circle[data-fill="68"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="68"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="68"] > .bar:after {
  -webkit-transform: rotate(244.8deg);
          transform: rotate(244.8deg);
}
.circle[data-fill="69"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="69"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="69"] > .bar:after {
  -webkit-transform: rotate(248.4deg);
          transform: rotate(248.4deg);
}
.circle[data-fill="70"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="70"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="70"] > .bar:after {
  -webkit-transform: rotate(252deg);
          transform: rotate(252deg);
}
.circle[data-fill="71"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="71"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="71"] > .bar:after {
  -webkit-transform: rotate(255.6deg);
          transform: rotate(255.6deg);
}
.circle[data-fill="72"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="72"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="72"] > .bar:after {
  -webkit-transform: rotate(259.2deg);
          transform: rotate(259.2deg);
}
.circle[data-fill="73"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="73"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="73"] > .bar:after {
  -webkit-transform: rotate(262.8deg);
          transform: rotate(262.8deg);
}
.circle[data-fill="74"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="74"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="74"] > .bar:after {
  -webkit-transform: rotate(266.4deg);
          transform: rotate(266.4deg);
}
.circle[data-fill="75"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="75"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="75"] > .bar:after {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.circle[data-fill="76"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="76"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="76"] > .bar:after {
  -webkit-transform: rotate(273.6deg);
          transform: rotate(273.6deg);
}
.circle[data-fill="77"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="77"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="77"] > .bar:after {
  -webkit-transform: rotate(277.2deg);
          transform: rotate(277.2deg);
}
.circle[data-fill="78"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="78"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="78"] > .bar:after {
  -webkit-transform: rotate(280.8deg);
          transform: rotate(280.8deg);
}
.circle[data-fill="79"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="79"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="79"] > .bar:after {
  -webkit-transform: rotate(284.4deg);
          transform: rotate(284.4deg);
}
.circle[data-fill="80"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="80"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="80"] > .bar:after {
  -webkit-transform: rotate(288deg);
          transform: rotate(288deg);
}
.circle[data-fill="81"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="81"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="81"] > .bar:after {
  -webkit-transform: rotate(291.6deg);
          transform: rotate(291.6deg);
}
.circle[data-fill="82"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="82"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="82"] > .bar:after {
  -webkit-transform: rotate(295.2deg);
          transform: rotate(295.2deg);
}
.circle[data-fill="83"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="83"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="83"] > .bar:after {
  -webkit-transform: rotate(298.8deg);
          transform: rotate(298.8deg);
}
.circle[data-fill="84"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="84"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="84"] > .bar:after {
  -webkit-transform: rotate(302.4deg);
          transform: rotate(302.4deg);
}
.circle[data-fill="85"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="85"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="85"] > .bar:after {
  -webkit-transform: rotate(306deg);
          transform: rotate(306deg);
}
.circle[data-fill="86"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="86"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="86"] > .bar:after {
  -webkit-transform: rotate(309.6deg);
          transform: rotate(309.6deg);
}
.circle[data-fill="87"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="87"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="87"] > .bar:after {
  -webkit-transform: rotate(313.2deg);
          transform: rotate(313.2deg);
}
.circle[data-fill="88"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="88"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="88"] > .bar:after {
  -webkit-transform: rotate(316.8deg);
          transform: rotate(316.8deg);
}
.circle[data-fill="89"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="89"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="89"] > .bar:after {
  -webkit-transform: rotate(320.4deg);
          transform: rotate(320.4deg);
}
.circle[data-fill="90"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="90"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="90"] > .bar:after {
  -webkit-transform: rotate(324deg);
          transform: rotate(324deg);
}
.circle[data-fill="91"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="91"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="91"] > .bar:after {
  -webkit-transform: rotate(327.6deg);
          transform: rotate(327.6deg);
}
.circle[data-fill="92"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="92"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="92"] > .bar:after {
  -webkit-transform: rotate(331.2deg);
          transform: rotate(331.2deg);
}
.circle[data-fill="93"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="93"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="93"] > .bar:after {
  -webkit-transform: rotate(334.8deg);
          transform: rotate(334.8deg);
}
.circle[data-fill="94"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="94"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="94"] > .bar:after {
  -webkit-transform: rotate(338.4deg);
          transform: rotate(338.4deg);
}
.circle[data-fill="95"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="95"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="95"] > .bar:after {
  -webkit-transform: rotate(342deg);
          transform: rotate(342deg);
}
.circle[data-fill="96"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="96"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="96"] > .bar:after {
  -webkit-transform: rotate(345.6deg);
          transform: rotate(345.6deg);
}
.circle[data-fill="97"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="97"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="97"] > .bar:after {
  -webkit-transform: rotate(349.2deg);
          transform: rotate(349.2deg);
}
.circle[data-fill="98"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="98"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="98"] > .bar:after {
  -webkit-transform: rotate(352.8deg);
          transform: rotate(352.8deg);
}
.circle[data-fill="99"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="99"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="99"] > .bar:after {
  -webkit-transform: rotate(356.4deg);
          transform: rotate(356.4deg);
}
.circle[data-fill="100"] > .bar {
  clip: rect(auto, auto, auto);
}
.circle[data-fill="100"] > .bar:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.circle[data-fill="100"] > .bar:after {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.contentInfo-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid gray;
}
.contentInfo-center h5 {
  width: 71%;
  text-align: center;
  padding: 17px;
}
.icons-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  padding: 0;
  grid-column-gap: 10px;
  -webkit-transform: scale(0.88);
          transform: scale(0.88);
  margin-top: 1rem;
}
.Icon-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contentInfo-center p {
  width: 78%;
  margin-bottom: 2rem;
}
.headerInfos h1 {
  color: white;
}
.contentInfoModal {
  background: var(--Modalbackground);
  color: var(--colorText);
  padding-top: 1rem;
}
.headerInfos {
  display: flex;
  align-items: center;
  width: 99%;
  position: relative;
  height: 69 px;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  border-radius: 6px 6px 0 0;
}
.contentInfo-footer {
  width: 90%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contentInfo-footer button {
  font-size: 21px;
}
.statsButton {
  width: 100px !important;
  height: 39px;
  border-radius: 9px;
  margin-top: 1rem;
}
.rtt {
  font-size: 0.7rem;
  width: 100% !important;
  display: grid;
  text-align: center;
  margin-top: 1rem;
  font-style: italic;
}

.profile-container {
  width: 367px;
  height: auto;
  /* background: var(--Modalbackground); */
  border-radius: 18px;
  box-shadow: 0 2px 6px #6b6767;
  padding-bottom: 2rem;
  position: relative;
}
.header-profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 145px;
  background: #7b16ff;
  box-shadow: 0 5px 7px #565557;
  /* background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  ); */
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #f39c12 0%,
    #f8664f 100%
  );
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  padding: 7px;
}
.content-profile {
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 63;
  color: var(--colorText);
}
.profile-innerContent {
  position: absolute;
  top: 91px;
  left: 32%;
  right: auto;
  /* box-shadow: 0 2px 6px black;
  border-radius: 50%;
  height: 112px; */
}
.EditProfileLover__imageContainer .container {
  position: absolute;
  top: 0;
}
.react-responsive-modal-modal {
  margin: 0 !important;
  text-align: inherit !important;
  min-width: 352px;
}
#profile-picture {
  width: 135px;
  height: 135px;
  position: relative;
}
.dropzone button span svg {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.bottom-headerProfile p,
.bottom-headerProfile a p {
  color: white;
  font-weight: bold;
}
.bottom-headerProfile p {
  -webkit-transform: translate(-140px, 40px);
          transform: translate(-140px, 40px);
  text-align: center;
}
.bottom-headerProfile a p {
  -webkit-transform: translate(8.8rem, 11px);
          transform: translate(8.8rem, 11px);
}
.profile-middleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  height: 46%;
  color: white;
}
.profile-middleHead,
.profile-nextmiddleHead,
.profile-lastmiddleHead {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
  position: relative;
}
.session-profileHead {
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #f39c12 0%,
    #f8664f 100%
  );

  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  color: white;
  width: auto;
  padding: 4.5px 9px 4.5px 9px;
  position: relative;
}
.profile-middleHead .text-tooltip {
  background-color: white;
  width: 11em;
  height: auto;
  position: absolute;
  top: -27px;
  left: 8px;
  border-radius: 9px;
  box-shadow: 0 0 6px grey;
  padding: 8px;
  z-index: 9;
}

.session-profileHead .text-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.session-profileHead .text-tooltip sub {
  color: black;
  -webkit-transform: inherit;
          transform: inherit;
}
.infoSigns {
  width: 16px;
  position: absolute;
  right: 6px;
  top: 3px;
  transition: all 0.7s ease-in-out both;
}
.infosSignsSelect {
  -webkit-filter: sepia(1);
          filter: sepia(1);
  transition: all 0.7s ease-in-out both;
}
.profile-lastmiddleHead sub {
  padding: 9px;
}
.session-profileHead h3 {
  -webkit-transform: translate(7px, -3px);
          transform: translate(7px, -3px);
  font-size: 1.8rem;
  padding: 2px;
  margin-right: 1.1rem;
}
.session-profileHead sub {
  font-size: 0.99rem;
  -webkit-transform: translate(-9px, 5px);
          transform: translate(-9px, 5px);
}
.profile-middleHead h2,
.profile-nextmiddleHead h2,
.profile-lastmiddleHead h2 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.closeProfileButton,
.buttonCloseInvite {
  color: #ee0979;
  border: none;
  padding: 7px;
  position: absolute;
  top: 0;
  right: 11px;
  font-size: 1.9rem;
  background: transparent;
  font-weight: bold;
  text-shadow: 0px 1px 2px black;
}
.modalProfileBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-lastmiddleHead .session-profileHead sub {
  font-size: 11px;
  -webkit-transform: translate(0px, 0px) !important;
          transform: translate(0px, 0px) !important;
  font-weight: bold;
}
.bottom-headerProfile p {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
  padding: 7px;
  border-radius: 0 14px 0 14px;
  /* border: 1px solid white;
  box-shadow: inset 0 2px 6px white; */
}
.badgesSestionProfile {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-evenly;
}
/* .profile-middleContent .attendanceBadge-container {
  margin-right: 1rem;
  margin-left: 1rem;
}
.profile-middleContent .bestLearn-container {
  margin-right: 1rem;
}
.profile-middleContent .levelsBadges-container {
  margin-right: 0.3rem;
} */
.profile-middleContent .badgesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-middleContent .badgesContainer h2 {
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
}
.deleteIcon {
  position: absolute;
  color: #674f4f;
  -webkit-transform: scale(0.7) translate(-11px, -11px);
          transform: scale(0.7) translate(-11px, -11px);
  z-index: 99999;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.image-bg:before {
  border-radius: 14px;
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: linear-gradient(-47deg, #7c4fe0 0%, #4528dc 100%);
}
.contour {
  width: 232px;
  position: absolute;
  -webkit-transform: translate(-56.2px, -46.5px);
          transform: translate(-56.2px, -46.5px);
  -webkit-filter: drop-shadow(2px 4px 6px black);
          filter: drop-shadow(2px 4px 6px black);
}

.EditProfileLover__imageContainer span {
  width: 100%;
  height: auto;
}
.logoProfile img {
  -webkit-transform: scale(0.76);
          transform: scale(0.76);
  margin-right: 0rem;
}
.logoProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 207px;
  height: 0px;
  margin-bottom: 1.03rem;
}
.logoProfile sub {
  color: white;
}
.deleteIcon svg {
  -webkit-filter: opacity(0.32);
          filter: opacity(0.32);
}

.allSocialsContainer {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.levelsBadges-container .beginnerBadge .check {
  top: 9px !important;
}
.profile-lastmiddleHead .text-tooltip {
  background-color: white;
  width: 11em;
  height: auto;
  position: absolute;
  top: -51px;
  left: 11px;
  border-radius: 9px;
  box-shadow: 0 0 6px grey;
  padding: 8px;
  z-index: 8;
}
.profile-lastmiddleHead .text-tooltip sub {
  color: black;
}
.profile-lastmiddleHead .session-profileHead sub {
  padding: 14px 9px 10px 9px;
}
.profile-nextmiddleHead .session-profileHead .text-tooltip {
  top: -61px !important;
}

.EditProfileLover__imageContainer {
  position: relative;
  width: 100%;
  height: auto;
}

/* PictureCompress component */
.picture-compress__fabicon {
  /* position: relative !important;
  margin-top: -47px !important;
  margin-left: 142px !important; */
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  color: white !important;
  width: 30% !important;
  margin-bottom: 4vh !important;
}

.picture-compress__input {
  display: none !important;
}

/* ModalFilters Component */
.paper {
  position: absolute;
  min-width: 352px;
  min-height: 520px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 2px 2px 6px black;
  padding: 10px 17px 37px;
  outline: none !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

/* Picture filter component */
.pictureFilter__mainImage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#profile-picture {
  /* width: 100%; */
  border-radius: 50%;
  box-shadow: 0 2px 6px gray, inset 5px 4px 6px black;
}

.MuiFab-sizeMedium {
  width: 35px !important;
  height: 35px !important;
  opacity: 0.6;
}
.MuiAvatar-fallback {
  width: 75% !important;
  height: 75% !important;
}
.picture-compress__fabicon .MuiFab-label .MuiSvgIcon-root {
  width: 1em !important;
  height: 1em !important;
}
.header-profile h1 {
  color: white;
  text-transform: uppercase;
  -webkit-transform: translate(0, -14px);
          transform: translate(0, -14px);
}
/* .cropper-crop-box {
  width: 80% !important;
  height: 80% !important;
} */

.allSocialsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 999999;
  background: var(--Modalbackground);
  padding-bottom: 2rem;
  padding-top: 2rem;
  flex-wrap: wrap;
  border-radius: 17px;
  margin-top: 0%;
}
.headerInviteModal {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 20px;
  border-bottom: 1px solid lightgray;
}
.iconsInviteContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.4rem 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.invitecontent {
  margin-top: 1rem;
}
.invitecontent h3 {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.invitehead,
.inviteFooter {
  width: 80%;
}
.inviteFooter {
  text-align: center;
}
.headerInviteModal h1 {
  -webkit-transform: translate(-28px, -1.2rem);
          transform: translate(-28px, -1.2rem);
}

.loaderApp-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  background: rgba(0, 0, 0, 0.68);
  z-index: 83;
}

.container-load {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: auto;
  -webkit-filter: url("#goo");
          filter: url("#goo");
  -webkit-animation: rotate-move 2s ease-in-out infinite;
          animation: rotate-move 2s ease-in-out infinite;
}

.dotload {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.dotload-3 {
  background-color: #f8664f;
  -webkit-animation: dotload-3-move 2s ease infinite, index 6s ease infinite;
          animation: dotload-3-move 2s ease infinite, index 6s ease infinite;
}

.dotload-2 {
  background-color: #7b16ff;
  -webkit-animation: dotload-2-move 2s ease infinite, index 6s -4s ease infinite;
          animation: dotload-2-move 2s ease infinite, index 6s -4s ease infinite;
}

.dotload-1 {
  background-color: #fbe88f;
  -webkit-animation: dotload-1-move 2s ease infinite, index 6s -2s ease infinite;
          animation: dotload-1-move 2s ease infinite, index 6s -2s ease infinite;
}

@-webkit-keyframes dotload-3-move {
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: translateY(-18px) scale(0.45);
            transform: translateY(-18px) scale(0.45);
  }
  60% {
    -webkit-transform: translateY(-90px) scale(0.45);
            transform: translateY(-90px) scale(0.45);
  }
  80% {
    -webkit-transform: translateY(-90px) scale(0.45);
            transform: translateY(-90px) scale(0.45);
  }
  100% {
    -webkit-transform: translateY(0px) scale(1);
            transform: translateY(0px) scale(1);
  }
}

@keyframes dotload-3-move {
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: translateY(-18px) scale(0.45);
            transform: translateY(-18px) scale(0.45);
  }
  60% {
    -webkit-transform: translateY(-90px) scale(0.45);
            transform: translateY(-90px) scale(0.45);
  }
  80% {
    -webkit-transform: translateY(-90px) scale(0.45);
            transform: translateY(-90px) scale(0.45);
  }
  100% {
    -webkit-transform: translateY(0px) scale(1);
            transform: translateY(0px) scale(1);
  }
}

@-webkit-keyframes dotload-2-move {
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: translate(-16px, 12px) scale(0.45);
            transform: translate(-16px, 12px) scale(0.45);
  }
  60% {
    -webkit-transform: translate(-80px, 60px) scale(0.45);
            transform: translate(-80px, 60px) scale(0.45);
  }
  80% {
    -webkit-transform: translate(-80px, 60px) scale(0.45);
            transform: translate(-80px, 60px) scale(0.45);
  }
  100% {
    -webkit-transform: translateY(0px) scale(1);
            transform: translateY(0px) scale(1);
  }
}

@keyframes dotload-2-move {
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: translate(-16px, 12px) scale(0.45);
            transform: translate(-16px, 12px) scale(0.45);
  }
  60% {
    -webkit-transform: translate(-80px, 60px) scale(0.45);
            transform: translate(-80px, 60px) scale(0.45);
  }
  80% {
    -webkit-transform: translate(-80px, 60px) scale(0.45);
            transform: translate(-80px, 60px) scale(0.45);
  }
  100% {
    -webkit-transform: translateY(0px) scale(1);
            transform: translateY(0px) scale(1);
  }
}

@-webkit-keyframes dotload-1-move {
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: translate(16px, 12px) scale(0.45);
            transform: translate(16px, 12px) scale(0.45);
  }
  60% {
    -webkit-transform: translate(80px, 60px) scale(0.45);
            transform: translate(80px, 60px) scale(0.45);
  }
  80% {
    -webkit-transform: translate(80px, 60px) scale(0.45);
            transform: translate(80px, 60px) scale(0.45);
  }
  100% {
    -webkit-transform: translateY(0px) scale(1);
            transform: translateY(0px) scale(1);
  }
}

@keyframes dotload-1-move {
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: translate(16px, 12px) scale(0.45);
            transform: translate(16px, 12px) scale(0.45);
  }
  60% {
    -webkit-transform: translate(80px, 60px) scale(0.45);
            transform: translate(80px, 60px) scale(0.45);
  }
  80% {
    -webkit-transform: translate(80px, 60px) scale(0.45);
            transform: translate(80px, 60px) scale(0.45);
  }
  100% {
    -webkit-transform: translateY(0px) scale(1);
            transform: translateY(0px) scale(1);
  }
}

@-webkit-keyframes rotate-move {
  55% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  80% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate-move {
  55% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  80% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes index {
  0%,
  100% {
    z-index: 3;
  }
  33.3% {
    z-index: 2;
  }
  66.6% {
    z-index: 1;
  }
}

@keyframes index {
  0%,
  100% {
    z-index: 3;
  }
  33.3% {
    z-index: 2;
  }
  66.6% {
    z-index: 1;
  }
}

/* html:hover .presentation,
body:hover .presentation {
  display: none;
} */

.infoButtonContainer {
  position: fixed;
  z-index: 100;
  right: 0px;
  width: 50px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 16em;
}

.presentation {
  position: fixed;
  right: 58px;
  bottom: 23px;
  text-shadow: 1px 1px 1px #000;
}

/* menu */
/* nav.float-action-button {
  position: fixed;
  bottom: 4px;
  right: 0;
  z-index: 98;
  margin: 1em;
} */
.amanoLogo {
  width: 40px;
  margin-top: -4px;
  background: #722cb9;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.buttons {
  /* box-shadow: 0 5px 11px -2px rgb(0 0 0 / 18%), 0 4px 12px -7px rgb(0 0 0 / 15%); */
  border-radius: 50%;
  width: 37px;
  height: 37px;
  color: #fff;
  font-size: 2.6em;
  padding: 1px 0 0 0;
  text-align: center;
  display: block;
  margin: 20px auto 0;
  position: relative;
  -webkit-filter: opacity(0.6);
          filter: opacity(0.6);
  transition: all 0.6s ease-out;
}
a.buttons:hover {
  -webkit-filter: opacity(1);
          filter: opacity(1);
}
a.buttons:hover > svg,
a.buttons:hover > img {
  box-shadow: 0 0 3px gray;
  border-radius: 50%;
}
.buttons > svg {
  -webkit-transform: translate(0px, -1px);
          transform: translate(0px, -1px);
}
.main-button > svg {
  -webkit-transform: translate(0px, -5px);
          transform: translate(0px, -5px);
}

a.buttons:active,
a.buttons:focus,
a.buttons:hover {
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); */
  text-decoration: none;
}

a.buttons:not(:last-child) {
  width: 40px;
  height: 40px;
  margin: 20px auto 0;
  opacity: 0;
  font-size: 1.6em;
  padding-top: 4px;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}

a.buttons.main-button > i:first-child {
  position: relative;
  margin-top: 10px;
}

nav.float-action-button:hover a.buttons:not(:last-child) {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  margin: 20px auto 0;
  transition: all 0.6s ease-in-out;
}

a.buttons:nth-last-child(1) {
  transition-delay: 25ms;
  /* background-color: #d32f2f; */
  /* Button color */
}

a.buttons:nth-last-child(1) i.fa {
  -webkit-transform: rotate3d(0, 0, 1, 0);
          transform: rotate3d(0, 0, 1, 0);
  transition: content 0.4s, opacity 0.4s, -webkit-transform 0.4s;
  transition: content 0.4s, transform 0.4s, opacity 0.4s;
  transition: content 0.4s, transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
}

a.buttons:nth-last-child(1):hover i.fa {
  -webkit-transform: rotate3d(0, 0, 1, -180deg);
          transform: rotate3d(0, 0, 1, -180deg);
}

a.buttons:nth-last-child(1) i.fa:nth-last-child(1) {
  position: absolute;
  margin: 10px 0 0 -32px;
}

a.buttons:nth-last-child(1) i.fa:nth-last-child(2) {
  opacity: 0;
}

a.buttons:nth-last-child(1):hover i.fa:nth-last-child(1) {
  opacity: 0;
}

a.buttons:nth-last-child(1):hover i.fa:nth-last-child(2) {
  opacity: 1;
}

a.buttons:not(:last-child):nth-last-child(2) {
  transition-delay: 20ms;
  /* background-color: #3b5998; */
  /* Facebook color */
}

a.buttons:not(:last-child):nth-last-child(3) {
  transition-delay: 40ms;
  /* background-color: #1da1f2; */
  /* Twitter color */
}

a.buttons:not(:last-child):nth-last-child(4) {
  transition-delay: 60ms;
  /* background-color: #dd4b39; */
  /* Google plus color */
}

.tooltip.left {
  margin-left: -10px;
}
.graduateIcon {
  width: 40px;
  margin-top: -4px;
  background-image: radial-gradient(
    ellipse farthest-corner at top right,
    #f39c12 0%,
    #f8664f 100%
  );
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate(-6px, 0px);
          transform: translate(-6px, 0px);
}

.logoChoice-content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 202;
}
.overlay-logoChoice {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 200;
}

.configIcon {
  position: absolute;
  bottom: 21%;
  left: 2px;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-filter: drop-shadow(0 0 1.4px black) opacity(0.8);
          filter: drop-shadow(0 0 1.4px black) opacity(0.8);
}
.configIcon:hover,
.configIcon:active {
  transition: all 0.8s ease-in-out;
  -webkit-filter: opacity(1) drop-shadow(0px 0px 2px black);
          filter: opacity(1) drop-shadow(0px 0px 2px black);
}

@-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.logoChoice-container {
  background: var(--Modalbackground);
  width: 93%;
  /* height: 70vh; */
  position: absolute;
  z-index: 999;
  top: 10%;
  box-shadow: 0 2px 6px black;
  border-radius: 6px;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1000px) {
  .logoChoice-container {
    max-width: 24em !important;
  }
  .logoChoice-pictureContainer img {
    width: 33vmin;
    height: 33vmin;
  }
}
.logoChoice-text {
  color: white;
  font-weight: bold;
}

.logoPreview-picture {
  width: 58vmin;
  height: 58vmin;
}
.header-logoChoice {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* margin-bottom: 0.7em; */
  width: 100%;
  height: 40px;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  border-radius: 6px 6px 0 0;
}
.logoChoice-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  color: red;
  font-size: 22px;
  font-weight: bold;
}

.textLogoChoice {
  width: 72%;
  text-align: center;
}
.logoPreview {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.logoChoice-containerList {
  position: absolute;
  width: 95%;
  /* height: 107px; */
  bottom: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}
.logoChoice-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  position: relative;
  border-bottom: 2px solid purple;
  border-top: 2px solid purple;
  width: 81%;
}
@media screen and (max-width: 900px) {
  .logoChoice-list::-webkit-scrollbar {
    display: none !important;
  }
  .logoChoice-list {
    width: 79% !important;
  }
}
@media screen and (min-width: 900px) {
  .logoChoice-list::-webkit-scrollbar {
    height: 6px;
    background: whitesmoke;
  }
  .logoChoice-list::-webkit-scrollbar-thumb {
    background: #5c09e2;
  }
}
.logoChoice-ul {
  list-style: none;
  position: relative;
}
.logoChoice-arrowLeft {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  border: 2px solid purple;
  border-right: none;
  z-index: 999;
}
.logoChoice-arrowLeft svg,
.logoChoice-arrowRight svg {
  width: 32px;
  fill: var(--colorText);
}
.logoChoice-arrowRight {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 96%;
  display: flex;
  align-items: center;
  padding: 1px;
  border: 2px solid purple;
  border-left: none;
}
.logoChoice-arrowLeft,
.logoChoice-arrowRight {
  background: #92919466;
}
/* .logoChoice-ul:last-child .logoChoice-li {
  margin-right: 3rem;
} */
.logoChoice-li--selected {
  background: #7a7be0;
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
}
.logoChoice-img {
  padding-right: 26px;
}
.logoChoice-button {
  background: none;
  border: none;
  width: 100%;
}
.logoChoice-button:first-child {
  border-right: 1px dotted black;
}
.logoChoice-middle {
  color: var(--colorText);
  height: 50vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  width: 100%;
}
.logoChoice-lowerMiddle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7em;
}
.defaultLogo {
  position: absolute;
  width: 100%;
  font-size: 15px;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colorText);
}
.logoChoice-li {
  width: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoChoice-close,
.curiousBadge-container,
.logoChoice-li,
.bestLearn-container,
.configIcon,
.eraseIcon {
  cursor: pointer;
}
.footerLogoChoice {
  /* position: absolute; */
  /* bottom: 4px; */
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
}
.buttonContainer-logoChoices {
  width: 79%;
  display: flex;
  background: transparent;
  border-radius: 5px;
  box-shadow: 0 2px 6px var(--shadowColor);
}
.placeholderLogo {
  width: 99px;
  -webkit-filter: opacity(0.7);
          filter: opacity(0.7);
}

.App-child {
  text-align: center;
  background-color: var(--Background);
  height: 100vh;
  position: absolute;
  z-index: 97;
  padding-bottom: 7em;
  color: darkgrey;
  width: 100%;
}
.categories-container {
  background-color: var(--Background);
}

.logo {
  height: 56vmin;
  width: 56vmin;
  pointer-events: none;
  border-radius: 17%;
  padding: 10px;
  border: 1px solid whitesmoke;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }
}
@-webkit-keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-top: 25px;
}
.video-responsive:last-child {
  margin-bottom: 25px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.childSection-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7%;
  margin-bottom: 0;
}
.buttonChild-container button {
  border: none;
  background: none;
}
.buttonChild-container {
  width: 91px;
  position: fixed;
  right: 15px;
  top: 50px;
  z-index: 99;
}

.alertContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
  margin-top: 5%;
}
.alertText {
  font-size: 12px;
  width: 80%;
  color: var(--warningTextColor);
  font-style: italic;
  margin-top: 10px;
}
.noneCat {
  height: 33vh;
}
.slide-in-bottom-child {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0em;
  padding-top: 7em;
  background: var(--Background);
}
.buttonChildZone {
  border: 4px solid rgba(115, 103, 240, 0.34);
  border-radius: 0.25em;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  background-color: #7367f0;
  color: #fff;
  font-size: 1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  padding: 8px;
  margin-bottom: 17px;
}
.buttonChildZone-active {
  background-color: #3c31ad !important;
  color: darkgray;
}

.buttonContainer {
  /* margin-top: 50%; */
  width: 99%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 117px;
}
.categories-container {
  width: 100%;
}
.absol {
  position: absolute;
  top: 0;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.catselected {
  padding-top: 20vh;
}
.hidden {
  display: none;
}

@media screen and (min-width: 1290px) {
  .logo {
    height: 28vmin;
    width: 28vmin;
  }
  .childSection-content {
    min-height: 670px;
  }
  .alertContent {
    margin-top: 3em;
  }
}
@media screen and (max-width: 375px) {
  .noneCat {
    margin-top: 17%;
  }
  .App-child {
    padding-bottom: 11em;
  }
  .categories-container {
    margin-top: 15%;
  }
  .loader-reconstruct {
    margin-top: 17%;
  }
}
.child-button-label {
  width: 98px;
  height: 20px;
  position: absolute;
  top: 2rem;
  right: -54px;
  padding: 4px;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #f39c12 0%,
    #f8664f 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: all 0.8s ease-in-out;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 1px 4px grey;
}

.child-button-label--active {
  width: 98px;
  height: 15px;
  position: absolute;
  top: 0rem;
  right: -13px;
  padding: 4px;
  background: #f8664f;
  background-image: radial-gradient(
    ellipse farthest-corner at top,
    #f39c12 1%,
    #f8664f 99%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  border-radius: 3px;
  box-shadow: 0 2px 6px grey;
  transition: all 0.8s ease-in-out;
}
.child-label-text {
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}
.child-button-img {
  width: 110px;
  height: auto;
  display: none;
}
.child-button-img--active {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  width: 110px;
  height: auto;
  display: inherit;
  margin-top: 2em;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
#triangle-child-level {
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 42px solid #f77142;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  position: absolute;
  bottom: 62px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  left: 36px;
  z-index: 99;
  -webkit-animation: fade-in 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) 500ms both;
          animation: fade-in 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) 500ms both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.child-button-label-inZone {
  background: #7367f0 !important;
}

.loader-reconstruct {
  width: 19em;
  height: 40px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

.loader-reconstruct > p {
  margin-left: -5vw;
}

.snippet {
  position: absolute;
  top: 30px;
  left: 57%;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px #9880ff;
  -webkit-animation: dotPulse 1.5s infinite linear;
          animation: dotPulse 1.5s infinite linear;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #9880ff;
  -webkit-animation: dotPulseBefore 1.5s infinite linear;
          animation: dotPulseBefore 1.5s infinite linear;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #9880ff;
  -webkit-animation: dotPulseAfter 1.5s infinite linear;
          animation: dotPulseAfter 1.5s infinite linear;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}
@-webkit-keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}
@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}
@-webkit-keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}
@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}
#outline {
  stroke-dasharray: 2.42777px, 242.77666px;
  stroke-dashoffset: 0;
  -webkit-animation: anim 1.6s linear infinite;
  animation: anim 1.6s linear infinite;
}

@-webkit-keyframes anim {
  12.5% {
    stroke-dasharray: 33.98873px, 242.77666px;
    stroke-dashoffset: -26.70543px;
  }
  43.75% {
    stroke-dasharray: 84.97183px, 242.77666px;
    stroke-dashoffset: -84.97183px;
  }
  100% {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: -240.34889px;
  }
}
@keyframes anim {
  12.5% {
    stroke-dasharray: 33.98873px, 242.77666px;
    stroke-dashoffset: -26.70543px;
  }
  43.75% {
    stroke-dasharray: 84.97183px, 242.77666px;
    stroke-dashoffset: -84.97183px;
  }
  100% {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: -240.34889px;
  }
}
.check-out {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 2rem;
}

/* animation sets */

/* move from / to  */

.pt-page-moveToLeft {
  -webkit-animation: moveToLeft 0.6s ease both;
  animation: moveToLeft 0.6s ease both;
}

.pt-page-moveFromLeft {
  -webkit-animation: moveFromLeft 0.6s ease both;
  animation: moveFromLeft 0.6s ease both;
}

.pt-page-moveToRight {
  -webkit-animation: moveToRight 0.6s ease both;
  animation: moveToRight 0.6s ease both;
}

.pt-page-moveFromRight {
  -webkit-animation: moveFromRight 0.6s ease both;
  animation: moveFromRight 0.6s ease both;
}

.pt-page-moveToTop {
  -webkit-animation: moveToTop 0.6s ease both;
  animation: moveToTop 0.6s ease both;
}

.pt-page-moveFromTop {
  -webkit-animation: moveFromTop 0.6s ease both;
  animation: moveFromTop 0.6s ease both;
}

.pt-page-moveToBottom {
  -webkit-animation: moveToBottom 0.6s ease both;
  animation: moveToBottom 0.6s ease both;
}

.pt-page-moveFromBottom {
  -webkit-animation: moveFromBottom 0.6s ease both;
  animation: moveFromBottom 0.6s ease both;
}

/* fade */

.pt-page-fade {
  -webkit-animation: fade 0.7s ease both;
  animation: fade 0.7s ease both;
}

/* move from / to and fade */

.pt-page-moveToLeftFade {
  -webkit-animation: moveToLeftFade 0.7s ease both;
  animation: moveToLeftFade 0.7s ease both;
}

.pt-page-moveFromLeftFade {
  -webkit-animation: moveFromLeftFade 0.7s ease both;
  animation: moveFromLeftFade 0.7s ease both;
}

.pt-page-moveToRightFade {
  -webkit-animation: moveToRightFade 0.7s ease both;
  animation: moveToRightFade 0.7s ease both;
}

.pt-page-moveFromRightFade {
  -webkit-animation: moveFromRightFade 0.7s ease both;
  animation: moveFromRightFade 0.7s ease both;
}

.pt-page-moveToTopFade {
  -webkit-animation: moveToTopFade 0.7s ease both;
  animation: moveToTopFade 0.7s ease both;
}

.pt-page-moveFromTopFade {
  -webkit-animation: moveFromTopFade 0.7s ease both;
  animation: moveFromTopFade 0.7s ease both;
}

.pt-page-moveToBottomFade {
  -webkit-animation: moveToBottomFade 0.7s ease both;
  animation: moveToBottomFade 0.7s ease both;
}

.pt-page-moveFromBottomFade {
  -webkit-animation: moveFromBottomFade 0.7s ease both;
  animation: moveFromBottomFade 0.7s ease both;
}

/* move to with different easing */

.pt-page-moveToLeftEasing {
  -webkit-animation: moveToLeft 0.7s ease-in-out both;
  animation: moveToLeft 0.7s ease-in-out both;
}
.pt-page-moveToRightEasing {
  -webkit-animation: moveToRight 0.7s ease-in-out both;
  animation: moveToRight 0.7s ease-in-out both;
}
.pt-page-moveToTopEasing {
  -webkit-animation: moveToTop 0.7s ease-in-out both;
  animation: moveToTop 0.7s ease-in-out both;
}
.pt-page-moveToBottomEasing {
  -webkit-animation: moveToBottom 0.7s ease-in-out both;
  animation: moveToBottom 0.7s ease-in-out both;
}

/********************************* keyframes **************************************/

/* move from / to  */

@-webkit-keyframes moveToLeft {
  from {
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes moveToLeft {
  from {
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes moveFromLeft {
  from {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes moveFromLeft {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes moveToRight {
  from {
  }
  to {
    -webkit-transform: translateX(100%);
  }
}
@keyframes moveToRight {
  from {
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@-webkit-keyframes moveFromRight {
  from {
    -webkit-transform: translateX(100%);
  }
}
@keyframes moveFromRight {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@-webkit-keyframes moveToTop {
  from {
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}
@keyframes moveToTop {
  from {
  }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-webkit-keyframes moveFromTop {
  from {
    -webkit-transform: translateY(-100%);
  }
}
@keyframes moveFromTop {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-webkit-keyframes moveToBottom {
  from {
  }
  to {
    -webkit-transform: translateY(100%);
  }
}
@keyframes moveToBottom {
  from {
  }
  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@-webkit-keyframes moveFromBottom {
  from {
    -webkit-transform: translateY(100%);
  }
}
@keyframes moveFromBottom {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

/* fade */

@-webkit-keyframes fade {
  from {
  }
  to {
    opacity: 0.3;
  }
}
@keyframes fade {
  from {
  }
  to {
    opacity: 0.3;
  }
}

/* move from / to and fade */

@-webkit-keyframes moveToLeftFade {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(-100%);
  }
}
@keyframes moveToLeftFade {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes moveFromLeftFade {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(-100%);
  }
}
@keyframes moveFromLeftFade {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes moveToRightFade {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(100%);
  }
}
@keyframes moveToRightFade {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@-webkit-keyframes moveFromRightFade {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(100%);
  }
}
@keyframes moveFromRightFade {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@-webkit-keyframes moveToTopFade {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(-100%);
  }
}
@keyframes moveToTopFade {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-webkit-keyframes moveFromTopFade {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(-100%);
  }
}
@keyframes moveFromTopFade {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-webkit-keyframes moveToBottomFade {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(100%);
  }
}
@keyframes moveToBottomFade {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@-webkit-keyframes moveFromBottomFade {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(100%);
  }
}
@keyframes moveFromBottomFade {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

/* scale and fade */

.pt-page-scaleDown {
  -webkit-animation: scaleDown 0.7s ease both;
  animation: scaleDown 0.7s ease both;
}

.pt-page-scaleUp {
  -webkit-animation: scaleUp 0.7s ease both;
  animation: scaleUp 0.7s ease both;
}

.pt-page-scaleUpDown {
  -webkit-animation: scaleUpDown 0.5s ease both;
  animation: scaleUpDown 0.5s ease both;
}

.pt-page-scaleDownUp {
  -webkit-animation: scaleDownUp 0.5s ease both;
  animation: scaleDownUp 0.5s ease both;
}

.pt-page-scaleDownCenter {
  -webkit-animation: scaleDownCenter 0.4s ease-in both;
  animation: scaleDownCenter 0.4s ease-in both;
}

.pt-page-scaleUpCenter {
  -webkit-animation: scaleUpCenter 0.4s ease-out both;
  animation: scaleUpCenter 0.4s ease-out both;
}

/********************************* keyframes **************************************/

/* scale and fade */

@-webkit-keyframes scaleDown {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}
@keyframes scaleDown {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@-webkit-keyframes scaleUp {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}
@keyframes scaleUp {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@-webkit-keyframes scaleUpDown {
  from {
    opacity: 0;
    -webkit-transform: scale(1.2);
  }
}
@keyframes scaleUpDown {
  from {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@-webkit-keyframes scaleDownUp {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1.2);
  }
}
@keyframes scaleDownUp {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@-webkit-keyframes scaleDownCenter {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.7);
  }
}
@keyframes scaleDownCenter {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@-webkit-keyframes scaleUpCenter {
  from {
    opacity: 0;
    -webkit-transform: scale(0.7);
  }
}
@keyframes scaleUpCenter {
  from {
    opacity: 0;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

/* rotate sides first and scale */

.pt-page-rotateRightSideFirst {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateRightSideFirst 0.8s both ease-in;
  animation: rotateRightSideFirst 0.8s both ease-in;
}
.pt-page-rotateLeftSideFirst {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateLeftSideFirst 0.8s both ease-in;
  animation: rotateLeftSideFirst 0.8s both ease-in;
}
.pt-page-rotateTopSideFirst {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateTopSideFirst 0.8s both ease-in;
  animation: rotateTopSideFirst 0.8s both ease-in;
}
.pt-page-rotateBottomSideFirst {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateBottomSideFirst 0.8s both ease-in;
  animation: rotateBottomSideFirst 0.8s both ease-in;
}

/* flip */

.pt-page-flipOutRight {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipOutRight 0.5s both ease-in;
  animation: flipOutRight 0.5s both ease-in;
}
.pt-page-flipInLeft {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipInLeft 0.5s both ease-out;
  animation: flipInLeft 0.5s both ease-out;
}
.pt-page-flipOutLeft {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipOutLeft 0.5s both ease-in;
  animation: flipOutLeft 0.5s both ease-in;
}
.pt-page-flipInRight {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipInRight 0.5s both ease-out;
  animation: flipInRight 0.5s both ease-out;
}
.pt-page-flipOutTop {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipOutTop 0.5s both ease-in;
  animation: flipOutTop 0.5s both ease-in;
}
.pt-page-flipInBottom {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipInBottom 0.5s both ease-out;
  animation: flipInBottom 0.5s both ease-out;
}
.pt-page-flipOutBottom {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipOutBottom 0.5s both ease-in;
  animation: flipOutBottom 0.5s both ease-in;
}
.pt-page-flipInTop {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipInTop 0.5s both ease-out;
  animation: flipInTop 0.5s both ease-out;
}

/* rotate fall */

.pt-page-rotateFall {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-animation: rotateFall 1s both ease-in;
  animation: rotateFall 1s both ease-in;
}

/* rotate newspaper */
.pt-page-rotateOutNewspaper {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: rotateOutNewspaper 0.5s both ease-in;
  animation: rotateOutNewspaper 0.5s both ease-in;
}
.pt-page-rotateInNewspaper {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: rotateInNewspaper 0.5s both ease-out;
  animation: rotateInNewspaper 0.5s both ease-out;
}

/* push */
.pt-page-rotatePushLeft {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotatePushLeft 0.8s both ease;
  animation: rotatePushLeft 0.8s both ease;
}
.pt-page-rotatePushRight {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotatePushRight 0.8s both ease;
  animation: rotatePushRight 0.8s both ease;
}
.pt-page-rotatePushTop {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotatePushTop 0.8s both ease;
  animation: rotatePushTop 0.8s both ease;
}
.pt-page-rotatePushBottom {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotatePushBottom 0.8s both ease;
  animation: rotatePushBottom 0.8s both ease;
}

/* pull */
.pt-page-rotatePullRight {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotatePullRight 0.5s both ease;
  animation: rotatePullRight 0.5s both ease;
}
.pt-page-rotatePullLeft {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotatePullLeft 0.5s both ease;
  animation: rotatePullLeft 0.5s both ease;
}
.pt-page-rotatePullTop {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotatePullTop 0.5s both ease;
  animation: rotatePullTop 0.5s both ease;
}
.pt-page-rotatePullBottom {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotatePullBottom 0.5s both ease;
  animation: rotatePullBottom 0.5s both ease;
}

/* fold */
.pt-page-rotateFoldRight {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateFoldRight 0.7s both ease;
  animation: rotateFoldRight 0.7s both ease;
}
.pt-page-rotateFoldLeft {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateFoldLeft 0.7s both ease;
  animation: rotateFoldLeft 0.7s both ease;
}
.pt-page-rotateFoldTop {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateFoldTop 0.7s both ease;
  animation: rotateFoldTop 0.7s both ease;
}
.pt-page-rotateFoldBottom {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateFoldBottom 0.7s both ease;
  animation: rotateFoldBottom 0.7s both ease;
}

/* unfold */
.pt-page-rotateUnfoldLeft {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateUnfoldLeft 0.7s both ease;
  animation: rotateUnfoldLeft 0.7s both ease;
}
.pt-page-rotateUnfoldRight {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateUnfoldRight 0.7s both ease;
  animation: rotateUnfoldRight 0.7s both ease;
}
.pt-page-rotateUnfoldTop {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateUnfoldTop 0.7s both ease;
  animation: rotateUnfoldTop 0.7s both ease;
}
.pt-page-rotateUnfoldBottom {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateUnfoldBottom 0.7s both ease;
  animation: rotateUnfoldBottom 0.7s both ease;
}

/* room walls */
.pt-page-rotateRoomLeftOut {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateRoomLeftOut 0.8s both ease;
  animation: rotateRoomLeftOut 0.8s both ease;
}
.pt-page-rotateRoomLeftIn {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateRoomLeftIn 0.8s both ease;
  animation: rotateRoomLeftIn 0.8s both ease;
}
.pt-page-rotateRoomRightOut {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateRoomRightOut 0.8s both ease;
  animation: rotateRoomRightOut 0.8s both ease;
}
.pt-page-rotateRoomRightIn {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateRoomRightIn 0.8s both ease;
  animation: rotateRoomRightIn 0.8s both ease;
}
.pt-page-rotateRoomTopOut {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateRoomTopOut 0.8s both ease;
  animation: rotateRoomTopOut 0.8s both ease;
}
.pt-page-rotateRoomTopIn {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateRoomTopIn 0.8s both ease;
  animation: rotateRoomTopIn 0.8s both ease;
}
.pt-page-rotateRoomBottomOut {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateRoomBottomOut 0.8s both ease;
  animation: rotateRoomBottomOut 0.8s both ease;
}
.pt-page-rotateRoomBottomIn {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateRoomBottomIn 0.8s both ease;
  animation: rotateRoomBottomIn 0.8s both ease;
}

/* cube */
.pt-page-rotateCubeLeftOut {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateCubeLeftOut 0.6s both ease-in;
  animation: rotateCubeLeftOut 0.6s both ease-in;
}
.pt-page-rotateCubeLeftIn {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateCubeLeftIn 0.6s both ease-in;
  animation: rotateCubeLeftIn 0.6s both ease-in;
}
.pt-page-rotateCubeRightOut {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateCubeRightOut 0.6s both ease-in;
  animation: rotateCubeRightOut 0.6s both ease-in;
}
.pt-page-rotateCubeRightIn {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateCubeRightIn 0.6s both ease-in;
  animation: rotateCubeRightIn 0.6s both ease-in;
}
.pt-page-rotateCubeTopOut {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateCubeTopOut 0.6s both ease-in;
  animation: rotateCubeTopOut 0.6s both ease-in;
}
.pt-page-rotateCubeTopIn {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateCubeTopIn 0.6s both ease-in;
  animation: rotateCubeTopIn 0.6s both ease-in;
}
.pt-page-rotateCubeBottomOut {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateCubeBottomOut 0.6s both ease-in;
  animation: rotateCubeBottomOut 0.6s both ease-in;
}
.pt-page-rotateCubeBottomIn {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateCubeBottomIn 0.6s both ease-in;
  animation: rotateCubeBottomIn 0.6s both ease-in;
}

/* carousel */
.pt-page-rotateCarouselLeftOut {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateCarouselLeftOut 0.8s both ease;
  animation: rotateCarouselLeftOut 0.8s both ease;
}
.pt-page-rotateCarouselLeftIn {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateCarouselLeftIn 0.8s both ease;
  animation: rotateCarouselLeftIn 0.8s both ease;
}
.pt-page-rotateCarouselRightOut {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: rotateCarouselRightOut 0.8s both ease;
  animation: rotateCarouselRightOut 0.8s both ease;
}
.pt-page-rotateCarouselRightIn {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-animation: rotateCarouselRightIn 0.8s both ease;
  animation: rotateCarouselRightIn 0.8s both ease;
}
.pt-page-rotateCarouselTopOut {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateCarouselTopOut 0.8s both ease;
  animation: rotateCarouselTopOut 0.8s both ease;
}
.pt-page-rotateCarouselTopIn {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateCarouselTopIn 0.8s both ease;
  animation: rotateCarouselTopIn 0.8s both ease;
}
.pt-page-rotateCarouselBottomOut {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: rotateCarouselBottomOut 0.8s both ease;
  animation: rotateCarouselBottomOut 0.8s both ease;
}
.pt-page-rotateCarouselBottomIn {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: rotateCarouselBottomIn 0.8s both ease;
  animation: rotateCarouselBottomIn 0.8s both ease;
}

/* sides */
.pt-page-rotateSidesOut {
  -webkit-transform-origin: -50% 50%;
  transform-origin: -50% 50%;
  -webkit-animation: rotateSidesOut 0.5s both ease-in;
  animation: rotateSidesOut 0.5s both ease-in;
}
.pt-page-rotateSidesIn {
  -webkit-transform-origin: 150% 50%;
  transform-origin: 150% 50%;
  -webkit-animation: rotateSidesIn 0.5s both ease-out;
  animation: rotateSidesIn 0.5s both ease-out;
}

/* slide */
.pt-page-rotateSlideOut {
  -webkit-animation: rotateSlideOut 1s both ease;
  animation: rotateSlideOut 1s both ease;
}
.pt-page-rotateSlideIn {
  -webkit-animation: rotateSlideIn 1s both ease;
  animation: rotateSlideIn 1s both ease;
}

/********************************* keyframes **************************************/

/* rotate sides first and scale */

@-webkit-keyframes rotateRightSideFirst {
  0% {
  }
  40% {
    -webkit-transform: rotateY(15deg);
    opacity: 0.8;
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}
@keyframes rotateRightSideFirst {
  0% {
  }
  40% {
    -webkit-transform: rotateY(15deg);
    transform: rotateY(15deg);
    opacity: 0.8;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(0.8) translateZ(-200px);
    transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}

@-webkit-keyframes rotateLeftSideFirst {
  0% {
  }
  40% {
    -webkit-transform: rotateY(-15deg);
    opacity: 0.8;
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}
@keyframes rotateLeftSideFirst {
  0% {
  }
  40% {
    -webkit-transform: rotateY(-15deg);
    transform: rotateY(-15deg);
    opacity: 0.8;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(0.8) translateZ(-200px);
    transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}

@-webkit-keyframes rotateTopSideFirst {
  0% {
  }
  40% {
    -webkit-transform: rotateX(15deg);
    opacity: 0.8;
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}
@keyframes rotateTopSideFirst {
  0% {
  }
  40% {
    -webkit-transform: rotateX(15deg);
    transform: rotateX(15deg);
    opacity: 0.8;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(0.8) translateZ(-200px);
    transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}

@-webkit-keyframes rotateBottomSideFirst {
  0% {
  }
  40% {
    -webkit-transform: rotateX(-15deg);
    opacity: 0.8;
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}
@keyframes rotateBottomSideFirst {
  0% {
  }
  40% {
    -webkit-transform: rotateX(-15deg);
    transform: rotateX(-15deg);
    opacity: 0.8;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(0.8) translateZ(-200px);
    transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}

/* flip */

@-webkit-keyframes flipOutRight {
  from {
  }
  to {
    -webkit-transform: translateZ(-1000px) rotateY(90deg);
    opacity: 0.2;
  }
}
@keyframes flipOutRight {
  from {
  }
  to {
    -webkit-transform: translateZ(-1000px) rotateY(90deg);
    transform: translateZ(-1000px) rotateY(90deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes flipInLeft {
  from {
    -webkit-transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2;
  }
}
@keyframes flipInLeft {
  from {
    -webkit-transform: translateZ(-1000px) rotateY(-90deg);
    transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes flipOutLeft {
  from {
  }
  to {
    -webkit-transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2;
  }
}
@keyframes flipOutLeft {
  from {
  }
  to {
    -webkit-transform: translateZ(-1000px) rotateY(-90deg);
    transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes flipInRight {
  from {
    -webkit-transform: translateZ(-1000px) rotateY(90deg);
    opacity: 0.2;
  }
}
@keyframes flipInRight {
  from {
    -webkit-transform: translateZ(-1000px) rotateY(90deg);
    transform: translateZ(-1000px) rotateY(90deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes flipOutTop {
  from {
  }
  to {
    -webkit-transform: translateZ(-1000px) rotateX(90deg);
    opacity: 0.2;
  }
}
@keyframes flipOutTop {
  from {
  }
  to {
    -webkit-transform: translateZ(-1000px) rotateX(90deg);
    transform: translateZ(-1000px) rotateX(90deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes flipInBottom {
  from {
    -webkit-transform: translateZ(-1000px) rotateX(-90deg);
    opacity: 0.2;
  }
}
@keyframes flipInBottom {
  from {
    -webkit-transform: translateZ(-1000px) rotateX(-90deg);
    transform: translateZ(-1000px) rotateX(-90deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes flipOutBottom {
  from {
  }
  to {
    -webkit-transform: translateZ(-1000px) rotateX(-90deg);
    opacity: 0.2;
  }
}
@keyframes flipOutBottom {
  from {
  }
  to {
    -webkit-transform: translateZ(-1000px) rotateX(-90deg);
    transform: translateZ(-1000px) rotateX(-90deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes flipInTop {
  from {
    -webkit-transform: translateZ(-1000px) rotateX(90deg);
    opacity: 0.2;
  }
}
@keyframes flipInTop {
  from {
    -webkit-transform: translateZ(-1000px) rotateX(90deg);
    transform: translateZ(-1000px) rotateX(90deg);
    opacity: 0.2;
  }
}

/* fall */

@-webkit-keyframes rotateFall {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  20% {
    -webkit-transform: rotateZ(10deg);
    -webkit-animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: rotateZ(17deg);
  }
  60% {
    -webkit-transform: rotateZ(16deg);
  }
  100% {
    -webkit-transform: translateY(100%) rotateZ(17deg);
  }
}
@keyframes rotateFall {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  20% {
    -webkit-transform: rotateZ(10deg);
    transform: rotateZ(10deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: rotateZ(17deg);
    transform: rotateZ(17deg);
  }
  60% {
    -webkit-transform: rotateZ(16deg);
    transform: rotateZ(16deg);
  }
  100% {
    -webkit-transform: translateY(100%) rotateZ(17deg);
    transform: translateY(100%) rotateZ(17deg);
  }
}

/* newspaper */

@-webkit-keyframes rotateOutNewspaper {
  from {
  }
  to {
    -webkit-transform: translateZ(-3000px) rotateZ(360deg);
    opacity: 0;
  }
}
@keyframes rotateOutNewspaper {
  from {
  }
  to {
    -webkit-transform: translateZ(-3000px) rotateZ(360deg);
    transform: translateZ(-3000px) rotateZ(360deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateInNewspaper {
  from {
    -webkit-transform: translateZ(-3000px) rotateZ(-360deg);
    opacity: 0;
  }
}
@keyframes rotateInNewspaper {
  from {
    -webkit-transform: translateZ(-3000px) rotateZ(-360deg);
    transform: translateZ(-3000px) rotateZ(-360deg);
    opacity: 0;
  }
}

/* push */

@-webkit-keyframes rotatePushLeft {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
  }
}
@keyframes rotatePushLeft {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
}

@-webkit-keyframes rotatePushRight {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
  }
}
@keyframes rotatePushRight {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
}

@-webkit-keyframes rotatePushTop {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
  }
}
@keyframes rotatePushTop {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }
}

@-webkit-keyframes rotatePushBottom {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: rotateX(90deg);
  }
}
@keyframes rotatePushBottom {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
  }
}

/* pull */

@-webkit-keyframes rotatePullRight {
  from {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
  }
}
@keyframes rotatePullRight {
  from {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
}

@-webkit-keyframes rotatePullLeft {
  from {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
  }
}
@keyframes rotatePullLeft {
  from {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
}

@-webkit-keyframes rotatePullTop {
  from {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
  }
}
@keyframes rotatePullTop {
  from {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }
}

@-webkit-keyframes rotatePullBottom {
  from {
    opacity: 0;
    -webkit-transform: rotateX(90deg);
  }
}
@keyframes rotatePullBottom {
  from {
    opacity: 0;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
  }
}

/* fold */

@-webkit-keyframes rotateFoldRight {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(100%) rotateY(90deg);
  }
}
@keyframes rotateFoldRight {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(100%) rotateY(90deg);
    transform: translateX(100%) rotateY(90deg);
  }
}

@-webkit-keyframes rotateFoldLeft {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
  }
}
@keyframes rotateFoldLeft {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
    transform: translateX(-100%) rotateY(-90deg);
  }
}

@-webkit-keyframes rotateFoldTop {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(-100%) rotateX(90deg);
  }
}
@keyframes rotateFoldTop {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(-100%) rotateX(90deg);
    transform: translateY(-100%) rotateX(90deg);
  }
}

@-webkit-keyframes rotateFoldBottom {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(100%) rotateX(-90deg);
  }
}
@keyframes rotateFoldBottom {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(100%) rotateX(-90deg);
    transform: translateY(100%) rotateX(-90deg);
  }
}

/* unfold */

@-webkit-keyframes rotateUnfoldLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
  }
}
@keyframes rotateUnfoldLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
    transform: translateX(-100%) rotateY(-90deg);
  }
}

@-webkit-keyframes rotateUnfoldRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%) rotateY(90deg);
  }
}
@keyframes rotateUnfoldRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%) rotateY(90deg);
    transform: translateX(100%) rotateY(90deg);
  }
}

@-webkit-keyframes rotateUnfoldTop {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%) rotateX(90deg);
  }
}
@keyframes rotateUnfoldTop {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%) rotateX(90deg);
    transform: translateY(-100%) rotateX(90deg);
  }
}

@-webkit-keyframes rotateUnfoldBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%) rotateX(-90deg);
  }
}
@keyframes rotateUnfoldBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%) rotateX(-90deg);
    transform: translateY(100%) rotateX(-90deg);
  }
}

/* room walls */

@-webkit-keyframes rotateRoomLeftOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(-100%) rotateY(90deg);
  }
}
@keyframes rotateRoomLeftOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(-100%) rotateY(90deg);
    transform: translateX(-100%) rotateY(90deg);
  }
}

@-webkit-keyframes rotateRoomLeftIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(100%) rotateY(-90deg);
  }
}
@keyframes rotateRoomLeftIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(100%) rotateY(-90deg);
    transform: translateX(100%) rotateY(-90deg);
  }
}

@-webkit-keyframes rotateRoomRightOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(100%) rotateY(-90deg);
  }
}
@keyframes rotateRoomRightOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(100%) rotateY(-90deg);
    transform: translateX(100%) rotateY(-90deg);
  }
}

@-webkit-keyframes rotateRoomRightIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(-100%) rotateY(90deg);
  }
}
@keyframes rotateRoomRightIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(-100%) rotateY(90deg);
    transform: translateX(-100%) rotateY(90deg);
  }
}

@-webkit-keyframes rotateRoomTopOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(-100%) rotateX(-90deg);
  }
}
@keyframes rotateRoomTopOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(-100%) rotateX(-90deg);
    transform: translateY(-100%) rotateX(-90deg);
  }
}

@-webkit-keyframes rotateRoomTopIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(100%) rotateX(90deg);
  }
}
@keyframes rotateRoomTopIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(100%) rotateX(90deg);
    transform: translateY(100%) rotateX(90deg);
  }
}

@-webkit-keyframes rotateRoomBottomOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(100%) rotateX(90deg);
  }
}
@keyframes rotateRoomBottomOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(100%) rotateX(90deg);
    transform: translateY(100%) rotateX(90deg);
  }
}

@-webkit-keyframes rotateRoomBottomIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(-100%) rotateX(-90deg);
  }
}
@keyframes rotateRoomBottomIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(-100%) rotateX(-90deg);
    transform: translateY(-100%) rotateX(-90deg);
  }
}

/* cube */

@-webkit-keyframes rotateCubeLeftOut {
  0% {
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
  }
}
@keyframes rotateCubeLeftOut {
  0% {
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
    transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
    transform: translateX(-100%) rotateY(-90deg);
  }
}

@-webkit-keyframes rotateCubeLeftIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translateX(100%) rotateY(90deg);
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: translateX(50%) translateZ(-200px) rotateY(45deg);
  }
}
@keyframes rotateCubeLeftIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translateX(100%) rotateY(90deg);
    transform: translateX(100%) rotateY(90deg);
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: translateX(50%) translateZ(-200px) rotateY(45deg);
    transform: translateX(50%) translateZ(-200px) rotateY(45deg);
  }
}

@-webkit-keyframes rotateCubeRightOut {
  0% {
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: translateX(50%) translateZ(-200px) rotateY(45deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateX(100%) rotateY(90deg);
  }
}
@keyframes rotateCubeRightOut {
  0% {
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: translateX(50%) translateZ(-200px) rotateY(45deg);
    transform: translateX(50%) translateZ(-200px) rotateY(45deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateX(100%) rotateY(90deg);
    transform: translateX(100%) rotateY(90deg);
  }
}

@-webkit-keyframes rotateCubeRightIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
  }
}
@keyframes rotateCubeRightIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
    transform: translateX(-100%) rotateY(-90deg);
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
    transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
  }
}

@-webkit-keyframes rotateCubeTopOut {
  0% {
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(-100%) rotateX(90deg);
  }
}
@keyframes rotateCubeTopOut {
  0% {
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
    transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(-100%) rotateX(90deg);
    transform: translateY(-100%) rotateX(90deg);
  }
}

@-webkit-keyframes rotateCubeTopIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translateY(100%) rotateX(-90deg);
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
  }
}
@keyframes rotateCubeTopIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translateY(100%) rotateX(-90deg);
    transform: translateY(100%) rotateX(-90deg);
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
    transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
  }
}

@-webkit-keyframes rotateCubeBottomOut {
  0% {
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(100%) rotateX(-90deg);
  }
}
@keyframes rotateCubeBottomOut {
  0% {
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
    transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(100%) rotateX(-90deg);
    transform: translateY(100%) rotateX(-90deg);
  }
}

@-webkit-keyframes rotateCubeBottomIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translateY(-100%) rotateX(90deg);
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    -webkit-transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
  }
}
@keyframes rotateCubeBottomIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translateY(-100%) rotateX(90deg);
    transform: translateY(-100%) rotateX(90deg);
  }
  50% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
    transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
  }
}

/* carousel */

@-webkit-keyframes rotateCarouselLeftOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(-150%) scale(0.4) rotateY(-65deg);
  }
}
@keyframes rotateCarouselLeftOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(-150%) scale(0.4) rotateY(-65deg);
    transform: translateX(-150%) scale(0.4) rotateY(-65deg);
  }
}

@-webkit-keyframes rotateCarouselLeftIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(200%) scale(0.4) rotateY(65deg);
  }
}
@keyframes rotateCarouselLeftIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(200%) scale(0.4) rotateY(65deg);
    transform: translateX(200%) scale(0.4) rotateY(65deg);
  }
}

@-webkit-keyframes rotateCarouselRightOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(200%) scale(0.4) rotateY(65deg);
  }
}
@keyframes rotateCarouselRightOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateX(200%) scale(0.4) rotateY(65deg);
    transform: translateX(200%) scale(0.4) rotateY(65deg);
  }
}

@-webkit-keyframes rotateCarouselRightIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(-200%) scale(0.4) rotateY(-65deg);
  }
}
@keyframes rotateCarouselRightIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateX(-200%) scale(0.4) rotateY(-65deg);
    transform: translateX(-200%) scale(0.4) rotateY(-65deg);
  }
}

@-webkit-keyframes rotateCarouselTopOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(-200%) scale(0.4) rotateX(65deg);
  }
}
@keyframes rotateCarouselTopOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(-200%) scale(0.4) rotateX(65deg);
    transform: translateY(-200%) scale(0.4) rotateX(65deg);
  }
}

@-webkit-keyframes rotateCarouselTopIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(200%) scale(0.4) rotateX(-65deg);
  }
}
@keyframes rotateCarouselTopIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(200%) scale(0.4) rotateX(-65deg);
    transform: translateY(200%) scale(0.4) rotateX(-65deg);
  }
}

@-webkit-keyframes rotateCarouselBottomOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(200%) scale(0.4) rotateX(-65deg);
  }
}
@keyframes rotateCarouselBottomOut {
  from {
  }
  to {
    opacity: 0.3;
    -webkit-transform: translateY(200%) scale(0.4) rotateX(-65deg);
    transform: translateY(200%) scale(0.4) rotateX(-65deg);
  }
}

@-webkit-keyframes rotateCarouselBottomIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(-200%) scale(0.4) rotateX(65deg);
  }
}
@keyframes rotateCarouselBottomIn {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(-200%) scale(0.4) rotateX(65deg);
    transform: translateY(-200%) scale(0.4) rotateX(65deg);
  }
}

/* sides */

@-webkit-keyframes rotateSidesOut {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateZ(-500px) rotateY(90deg);
  }
}
@keyframes rotateSidesOut {
  from {
  }
  to {
    opacity: 0;
    -webkit-transform: translateZ(-500px) rotateY(90deg);
    transform: translateZ(-500px) rotateY(90deg);
  }
}

@-webkit-keyframes rotateSidesIn {
  from {
    opacity: 0;
    -webkit-transform: translateZ(-500px) rotateY(-90deg);
  }
}
@keyframes rotateSidesIn {
  from {
    opacity: 0;
    -webkit-transform: translateZ(-500px) rotateY(-90deg);
    transform: translateZ(-500px) rotateY(-90deg);
  }
}

/* slide */

@-webkit-keyframes rotateSlideOut {
  0% {
  }
  25% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px);
  }
  75% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
}
@keyframes rotateSlideOut {
  0% {
  }
  25% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px);
    transform: translateZ(-500px);
  }
  75% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
    transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
    transform: translateZ(-500px) translateX(-200%);
  }
}

@-webkit-keyframes rotateSlideIn {
  0%,
  25% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0);
  }
}
@keyframes rotateSlideIn {
  0%,
  25% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(200%);
    transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px);
    transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
  }
}

/* animation delay classes */

.pt-page-delay100 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.pt-page-delay180 {
  -webkit-animation-delay: 0.18s;
  animation-delay: 0.18s;
}
.pt-page-delay200 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.pt-page-delay300 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.pt-page-delay400 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.pt-page-delay500 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.pt-page-delay700 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.pt-page-delay1000 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.startingApp-container {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(#7b16ff 21%, rgb(68 0 204 / 61%) 88%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 0rem;
  justify-content: space-evenly;
  overflow: hidden;
}
/* .pictContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
} */
/* .pictContent img {
  transform: translate(0px, 5rem);
}
.startingApp-container p {
  transform: translate(0px, -7rem);
} */

.center-loadingApp {
  /* margin-bottom: -8rem;
  margin-top: 6rem; */
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.startingApp-container h5 {
  font-size: 80%;
  word-break: break-word;
}
.loader-startApp {
  position: relative;
  width: 200px;
  height: 200px;
  margin-top: -5rem;
  margin-bottom: 7rem;
}

/* The dot */
.loader-startApp > .dot-startApp {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;

  width: 160px;
  height: 100px;

  margin-top: -50px;
  margin-left: -80px;

  border-radius: 5px;

  background-color: #1e3f57;

  -webkit-transform: preserve-3d;

          transform: preserve-3d;

  -webkit-animation: dot1 2.8s cubic-bezier(0.55, 0.3, 0.24, 0.99) infinite;

          animation: dot1 2.8s cubic-bezier(0.55, 0.3, 0.24, 0.99) infinite;
}

.loader-startApp > .dot-startApp:nth-child(2) {
  z-index: 11;

  width: 150px;
  height: 90px;

  margin-top: -45px;
  margin-left: -75px;

  border-radius: 3px;

  background-color: #3c617d;

  -webkit-animation-name: dot2;

          animation-name: dot2;
}

.loader-startApp > .dot-startApp:nth-child(3) {
  z-index: 12;

  width: 40px;
  height: 20px;

  margin-top: 50px;
  margin-left: -20px;

  border-radius: 0 0 5px 5px;

  background-color: #6bb2cd;

  -webkit-animation-name: dot3;

          animation-name: dot3;
}

@-webkit-keyframes dot1 {
  3%,
  97% {
    width: 160px;
    height: 100px;

    margin-top: -50px;
    margin-left: -80px;
  }
  30%,
  36% {
    width: 80px;
    height: 120px;

    margin-top: -60px;
    margin-left: -40px;
  }
  63%,
  69% {
    width: 40px;
    height: 80px;

    margin-top: -40px;
    margin-left: -20px;
  }
}

@keyframes dot1 {
  3%,
  97% {
    width: 160px;
    height: 100px;

    margin-top: -50px;
    margin-left: -80px;
  }
  30%,
  36% {
    width: 80px;
    height: 120px;

    margin-top: -60px;
    margin-left: -40px;
  }
  63%,
  69% {
    width: 40px;
    height: 80px;

    margin-top: -40px;
    margin-left: -20px;
  }
}

@-webkit-keyframes dot2 {
  3%,
  97% {
    width: 150px;
    height: 90px;

    margin-top: -45px;
    margin-left: -75px;
  }
  30%,
  36% {
    width: 70px;
    height: 96px;

    margin-top: -48px;
    margin-left: -35px;
  }
  63%,
  69% {
    width: 32px;
    height: 60px;

    margin-top: -30px;
    margin-left: -16px;
  }
}

@keyframes dot2 {
  3%,
  97% {
    width: 150px;
    height: 90px;

    margin-top: -45px;
    margin-left: -75px;
  }
  30%,
  36% {
    width: 70px;
    height: 96px;

    margin-top: -48px;
    margin-left: -35px;
  }
  63%,
  69% {
    width: 32px;
    height: 60px;

    margin-top: -30px;
    margin-left: -16px;
  }
}

@-webkit-keyframes dot3 {
  3%,
  97% {
    width: 40px;
    height: 20px;

    margin-top: 50px;
    margin-left: -20px;
  }
  30%,
  36% {
    width: 8px;
    height: 8px;

    margin-top: 49px;
    margin-left: -5px;

    border-radius: 8px;
  }
  63%,
  69% {
    width: 16px;
    height: 4px;

    margin-top: -37px;
    margin-left: -8px;

    border-radius: 10px;
  }
}

@keyframes dot3 {
  3%,
  97% {
    width: 40px;
    height: 20px;

    margin-top: 50px;
    margin-left: -20px;
  }
  30%,
  36% {
    width: 8px;
    height: 8px;

    margin-top: 49px;
    margin-left: -5px;

    border-radius: 8px;
  }
  63%,
  69% {
    width: 16px;
    height: 4px;

    margin-top: -37px;
    margin-left: -8px;

    border-radius: 10px;
  }
}

.tracking-in-contract-bck-top {
  -webkit-animation: tracking-in-contract-bck-top 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
          animation: tracking-in-contract-bck-top 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
@-webkit-keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
            transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
            transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.roll-in-left {
  -webkit-animation: roll-in-left 0.6s 1s ease-out both;
          animation: roll-in-left 0.6s 1s ease-out both;
}

@-webkit-keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
            transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
            transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

.tracking-in-expand-fwd-bottom {
  -webkit-animation: tracking-in-expand-fwd-bottom 0.8s 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
          animation: tracking-in-expand-fwd-bottom 0.8s 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@-webkit-keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
            transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
            transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.slide-in-blurred-right {
  -webkit-animation: slide-in-blurred-right 0.6s 0.2s cubic-bezier(0.23, 1, 0.32, 1)
    both;
          animation: slide-in-blurred-right 0.6s 0.2s cubic-bezier(0.23, 1, 0.32, 1)
    both;
}
@-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
.bounce-in-bottom {
  -webkit-animation: bounce-in-bottom 1.1s both;
          animation: bounce-in-bottom 1.1s both;
}
@-webkit-keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(65px);
            transform: translateY(65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(28px);
            transform: translateY(28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(65px);
            transform: translateY(65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(28px);
            transform: translateY(28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
.loadingText {
  position: relative;
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.5em;
}
.loadingText:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 7em;
  white-space: nowrap;
  color: #fff;
  -webkit-animation: loading 4.8s linear;
          animation: loading 4.8s linear;
}
@-webkit-keyframes loading {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}
@keyframes loading {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}
.footer-loadingApp.bounce-in-bottom {
  height: 11%;
}

#skill {
  list-style: none;
  font: 12px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  width: 296px;
  margin: 6px auto 0;
  position: relative;
  line-height: 2em;
  padding: 56px 0;
}

#skill li {
  margin-bottom: 50px;
  background: #e9e5e2;
  background-image: linear-gradient(to to bottom, #e1ddd9, #e9e5e2);
  height: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 0px #bebbb9 inset, 0 1px 0 #fcfcfc;
}

#skill li h3 {
  position: relative;
  top: -25px;
}

.bar-loaderApp {
  height: 18px;
  margin: 1px 2px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0 1px 0px #fcfcfc inset, 0 1px 0 #bebbb9;
}

.graphic-design {
  width: 100%;
  -webkit-animation: graphic-design 4.4s ease-out;
          animation: graphic-design 4.4s ease-out;

  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
}

@-webkit-keyframes graphic-design {
  0% {
    width: 0px;
  }
  10% {
    width: 5%;
  }
  15% {
    width: 10%;
  }
  20% {
    width: 12%;
  }
  21% {
    width: 13%;
  }
  23% {
    width: 17%;
  }
  25% {
    width: 25%;
  }
  30% {
    width: 26%;
  }
  50% {
    width: 40%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 70%;
  }
  80% {
    width: 72%;
  }
  85% {
    width: 73%;
  }
  90% {
    width: 84%;
  }
  100% {
    width: 97%;
  }
}

@keyframes graphic-design {
  0% {
    width: 0px;
  }
  10% {
    width: 5%;
  }
  15% {
    width: 10%;
  }
  20% {
    width: 12%;
  }
  21% {
    width: 13%;
  }
  23% {
    width: 17%;
  }
  25% {
    width: 25%;
  }
  30% {
    width: 26%;
  }
  50% {
    width: 40%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 70%;
  }
  80% {
    width: 72%;
  }
  85% {
    width: 73%;
  }
  90% {
    width: 84%;
  }
  100% {
    width: 97%;
  }
}

.stage-hello {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: hiddenTime 5s ease-in-out both;
          animation: hiddenTime 5s ease-in-out both;
  text-align: center;
}

.stage-hello p {
  font-size: 14px;
  color: darkgrey;
  word-break: break-word;
  position: relative;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  width: 23em;
  -webkit-animation: type 3s 4s steps(60, end);
          animation: type 3s 4s steps(60, end);
}

.stage-hello span {
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
}

@-webkit-keyframes type {
  from {
    width: 0;
    -webkit-filter: opacity(0);
            filter: opacity(0);
  }
  to {
    -webkit-filter: opacity(1);
            filter: opacity(1);
  }
}

@keyframes type {
  from {
    width: 0;
    -webkit-filter: opacity(0);
            filter: opacity(0);
  }
  to {
    -webkit-filter: opacity(1);
            filter: opacity(1);
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: 100;
    -webkit-filter: opacity(1);
            filter: opacity(1);
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: 100;
    -webkit-filter: opacity(1);
            filter: opacity(1);
  }
}

@-webkit-keyframes blink {
  to {
    opacity: 0;
  }
}

@keyframes blink {
  to {
    opacity: 0;
  }
}

::selection {
  background: black;
}
@-webkit-keyframes hiddenTime {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hiddenTime {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.counterNotStart {
  margin-bottom: 3em;
}
.counterStart {
  margin-bottom: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-session-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  width: 81%;
  border: 1px dotted darkgrey;
  padding: 8px;
  border-radius: 7px;
}

.session-timer {
  margin: 18px auto;
  display: flex;
  justify-content: space-around;
  max-width: 1.875rem;
  max-width: 30rem;
}
@media (min-width: 576px) {
  .form-inline .form-control {
    /* display: inline-block; */
    width: auto;
    vertical-align: middle;
  }
}

.session-timer input {
  max-width: 30px;
  max-width: 1.875rem;
  text-align: center;
  /* display: none; */
}
input,
.form-control {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.form-control {
  display: block;
  width: 100%;
  padding: 8px 12px;
  padding: 0.5rem 0.75rem;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-inline .form-label {
  margin-left: 6px;
  margin-left: 0.375rem;
  margin-right: -0.625rem;
  display: inline-block;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}
.session-timer__input {
  display: flex;
  align-items: center;
}
.form-label {
  font-size: 1.4rem !important;
  color: #848181;
}
.session-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 91vh;

  color: darkgray;
  /* top: -2em; */
}

.App-header,
.session-container,
.why-container,
.how-container,
.presets-container {
  background: var(--Background);
}
.fixed {
  position: fixed;
}
.inherit {
  position: inherit;
}
.startButton {
  color: #fff;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  box-shadow: 0 1px 19px rgb(123 22 255 / 30%);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
}

.wrapperStopButton {
  position: relative;
  width: 100%;
  height: 21vh;
}

.stopButton:before,
.stopButton:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: inherit;
  border-radius: inherit;
  border: inherit;
  background-color: transparent;
  box-shadow: inherit;
  -webkit-animation: pulse 2.2s ease-out infinite;
          animation: pulse 2.2s ease-out infinite;
}
.stopButton:after {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}
@-webkit-keyframes tiny-pulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
            transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1.05);
            transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
            transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}
@keyframes tiny-pulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
            transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1.05);
            transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
            transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}
@-webkit-keyframes pulse {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(4, 4, 4);
            transform: scale3d(4, 4, 4);
  }
}
@keyframes pulse {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(4, 4, 4);
            transform: scale3d(4, 4, 4);
  }
}
.stopButton {
  box-shadow: 0 1px 19px rgb(238 9 121 / 30%);
  border-radius: 50%;
  color: #fff;
  width: 80px;
  height: 80px;
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 100%;
  border: var(--borderEffect);
  background-color: var(--Background);
  box-shadow: var(--shadowEffect);
  -webkit-animation: tiny-pulse 1.1s ease-out infinite;
          animation: tiny-pulse 1.1s ease-out infinite;
}

/* Progress bar */
.indicator {
  width: 48em;
  height: 28em;
  text-align: center;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsContainer {
  margin-top: -7em;
  scale: 0.5;
  display: flex;
  align-items: center;
  -webkit-transform: translate(-43px, 4px);
          transform: translate(-43px, 4px);
  width: 100%;
  margin-bottom: -7em;
}
.zenIcon {
  width: 80px;
  height: 80px;
}

@media screen and (max-width: 320px) {
  .session-container {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
}

@media screen and (max-width: 700px) {
  .statsContainer {
    -webkit-transform: translate(2px, 4px) scale(0.6);
            transform: translate(2px, 4px) scale(0.6);
  }
}
.session-container h1 {
  color: var(--colorText);
}

@media screen and (min-width: 1000px) {
  .indicator {
    height: 40em !important;
  }
}

.buttonStartTimerContainer {
  -webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-bck-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
.sessionText {
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.session-textContent {
  font-size: 14px;
  text-align: left;
  max-width: 100% !important;
  padding: 2px;
}
.headSectionTimer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.session-container .slide-in-top {
  top: 20% !important;
  z-index: 99999;
}
.chrono-counterStart {
  margin-bottom: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(0.67);
          transform: scale(0.67);
}
.chronoConfig {
  width: 54px;
  position: absolute;
  left: 6px;
  bottom: 6rem;
  -webkit-filter: drop-shadow(0px 2px 3px black);
          filter: drop-shadow(0px 2px 3px black);
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
          animation: heartbeat 1.5s ease-in-out infinite both;
}
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.progress-circle {
  background-color: #ddd;
  border-radius: 50%;
  height: 32rem;
  position: relative;
  width: 32rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-circle:before {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  content: attr(data-progress) "%";
  display: flex;
  font-size: 7rem;
  justify-content: center;
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  bottom: 1rem;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-animation: heartbeat 1.8s ease-in-out infinite both;
          animation: heartbeat 1.8s ease-in-out infinite both;
}
.progress-circle:after {
  background-color: #7b16ff;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
}
.progress-circle:hover:before,
.progress-circle:focus:before {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
/**
* $step is set to 5 by default, meaning you can only use percentage classes in increments of five (e.g. 25, 30, 45, 50, and so on). This helps to reduce the size of the final CSS file. If you need a number that doesn't end in 0 or 5, you can change the text percentage while rounding the class up/down to the nearest 5.
*/
.progress-circle[data-progress="0"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(90deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="1"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(93.6deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="2"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(97.2deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="3"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(100.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="4"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(104.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="5"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(108deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="6"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(111.6deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="7"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(115.2deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="8"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(118.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="9"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(122.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="10"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(126deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="11"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(129.6deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="12"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(133.2deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="13"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(136.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="14"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(140.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="15"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(144deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="16"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(147.6deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="17"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(151.2deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="18"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(154.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="19"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(158.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="20"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(162deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="21"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(165.60000000000002deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="22"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(169.2deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="23"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(172.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="24"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(176.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="25"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(180deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="26"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(183.60000000000002deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="27"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(187.2deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="28"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(190.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="29"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(194.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="30"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(198deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="31"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(201.60000000000002deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="32"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(205.2deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="33"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(208.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="34"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(212.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="35"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(216deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="36"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(219.6deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="37"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(223.20000000000002deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="38"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(226.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="39"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(230.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="40"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(234deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="41"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(237.6deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="42"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(241.20000000000002deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="43"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(244.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="44"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(248.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="45"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(252deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="46"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(255.6deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="47"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(259.20000000000005deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="48"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(262.8deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="49"]:after {
  background-image: linear-gradient(
      90deg,
      #ddd 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(266.4deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="50"]:after {
  background-image: linear-gradient(
      -90deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="51"]:after {
  background-image: linear-gradient(
      -86.4deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="52"]:after {
  background-image: linear-gradient(
      -82.8deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="53"]:after {
  background-image: linear-gradient(
      -79.2deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="54"]:after {
  background-image: linear-gradient(
      -75.6deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="55"]:after {
  background-image: linear-gradient(
      -72deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="56"]:after {
  background-image: linear-gradient(
      -68.4deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="57"]:after {
  background-image: linear-gradient(
      -64.8deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="58"]:after {
  background-image: linear-gradient(
      -61.2deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="59"]:after {
  background-image: linear-gradient(
      -57.6deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="60"]:after {
  background-image: linear-gradient(
      -54deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="61"]:after {
  background-image: linear-gradient(
      -50.4deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="62"]:after {
  background-image: linear-gradient(
      -46.8deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="63"]:after {
  background-image: linear-gradient(
      -43.199999999999996deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="64"]:after {
  background-image: linear-gradient(
      -39.6deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="65"]:after {
  background-image: linear-gradient(
      -36deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="66"]:after {
  background-image: linear-gradient(
      -32.4deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="67"]:after {
  background-image: linear-gradient(
      -28.799999999999997deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="68"]:after {
  background-image: linear-gradient(
      -25.200000000000003deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="69"]:after {
  background-image: linear-gradient(
      -21.599999999999994deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="70"]:after {
  background-image: linear-gradient(
      -18deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="71"]:after {
  background-image: linear-gradient(
      -14.399999999999991deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="72"]:after {
  background-image: linear-gradient(
      -10.799999999999997deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="73"]:after {
  background-image: linear-gradient(
      -7.200000000000003deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="74"]:after {
  background-image: linear-gradient(
      -3.599999999999994deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="75"]:after {
  background-image: linear-gradient(
      0deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="76"]:after {
  background-image: linear-gradient(
      3.600000000000009deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="77"]:after {
  background-image: linear-gradient(
      7.200000000000003deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="78"]:after {
  background-image: linear-gradient(
      10.799999999999997deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="79"]:after {
  background-image: linear-gradient(
      14.400000000000006deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="80"]:after {
  background-image: linear-gradient(
      18deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="81"]:after {
  background-image: linear-gradient(
      21.60000000000001deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="82"]:after {
  background-image: linear-gradient(
      25.200000000000003deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="83"]:after {
  background-image: linear-gradient(
      28.799999999999997deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="84"]:after {
  background-image: linear-gradient(
      32.400000000000006deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="85"]:after {
  background-image: linear-gradient(
      36deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="86"]:after {
  background-image: linear-gradient(
      39.599999999999994deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="87"]:after {
  background-image: linear-gradient(
      43.20000000000002deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="88"]:after {
  background-image: linear-gradient(
      46.80000000000001deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="89"]:after {
  background-image: linear-gradient(
      50.400000000000006deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="90"]:after {
  background-image: linear-gradient(
      54deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="91"]:after {
  background-image: linear-gradient(
      57.599999999999994deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="92"]:after {
  background-image: linear-gradient(
      61.20000000000002deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="93"]:after {
  background-image: linear-gradient(
      64.80000000000001deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="94"]:after {
  background-image: linear-gradient(
      68.4deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="95"]:after {
  background-image: linear-gradient(
      72deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="96"]:after {
  background-image: linear-gradient(
      75.6deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="97"]:after {
  background-image: linear-gradient(
      79.20000000000002deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="98"]:after {
  background-image: linear-gradient(
      82.80000000000001deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="99"]:after {
  background-image: linear-gradient(
      86.4deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="100"]:after {
  background-image: linear-gradient(
      90deg,
      #7b16ff 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, #7b16ff 50%, #ddd 50%, #ddd);
}

@media screen and (max-width: 500px) {
  .progress-circle {
    height: 21rem;
    width: 21rem;
  }
}

@media screen and (min-width: 750px) {
  .progress-circle {
    height: 22rem;

    width: 22rem;
  }
}

#myModal2 {
  background: whitesmoke;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.text-center-histo {
  text-align: center !important;
  height: 56px;
  border-top: 1px solid lightgrey;
}
::-webkit-scrollbar {
  width: 2px;
  background: whitesmoke;
}

::-webkit-scrollbar-thumb {
  background: #5c09e2;
}
.modalcontentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
}
.modalcontentHeader p {
  font-size: 1rem;
  color: white;
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: absolute;
  top: 24%;
  width: 86%;
  display: flex;
  z-index: 100;
}

@media screen and (max-width: 360px) {
  .slide-in-top {
    top: 14% !important;
    z-index: 200 !important;
  }
}
@media screen and (min-width: 361px) and (max-width: 375px) {
  .slide-in-top {
    top: 19% !important;
  }
  /* .eraseIcon {
    transform: translate(8.4em, 0em) !important;
  } */
}
.history-list span sub {
  color: var(--purpleText);
  font-style: 14px;
  font-weight: bold;
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.eraseIcon {
  width: 24px;
  /* transform: translate(9.3em, 0em); */
}
.card-history {
  width: 100%;
  min-height: 45vh;
  background: var(--Background);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.history-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2em;
}
.history-list p {
  font-style: 14px;
  font-weight: bold;
}
.history-list span {
  border: 1px dotted darkgrey;
  padding: 6px;
  margin-right: 2px;
  text-align: center;
  border-radius: 5px;
  min-height: 94px;
  background: var(--cardColor);
}
.modal-content {
  box-shadow: 0 2px 6px grey;
}
.eraseHistoButton {
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  border: none;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  margin-top: 10px;
}
.card-history .history-list:last-child {
  margin-bottom: 5em;
}

.minuteSlider {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  background: whitesmoke;
  border-radius: 9px;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  margin: 10px 2%;
  width: 106px;
  height: 102px;
  box-shadow: 0 2px 6px black;
  transition: all 0.7s ease-in-out;
}
.minuteContent {
  list-style: none;
  position: absolute;
  top: 0;
  left: 0 !important;
  overflow-y: scroll;
  width: 100%;
  height: 54%;
  right: auto;
  -webkit-transform: translate(-38px, 23px);
          transform: translate(-38px, 23px);
}
.minuteLists {
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  margin-bottom: 7px;
  margin-top: 7px;
  overflow: hidden;
  width: 100%;
  height: 44px;
}
.arrow {
  border-top: 3px solid #ddd;
  border-left: 3px solid #ddd;
  height: 15px;
  width: 15px;
  display: inline-block;
  position: absolute;
}
.arrow.down {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.arrow.up {
  -webkit-transform: rotate(-315deg);
  transform: rotate(-315deg);
}
.tap_area.value_down {
  bottom: 0px;
  z-index: 11;
}

.tap_area .value_up .arrow {
  top: 10px;
}
.tap_area .value_down .arrow {
  bottom: 10px;
}
.tap_area.value_up {
  position: fixed;
  left: 42%;
  top: 10px;
  width: 100%;
  height: 20px;
  z-index: 10;
}
.tap_area.value_down {
  bottom: 7px;
  z-index: 11;
  position: fixed;
  width: 100%;
  height: 20px;
  left: 42%;
}
/* .minuteSlider-selected {
  border-radius: 50%;
  transition: all 0.7s ease-in-out;
} */

.number-selected {
  transition: all 0.7s ease-in-out;
  /* box-shadow: 0 2px 6px black, inset 7px 1px 10px grey, inset -5px -5px 13px !important; */
  color: #9e58e6 !important;
}
.arrow-selected {
  border-top: 3px solid #eeebeb;
  border-left: 3px solid #eeebeb;
  height: 15px;
  width: 15px;
  display: inline-block;
  position: absolute;
  transition: all 0.7s ease-in-out;
}
.info-icon {
  position: absolute;
  left: 0;
  top: 0;
}
.info-icon img {
  border-radius: 0 0 10px 0;
}

.block-settings-wrapper {
  position: fixed;
  left: -136px;
  bottom: 20%;
  width: 134px;
  z-index: 9999;
  transition: all 0.3s;
}

.block-settings-wrapper section {
  display: block;
  clear: both;
  padding: 15px 12px 15px 15px;
  height: 65px;
  background: var(--Background);
  box-shadow: 0 1px 2px rgb(35 35 35 / 10%), transparent 0 0 0,
    transparent 0 0 0;
  position: relative;
  z-index: 0;
}
.block-settings-wrapper section h3 {
  clear: both;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #333;
  margin: 0 0 15px 0 !important;
}
.block-settings-wrapper section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.block-settings-wrapper section ul li {
  list-style: none;
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
}
.block-settings-wrapper section ul li:last-child {
  margin-bottom: 0;
}

.block-settings-wrapper section ul li.active a {
  font-weight: bold;
  color: #1f2528;
}
.block-settings-wrapper section hr {
  display: block;
  border: none;
  height: 1px;
  margin: 15px 0;
  background: #ccc;
}
.block-settings-wrapper section span {
  display: block;
  float: left;
  width: 25px;
  height: 25px;
  margin-right: 3px;
  cursor: pointer;
  border-radius: 2px;
}
.block-settings-wrapper section span.blue {
  background-color: #7c4fe0;
}
.block-settings-wrapper section span.green {
  background-color: #2aa275;
}
.block-settings-wrapper section span.red {
  background-color: #ff3d65;
}
.block-settings-wrapper section span.turquoise {
  background-color: #46cad7;
}
.block-settings-wrapper section span.purple {
  background-color: #d1397c;
}
.block-settings-wrapper section span.orange {
  background-color: #ee8f67;
}
.block-settings-wrapper section span.yellow {
  background-color: #e4d20c;
}
.block-settings-wrapper section span.grey {
  background-color: #6b798f;
}
.block-settings-wrapper #settings_close {
  width: 34px;
  height: 26px;
  display: block;
  position: absolute;
  right: -55px;
  top: 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #1f2528;
  transition: all 0.3s;
  border-radius: 0px 2px 2px 0px;
  text-indent: -9000px;
  cursor: pointer;
  padding: 10px;
  box-shadow: 0 1px 4px black;
  background-image: url(/static/media/stopclock-filled.13f7a56b.svg);
  background-size: 78%;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.block-settings-wrapper #settings_close:hover {
  background-color: #434343;
}
.block-settings-wrapper section ul li a {
  color: #808080;
}
.block-settings-wrapper section ul li.active > i {
  margin-left: 5px;
  font-size: 14px;
  line-height: 20px;
}

.block-settings-wrapper #settings_close:hover {
  background-color: #434343;
}

/* Opened */
.opened-settings {
  left: 0px;
}

/* @media (max-width: 767px) {
  .block-settings-wrapper {
    display: none;
  }
} */
.choicesTime {
  width: 40px;
}
.choicesTime:first-child {
  margin-right: 1rem;
}
#block_settings {
  box-shadow: 0 1px 4px black;
}
#block_settings section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}
.buttonChoice {
  width: 54%;
  margin: 0.2rem;
}
.buttonChoice p {
  font-size: 10px;
  color: var(--colorText);
}
.block-settings-wrapper button:not(.buttonSelected) {
  opacity: 0.2;
}

button {
  background: transparent;
  border: none;
}

/* body {
  font-family: sans-serif;
  display: grid;
  height: 100vh;
  place-items: center;
} */

.base-timer {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 30px;
}

.base-timer__svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}

.why-container,
.how-container,
.presets-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 7em;
  overflow-y: scroll;
}

.why-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-container {
  display: flex;
  border-radius: 10px !important;
  background: var(--cardColor) !important;
  /* height: 18rem; */
}
.card-left {
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  color: #fff;
}
.card-right {
  width: 66%;
}

.card-whyTitle {
  width: 104px;
  font-size: 14px;
}
.btn--primary,
.btn--primary:not([href]):not([tabindex]),
.btn--primary:hover {
  color: #fff;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
}
.btn {
  display: inline-block;
  padding: 8px 18px 9px;
  padding: 0.5rem 1.125rem 0.5625rem;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2.1875rem;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
  padding: 14px;
}

.text-center {
  text-align: center !important;
}
.mt-4 {
  margin-top: 24px !important;
  margin-top: 1.5rem !important;
}

/**************************\
  Basic Modal Styles
\**************************/
.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.modal__container {
  background: var(--Background);

  padding: 30px;
  max-width: 500px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  width: 100%;
  height: 65px;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.25;
  color: #ffffff;
  box-sizing: border-box;
  width: 60%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
}

.modal__close {
  background: transparent;
  border: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.infosContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.modal__header .modal__close:before {
  content: "✕";
  font-size: 36px;
  color: #cd3434;
  position: absolute;
  right: 12px;
  font-weight: bold;
}

.modal__content {
  margin-top: 2rem;
  /* margin-bottom: 2rem; */
  line-height: 1.5;
  color: var(--colorText);
  min-height: 100vh;
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  color: #ffffff;
  font-weight: bold;
}

.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************\
  Demo Animation Style
\**************************/
@-webkit-keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes mmslideIn {
  from {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes mmslideIn {
  from {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes mmslideOut {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}
@keyframes mmslideOut {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}
.modal__footer {
  padding-bottom: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.micromodal-slide.is-open {
  display: block;
}

/* .micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
} */

/* .micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
} */

/* .micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
} */

/* .micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
} */

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

/**************************\
  Button default style
\**************************/

.cat-outer {
  align-self: auto;
}

.modalBtn {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  font-weight: inherit;
  padding: 0;
  text-decoration: none;
  padding: 10px 35px;
  border-radius: 5px;
  margin: 20px;
}

.notSmooth {
  background-image: radial-gradient(
    ellipse farthest-corner at top right,
    #f39c12 0%,
    #f8664f 100%
  );
  color: white;
  width: 73%;
  font-size: 11px;
}

.smooth {
  background-color: midnightblue;
  color: white;
}

#modal-2 {
  -webkit-overflow-scrolling: touch;
}
.buttonInfos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.titleInfos,
.paragInfos {
  max-width: 80%;
  margin-bottom: 17px;
}
.subWhyContent {
  width: 89%;
  margin-bottom: 15px;
  border: 1px dotted var(--dotted);
  padding: 7px;
  border-radius: 17px;
  background-color: var(--cardColor);
  text-align: center;
  font-size: 1.1rem;
  color: var(--globalTextColor);
}
.why-content-text {
  color: var(--colorText);
}

.how-content,
.why-content {
  padding-bottom: 2em;
}

.how-content .card-container {
  display: flex;
  border-radius: 10px !important;
  min-height: 11em !important;
}
.detailContent {
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999999;
  box-shadow: 6px 4px 28px black;
  border-radius: 10px 10px 0 0;
  overflow-y: scroll;
}
.deteiledConseils-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px !important;
}
.cardConseil-header {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  width: 100%;
  position: relative;
  height: 60px;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  color: white;
  border-radius: 8px 8px 0 0;
  top: 0;
}

.how-content-text {
  color: var(--colorText);
}
.closeConseils {
  position: absolute;
  right: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #cd3434;
  font-size: 27px;
}
.detailedConseil-card {
  padding: 26px;
  /* height: 100vh; */
  border: 1px dashed lightgray;
  /* width: 76%; */
  background: var(--Background);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding-top: 9.5em; */
}
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.slide-out-bottom {
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
          animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}
.detailedConseil-card p {
  margin-bottom: 16px;
  max-width: 86%;
  font-size: 1.1rem;
  color: var(--colorText);
}
.cardConseil-header h1 {
  width: 60%;
  font-size: 16px;
  color: white !important;
}
.subConseils {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0;
  border-radius: 5px;
  margin: 15px;
}
.conseils {
  font-size: 12px;
  font-style: italic;
  color: #fff;
  margin-top: 1em;
  cursor: pointer;
  margin-bottom: 1em;
}
/* .detailedConseil-card p:first-child {
  margin-top: 6em;
} */
.footer-conseils {
  margin-bottom: 10em;
}
.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
          animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}

@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}

.buttonPresets-container {
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
  width: 100%;
  margin-top: 1em;
  width: 100%;
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  grid-row-gap: 43px;
}
.buttonPresets {
  border-radius: 50%;
  height: 87px;
  width: 87px;
  border: none;
  font-style: italic;
  text-transform: lowercase;
  box-shadow: 0 1px 6px grey, inset 0 2px 6px white;
  font-size: 0.67rem;
  word-break: break-word;
}
.soundPicture {
  height: 76px;
  width: 76px;
}
.presets-content {
  width: 100%;
  /* height: 22em; */
  /* transform: translate(0px, -2em); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 12px;
}
.buttonPresets-container:last-child {
  margin-top: 1em;
}
.buttonPresets:hover,
.buttonPresets--active,
.buttonPresets--active:hover {
  color: #7b16ff;
  border: 1px solid #7b16ff;
  box-shadow: 0 1px 11px rgb(0 0 0 / 25%);
}
.buttonPresets:hover h2,
.buttonPresets--active h2,
.buttonPresets--active h2:hover {
  font-style: italic;
  font-weight: lighter;
  text-transform: lowercase;
}
.sound-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.soundTitle {
  /* position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  height: 100%; */
  display: none;
}
.hiddenText,
.hiddenText blockquote {
  -webkit-filter: opacity(0);
          filter: opacity(0);
  height: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}
.preset-content-hidden {
  /* height: 27em !important; */
  -webkit-transform: inherit !important;
          transform: inherit !important;
}
.soundPresets-content {
  display: flex;
  /* align-items: center;
    justify-content: center; */
  position: relative;
}
.timerName {
  position: absolute;
  top: 37%;
  left: 9%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 7px;
  box-shadow: 0 1px 1px grey;
  padding: 2px;
}
.preset1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonPresets-container .preset1:first-child .soundPresets-content h2 {
  left: 12% !important;
}
.Night,
.Tropical,
.Lake,
.Volcan {
  border-radius: 50%;
}
.headerMultimedia {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--backgroundHeaderMultimedia);
  height: 55px;
  position: fixed;
  top: 0;
  box-shadow: 0 0px 7px var(--shadow);
  left: 0;
  z-index: 100;
  overflow: hidden;
}

.pictureMantra img {
  overflow: hidden;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-clip: content-box;
  position: relative;
}
.sound-contentIntermed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.om .namah .shivaya {
  width: 50%;
}
.textMantra {
  position: absolute;
  margin-top: 19px;
  -webkit-transform: scale(0.78);
          transform: scale(0.78);
  max-width: 90px;
  text-align: center;
  /* word-break: break-word; */
}
/* .soundTitleIntermed {
  word-break: break-word;
  width: 100%;
  font-size: 144%;
} */
.soundPictureConfirmed {
  border-radius: 50%;
}
.sound-contentConfirmed {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textFrequency {
  position: absolute;
  padding: 8px;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 17px;
}
.pictureFrequency {
  margin-top: 4px;
}
.confirmed {
  position: relative;
}
.text-tooltip {
  background-color: white;
  width: 11em;
  height: auto;
  position: absolute;
  top: -52px;
  left: -28px;
  border-radius: 9px;
  box-shadow: 0 0 6px grey;
  padding: 8px;
  overflow: hidden;
  display: none;
}
.text-tooltip--active,
.text-tooltip:hover {
  overflow: visible;
  display: inherit !important;
}

#triangle-code {
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 42px solid #fffff7;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  position: absolute;
  bottom: -31px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  left: 46px;
}

.buttonPresets-container .confirmed:nth-child(1n) .text-tooltip--active {
  left: -17px !important;
}

.buttonPresets-container
  .confirmed:nth-child(1n)
  .text-tooltip--active
  #triangle-code {
  left: 36px !important;
}
.buttonPresets-container .confirmed:nth-child(3n) .text-tooltip--active {
  left: -32px !important;
}

.buttonPresets-container
  .confirmed:nth-child(3n)
  .text-tooltip--active
  #triangle-code {
  left: 49px !important;
}
.DurationHead {
  border-right: 1px dashed mediumpurple;
}
.DurationHead,
.SoundHeader {
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 4px;
}

.pictureCropper__img-container {
  width: 335px;
  height: 480px;
  float: left;
}

.pictureCropper__img-preview {
  width: 90px;
  height: 90px;
  float: left;
  margin-left: -85vw;
  margin-top: -63px !important;
  position: fixed;
  box-shadow: 1px 2px 10px black;
  border: 1px solid blue;
  border-radius: 50%;
}

.pictureCropper__btn-imageEditor {
  background: #7b16ff !important;
}

.cropper-container .cropper-bg {
  width: inherit !important;
}

.react-responsive-modal-modal {
  height: 532px;
}

@media (min-width: 321px) and (max-width: 360px) {
  .cropper-container {
    width: 280px !important;
  }

  .react-responsive-modal-container {
    width: 340px !important;
  }

  .react-responsive-modal-modal {
    width: 280px !important;
    margin-top: 10vh;
  }

  .pictureCropper__img-container {
    width: 280px !important;
  }

  .cropper-canvas {
    width: 280px !important;
  }
}

@media (min-width: 361px) and (max-width: 412px) {
  .cropper-container {
    width: 350px !important;
    height: 458px !important;
  }

  .react-responsive-modal-container {
    /* width: 360px !important; */
    margin-top: 4px !important;
  }

  .react-responsive-modal-modal {
    /* width: 300px !important; */
    margin-top: 10vh;
  }

  .pictureCropper__img-container {
    width: 300px !important;
  }

  .cropper-canvas {
    width: 300px !important;
    margin-top: -11px;
    height: 458px !important;
  }
}

@media (min-width: 415px) and (max-width: 768px) {
  .cropper-container {
    width: 420px !important;
  }

  .react-responsive-modal-container {
    width: 480px !important;
    margin-left: -2%;
    margin-top: 23%;
  }

  .react-responsive-modal-modal {
    width: 420px !important;
  }

  .pictureCropper__img-container {
    width: 420px !important;
  }

  .cropper-canvas {
    width: 420px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cropper-container {
    width: 620px !important;
  }

  .react-responsive-modal-container {
    width: 680px !important;
    margin-left: 17%;
    margin-top: 23%;
  }

  .react-responsive-modal-modal {
    width: 620px !important;
  }

  .pictureCropper__img-container {
    width: 620px !important;
  }

  .cropper-canvas {
    width: 620px !important;
  }
}

.pictureFilter__container {
  width: 32vh;
  height: 32vh;
  /* background-color: #dadada; */
}

button.sidebar__sidebarItem > svg {
  margin-left: -6px;
}

.btn-imageEditor,
.pictureCropper__btn-imageEditor:last-child,
.btn-imageEditor:active {
  background: #ee0979 !important;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  ) !important;
  color: white !important;
  font-weight: bold !important;
}

.sliderContainer__slider {
  height: 5vh;
}

.pictureFilter__mainImage__picture {
  width: 88%;
  height: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  /* margin-left: -39px; */
}

.sidebar {
  margin-right: 0.1px;
}

.sidebar,
.pictureFilter__sidebar {
  border-right: 1px solid white;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow-x: hidden;
  margin-right: -1px;
  box-shadow: 1px 2px 6px grey;
  border-radius: 7px;
  padding: 2px;
}

.pictureFilter__sidebar {
  margin-bottom: 12px;
  width: 353px;
  margin-bottom: 1rem;
}

.sidebar__sidebarItem {
  cursor: pointer;
  border: none;
  outline: none;

  padding: 1rem;
  position: relative;
  transition: background-color 150ms;
}

.sidebar__sidebarItem:hover,
.sidebar__sidebarItem:focus {
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #f39c12 0%,
    #f8664f 100%
  );
}

.active-side {
  background-color: hsl(265, 100%, 46%) !important;
}

/* .sidebar__sidebarItem:not(:active) {
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #f39c12 0%,
    #f8664f 100%
  );
} */

.sidebar__sidebarItem::after {
  content: "";
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  height: 1px;
}

.sliderContainer {
  margin-top: -1rem;
  padding: 0 2rem 0rem 0rem;
  margin-left: 0px;
  margin-bottom: 13px;
  width: 90%;
}

.sliderContainer__slider {
  width: 100%;
  cursor: pointer;
  accent-color: #f8664f;
}

@media (max-width: 860px) {
  .pictureFilter__container {
    height: 44vh;
    width: 32vh;
    background: white;
  }

  .pictureFilter__mainImage {
    margin-top: 11px;
    width: 346px;
    margin-left: 0.4rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 360px) {
  .img-filters {
    width: 251px !important;
    margin-top: 10vh;
    margin-left: -1px;
  }
}

@media (min-width: 385px) and (max-width: 600px) {
  .pictureFilter__container {
    height: 33vh !important;
    width: 36vh !important;
    -webkit-transform: translate(-2rem, 2px);
            transform: translate(-2rem, 2px);
  }
}

@media (min-width: 372px) and (max-width: 376px) {
  .pictureFilter__container {
    height: 41vh;
    width: 41vh;
    margin-top: -5px;
  }
}

@media (min-width: 413px) and (max-width: 450px) {
  .modal-414 {
    width: 260px !important;
    height: 563px !important;
  }
}

@media (min-width: 451px) and (max-width: 766px) {
  .pictureFilter__container {
    height: 29vh;
    width: 29vh;
    margin-top: -14px;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .modal-414 {
    width: 450px !important;
    height: 730px !important;
  }
}

