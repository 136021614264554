.counter-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.counterNotStart {
  margin-bottom: 3em;
}
.counterStart {
  margin-bottom: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-session-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  width: 81%;
  border: 1px dotted darkgrey;
  padding: 8px;
  border-radius: 7px;
}

.session-timer {
  margin: 18px auto;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-around;
  max-width: 1.875rem;
  max-width: 30rem;
}
@media (min-width: 576px) {
  .form-inline .form-control {
    /* display: inline-block; */
    width: auto;
    vertical-align: middle;
  }
}

.session-timer input {
  max-width: 30px;
  max-width: 1.875rem;
  text-align: center;
  /* display: none; */
}
input,
.form-control {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.form-control {
  display: block;
  width: 100%;
  padding: 8px 12px;
  padding: 0.5rem 0.75rem;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}
.form-inline .form-label {
  margin-left: 6px;
  margin-left: 0.375rem;
  margin-right: -0.625rem;
  display: inline-block;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
}
.session-timer__input {
  display: flex;
  align-items: center;
}
.form-label {
  font-size: 1.4rem !important;
  color: #848181;
}
.session-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 91vh;

  color: darkgray;
  /* top: -2em; */
}

.App-header,
.session-container,
.why-container,
.how-container,
.presets-container {
  background: var(--Background);
}
.fixed {
  position: fixed;
}
.inherit {
  position: inherit;
}
.startButton {
  color: #fff;
  background: #7b16ff;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #7b16ff 0%,
    #4400cc 100%
  );
  box-shadow: 0 1px 19px rgb(123 22 255 / 30%);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
}

.wrapperStopButton {
  position: relative;
  width: 100%;
  height: 21vh;
}

.stopButton:before,
.stopButton:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: inherit;
  border-radius: inherit;
  border: inherit;
  background-color: transparent;
  box-shadow: inherit;
  animation: pulse 2.2s ease-out infinite;
}
.stopButton:after {
  animation-delay: 1.1s;
}
@keyframes tiny-pulse {
  0% {
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  50% {
    transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(4, 4, 4);
  }
}
.stopButton {
  box-shadow: 0 1px 19px rgb(238 9 121 / 30%);
  border-radius: 50%;
  color: #fff;
  width: 80px;
  height: 80px;
  background: #ee0979;
  background-image: radial-gradient(
    ellipse farthest-corner at top left,
    #ff6a00 0%,
    #ee0979 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  border: var(--borderEffect);
  background-color: var(--Background);
  box-shadow: var(--shadowEffect);
  animation: tiny-pulse 1.1s ease-out infinite;
}

/* Progress bar */
.indicator {
  width: 48em;
  height: 28em;
  text-align: center;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsContainer {
  margin-top: -7em;
  scale: 0.5;
  display: flex;
  align-items: center;
  transform: translate(-43px, 4px);
  width: 100%;
  margin-bottom: -7em;
}
.zenIcon {
  width: 80px;
  height: 80px;
}

@media screen and (max-width: 320px) {
  .session-container {
    transform: scale(0.7);
  }
}

@media screen and (max-width: 700px) {
  .statsContainer {
    transform: translate(2px, 4px) scale(0.6);
  }
}
.session-container h1 {
  color: var(--colorText);
}

@media screen and (min-width: 1000px) {
  .indicator {
    height: 40em !important;
  }
}

.buttonStartTimerContainer {
  animation: slide-in-bck-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-bck-center {
  0% {
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
.sessionText {
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.session-textContent {
  font-size: 14px;
  text-align: left;
  max-width: 100% !important;
  padding: 2px;
}
.headSectionTimer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.session-container .slide-in-top {
  top: 20% !important;
  z-index: 99999;
}
.chrono-counterStart {
  margin-bottom: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.67);
}
.chronoConfig {
  width: 54px;
  position: absolute;
  left: 6px;
  bottom: 6rem;
  filter: drop-shadow(0px 2px 3px black);
}
